import React, { createContext, useContext, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Define the error messages
export const errorMessages = {
  // General
  "RuleErrorInsufficientBalance": {
    "title": "Insufficient Balance",
    "description": <><p>You do not have sufficient balance to carry out this transaction.</p><p>Deposit funds into your wallet to continue.</p></>,
    "icon": <i key="1" className="bi bi-currency-exchange"></i>,
  },
  // SubmitTransaction: Problem processing transaction: VerifyAndBroadcastTransaction: Problem broadcasting txn: BroadcastTransaction: : Server._addNewTxn: problem adding txn to pos mempool: PosMempool.AddTransaction: Transaction already in mempool
  "Transaction already in mempool": {
    "title": "Unconfirmed transaction",
    "description": <><p>This transaction already exists in the mempool. Are you trying to re-diamond the same post?</p></>,
    "icon": <i key="1" className="bi bi-exclamation-triangle-fill"></i>,
  },
  // Diamonds
  "RuleErrorCreatorCoinTransferPostAlreadyHasSufficientDiamonds": {
    "title": "Error sending diamonds",
    "description": "You have already awarded this post with the chosen diamond level. You need to issue a higher number of diamonds.",
    "icon": <i key="1" className="bi bi-gem"></i>,
  },
  "Sender and receiver cannot be the same.": {
    "title": "Sorry, you can't send that diamond",
    "description": "Sadly, you can't diamond your own content.",
    "icon": <i key="1" className="bi bi-gem"></i>,
  },
  // Follows
  "RuleErrorFollowEntryAlreadyExists": {
    "title": "Error changing following status",
    "description": "You are trying to follow an account you already follow.",
    "icon": <i key="1" className="bi bi-person-fill"></i>,
  },
  "RuleErrorCannotUnfollowNonexistentFollowEntry": {
    "title": "Can't unfollow",
    "description": "You are trying to unfollow an account you don't follow.",
    "icon": <i key="1" className="bi bi-person-fill"></i>,
  },


  /*******************************************************************************************************
   * App-specific errors
   */
  "fetchInitialNotificationsFailed": {
    "title": "Error Loading Notifications",
    "description": <><p>Retrieving your notifications failed.</p><p>Please close, reopen and try again.<br/>If it happens again please report this issue.</p></>,
    "icon": <i key="1" className="bi bi-bell-fill"></i>,
  },
  "error_buildUserFilters": {
    "title": "Error Constructing Notification Filters",
    "description": <><p>Please notify support with any error details below so that this issue can be resolved.</p><p>Updating your notification filter settings may help work-around this issue in the meantime.</p></>,
    "icon": <i key="1" className="bi bi-bell-fill"></i>,
  },
  "error_updateFilteredNotifications": {
    "title": "Error Filtering Notifications",
    "description": <><p>Please notify support with the details below so that this issue can be resolved.</p><p>Updating your notification filter settings may help work-around this issue in the meantime.</p></>,
    "icon": <i key="1" className="bi bi-bell-fill"></i>,
  },
  "errorLoadingMoreNotifications": {
    "title": "Error Loading More Notifications",
    "description": <><p>Please try again.</p><p>If you see this error again, please notify support.</p></>,
    "icon": <i key="1" className="bi bi-bell-fill"></i>,
  },
  "error_triggerProcessNotifications": {
    "title": "Error Processing Notifications",
    "description": <><p>Please try again.</p><p>If you see this error again, please notify support.</p></>,
    "icon": <i key="1" className="bi bi-bell-fill"></i>,
  },
  "error_pollingNotifications": {
    "title": "Error Updating Notifications",
    "description": <><p>Please try again.</p><p>If you see this error again, please notify support.</p></>,
    "icon": <i key="1" className="bi bi-bell-fill"></i>,
  },
};

// Create the error context
export const ErrorContext = createContext();

// Custom hook to access the error context
export const useError = () => useContext(ErrorContext);

// Error provider component
export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const processVerboseError = (error) => {
    if (!error) return;
  
    const errorMessage = error.message || 'Unknown error'; // Get the error message
    const stack = error.stack || null; // Get the full stack trace
    let functionName = null;
    let fileLocation = null;
  
    // Log the error message and stack for debugging
    console.log('Error occurred:', errorMessage);
    console.log('Stack trace:', stack);
  
    // Extract the first relevant function/component name from the stack trace
    if (stack) {
      const stackLines = stack.split('\n'); // Split the stack into individual lines
  
      if (stackLines.length > 1) { // Ensure there's at least one entry beyond the error message
        const errorLocation = stackLines[1].trim(); // The second line contains the function/component and location
  
        // Safely extract function name and file location
        const functionNameMatch = errorLocation.match(/at (\S+)/);
        functionName = functionNameMatch ? functionNameMatch[1] : 'unknown function';
  
        const fileLocationMatch = errorLocation.match(/\((.*)\)/);
        fileLocation = fileLocationMatch ? fileLocationMatch[1] : 'unknown location';
      }
    }
  
    console.log("Detected error details:", errorMessage, functionName, fileLocation);
  
    if (errorMessage) {
      return { errorMessage, functionName, fileLocation };
    } else {
      return;
    }
  };
  

  // Function to handle errors
  const handleError = (errorMessage, error) => {
    console.log('Error occurred:', errorMessage, error); // Log the error message

    // Initialize error details
    let errorText = typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage);
    let errorStatus = 'Unknown';
    let errorDetails = 'An unknown error occurred';

    // Check if the error's constructor name is 'DeSoAPIError'
    if (error && error.constructor && error.constructor.name === 'DeSoAPIError') {
        errorStatus = error.status; // Assuming status is a property of the error
        errorDetails = error.message || 'An error occurred without a specific message.';
    } else {
        // Fallback for other error types
        errorDetails = error?.message || errorText; // Use the original error message if available
    }
    console.log("handleError, errorMessage, error, errorText, errorStatus, errorDetails:", errorMessage, error, errorText, errorStatus, errorDetails);
    // Split the error message and get the last part if it's a valid string
    const parts = errorText.split(':');
    const lastPart = parts[parts.length - 1].trim();

    // Process any additional verbose error information, if needed
    const verbose = error ? processVerboseError(error) : null;
    console.log("handleError, verbose returned:", verbose);

    // Check if the last part exists in errorMessages
    const errorMessageData = errorMessages[lastPart] || {
        title: 'An error occurred',
        description: (
            <div>
                <p>{errorText}</p>
                <p><strong>Status:</strong> {errorStatus}</p>
                <p><strong>Details:</strong> {errorDetails}</p>
            </div>
        ), // Use the entire error message as the description
        icon: <i key="1" className="bi bi-exclamation-circle-fill"></i>, // Generic error icon
    };

    if (verbose) {
        errorMessageData.verbose = verbose;
    }

    setError(errorMessageData);
  };

  

  // Function to clear errors
  const clearError = () => {
    console.log('Clearing error'); // Log when clearing the error
    setError(null);
  };

  return (
    <ErrorContext.Provider value={{ error, handleError, clearError }}>
      {error && <ErrorModal error={error} onClose={clearError} onRetry={handleError} />}
      {children}
    </ErrorContext.Provider>
  );
};

// Error modal component using react-bootstrap Modal
export const ErrorModal = ({ error, onClose, onRetry }) => {
  console.log('ErrorModal rendering:', error); // Log when ErrorModal renders

  return (
    <Modal show={!!error} onHide={onClose} style={{ zIndex: "30000" }} centered>
      <Modal.Body>
        <div className="d-flex flex-row flex-nowrap justify-content-between mb-3">
          <span className='flex-fill'>"Houston, we've had a problem..."</span>
          <span className="nav-link action me-3 d-flex flex-nowrap align-self-start" onClick={onClose}>
              <i className="bi bi-x-circle-fill me-2"></i><span className="d-none d-lg-inline"></span>Dismiss
          </span>
        </div>
        <div className='text-center'>
          {error.icon && <h1>{error.icon}</h1>}
          <h5>{error.title}</h5>
          <p>{error.description}</p>
        </div>
        {error && error.verbose && (
          <div className='w-100 text-center'>
            <pre id="errorVerbose" className='w-100 text-start bg-secondary rounded p-3'>
              {error?.verbose?.errorMessage}
              <br/><strong>Function:</strong> {error?.verbose?.functionName}
            </pre>
          </div>
        )}
        <div className="d-flex flex-row flex-nowrap justify-content-center">
          {/*<Button variant="primary" onClick={onRetry}>Retry</Button>*/}
          <Button variant="outline-secondary" onClick={onClose}>Dismiss</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
