import { useContext, useEffect, useState } from "react";
import { useError } from "../contexts/ErrorHandling";
import { createPostAssociation, createUserAssociation, deleteUserAssociation, getPostAssociations, getUserAssociations } from "deso-protocol";
import { Badge, Button, Form, InputGroup, Modal, Spinner, Alert, ListGroup, Dropdown } from "react-bootstrap";
import { Avatar } from "./layouts";
import { AppDataContext } from "../contexts/appData";
import { deso_graphql } from "./graphql";

export const ModerationModal = ({ mode, post, target, currentUser, show, onClose }) => {
    const [moderationMode, setModerationMode] = useState(mode || 'TOPIC');
    const [moderationTarget, setModerationTarget] = useState(post && post?.PostHashHex ? 'content' : 'user' || 'user');
    const [updateFlags, setUpdateFlags] = useState(true);
    const [assignedFlags, setAssignedFlags] = useState([]);
    const [selectedFlag, setSelectedFlag] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const { handleError } = useError();
    const { appData } = useContext(AppDataContext);
    const [newVerificationLevel, setNewVerificationLevel] = useState("0");
    const [changeSelection, setChangeSelection] = useState(false);
    const [matchedFlag, setMatchedFlag] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredFlags, setFilteredFlags] = useState([]);

    console.log("[moderation.jsx] moderationFlags, contentTopics:",appData.moderationFlags, appData.contentFlags);
    useEffect(() => {
        if(moderationMode==="TOPIC") { setModerationTarget("content"); }
        if(moderationMode==="FLAG" || moderationMode==="VERIFICATION")  { setModerationTarget("user"); }
        setAssignedFlags([]);
        setUpdateFlags(true);
    }, [moderationMode, moderationTarget]);

    const handleVerificationLevelChange = (event) => {
        console.log("[moderation.jsx] handleVerificationLevelChange",event);
        setNewVerificationLevel(event.target.value);
    };

    useEffect(() => {
        if (show && updateFlags) {
            (async () => {
                /*
                if(moderationTarget === "content" && post?.PostHashHex) {
                    try {
                        const payload = {
                            PostHashHex: post.PostHashHex,
                            AssociationType: moderationMode,
                            AssociationValuePrefix: null,
                            TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                        };
                        const response = await getPostAssociations(payload);
                        setAssignedFlags(response.Associations || []);
                        setUpdateFlags(false);
                    } catch (error) {
                        handleError('Unable to fetch user moderation flags', error);
                    }
                } else {
                    try {
                        const payload = {
                            TargetUserPublicKeyBase58Check: target.PublicKeyBase58Check,
                            AssociationType: moderationMode,
                            TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                        };
                        const response = await getUserAssociations(payload);
                        setAssignedFlags(response.Associations || []);
                        setUpdateFlags(false);
                    } catch (error) {
                        handleError('Unable to fetch user moderation flags', error);
                    }
                }*/

                if(moderationTarget === "content" && post?.PostHashHex) {
                    try {
                        const data = await deso_graphql({
                            query: `query PostAssociations($condition: PostAssociationCondition, $first: Int, $before: Cursor, $after: Cursor) {
                                postAssociations(condition: $condition, first: $first, before: $before, after: $after) {
                                    nodes {
                                    associationId
                                    associationType
                                    associationValue
                                    extraData
                                    postHash
                                    }
                                }
                                }`,
                            variables: {
                                "condition": {
                                  "transactorPkid": null,
                                  "postHash": null,
                                  "associationType": null
                                },
                                "first": null,
                                "after": null
                              },
                            operationName: "PostAssociations"
                          });
                        console.log('[moderation.jsx] POST Associations:',data);
                        setAssignedFlags(data?.data?.userAssociations?.nodes || []);
                        setUpdateFlags(false);
                    } catch (error) {
                        handleError('Unable to fetch user moderation flags', error);
                    }
                } else {
                    try {
                        const data = await deso_graphql({
                            query: `query UserAssociations($filter: UserAssociationFilter, $first: Int, $after: Cursor, $before: Cursor, $condition: UserAssociationCondition) {
                                    userAssociations(filter: $filter, first: $first, after: $after, before: $before, condition: $condition) {
                                        nodes {
                                        associationId
                                        associationType
                                        associationValue
                                        targetUserPkid
                                        transactorPkid
                                        }
                                        pageInfo {
                                        endCursor
                                        hasNextPage
                                        hasPreviousPage
                                        startCursor
                                        }
                                    }
                                }`,
                            variables: {
                                "filter": {
                                  "associationType": {
                                    "equalTo": moderationMode
                                  },
                                  "associationValue": {
                                    "includesInsensitive": null
                                  },
                                  "extraData": {
                                    "contains": null
                                  }
                                },
                                "first": null,
                                "after": null,
                                "before": null,
                                "condition": {
                                    "transactorPkid": currentUser.PublicKeyBase58Check,
                                    "targetUserPkid": target.PublicKeyBase58Check,
                                },
                              },
                            operationName: "UserAssociations"
                          });
                          console.log('[moderation.jsx] USER Associations:',data);
                          setAssignedFlags(data?.data?.userAssociations?.nodes || []);
                        setUpdateFlags(false);
                    } catch (error) {
                        handleError('Unable to fetch user moderation flags', error);
                    }
                }



            })();
        }
    }, [show, target, currentUser, updateFlags, handleError, moderationMode, moderationTarget]);

    const handleAssignFlag = async () => {
        setSuccess(null);
        setError(null);
        if (!selectedFlag) {
            setMessage("Please select a flag to assign.");
            setError(true);
            return;
        }

        if(moderationTarget === "user") {
            try {
                await createUserAssociation({
                    AssociationType: moderationMode,
                    AssociationValue: selectedFlag,
                    TargetUserPublicKeyBase58Check: target.PublicKeyBase58Check,
                    TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                });
                setUpdateFlags(true);
                setMessage("Flag assigned to user successfully!");
                setSuccess(true);
                setError(false);
            } catch (err) {
                setMessage("Error assigning flag.");
                setSuccess(false);
                setError(true);
            }
        } else {
            try {
                await createPostAssociation({
                    AssociationType: moderationMode,
                    AssociationValue: selectedFlag,
                    TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                    PostHashHex: post.PostHashHex,
                });
                setUpdateFlags(true);
                setMessage("Flag assigned to post successfully!");
                setSuccess(true);
                setError(false);
            } catch (err) {
                setMessage("Error assigning flag.");
                setError(true);
                setSuccess(false)
            }
        }
    };

    const handleRevokeFlag = async (flag) => {
        try {
            const payload = {
                AssociationID: flag.AssociationID,
                TargetUserPublicKeyBase58Check: target.PublicKeyBase58Check,
                TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
            };
            await deleteUserAssociation(payload);
            setUpdateFlags(true);
            setMessage("Flag revoked successfully!");
            setError(false);
        } catch (err) {
            setMessage("Error revoking flag.");
            setError(true);
        }
    };

    const searchTopics = (term) => {
        setSearchTerm(term);
        if (term) {
            const filtered = appData?.contentTopics?.filter(flag =>
                (flag.ExtraData?.title || flag.AssociationValue)
                    .toLowerCase()
                    .includes(term.toLowerCase())
            );
            setFilteredFlags(filtered);
            console.log("[moderation.jsx] searchTopics: ",filtered);
        } else {
            setFilteredFlags([]);
        }
    };

    const handleSelectTopic = (flag) => {
        setMatchedFlag(flag);
        setSelectedFlag(flag.AssociationValue);
        setSearchTerm('');
        setFilteredFlags([]);
    };


    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Body className="">
                <div className="d-flex flex-row flex-nowrap align-items-center fs-5">
                    {moderationMode === 'VERIFICATION' && (
                        <span className='flex-fill fw-bold'>
                            <i className={`bi bi-patch-check-fill me-2`}></i>Verification <span className="fw-light">by Association</span>
                        </span>
                    )}
                    {moderationMode === 'FLAG' && (
                        <span className='flex-fill fw-bold'>
                            <i className={`bi bi-flag-fill me-2`}></i>Moderation
                        </span>
                    )}
                    {moderationMode === 'TOPIC' && (
                        <span className='flex-fill fw-bold'>
                            <i className={`bi bi-tags-fill me-2`}></i>Categorise content
                        </span>
                    )}
                    <span className="btn" onClick={onClose}>
                        <i className="bi bi-x-lg text-body"></i>
                    </span>
                </div>
                <div className="text-end small mb-3"><span className="fw-light me-2">by</span><span className="fw-bold"><i className="bi bi-flag-fill me-2"></i>Open</span><span className="fw-light">Moderation</span></div>
                <div className="userModerationSettings">

                    {moderationMode === 'VERIFICATION' && (
                        <>  
                            {currentUser.PublicKeyBase58Check === target.PublicKeyBase58Check ? (
                                <div className="mb-3">
                                    <p className="text-center">You cannot verify yourself through <span className="text-nowrap"><span className="fw-bold text-info"><i className="bi bi-patch-check-fill mx-1"></i>Verification</span> <span className="fw-light">by Association</span></span>.</p>
                                </div>
                            ) : (
                                <div className="mb-3">
                                    <p className="text-center"><span className="fw-bold">You are verifying {target.Username}</span> through <span className="text-nowrap">Verification by Association</span>.</p>
                                    <div className="mb-3">
                                        <label for="trustconfidence" className="form-label text-center w-100"><strong>How much</strong> do you <strong>trust <Avatar publicKey={target.PublicKeyBase58Check} suppliedProfile={target} type="avatar" />
                                        <Avatar publicKey={target.PublicKeyBase58Check} suppliedProfile={target} type="username" />?</strong></label>
                                        <input type="range" className="form-range" name="AssociationValue" value={newVerificationLevel} min="0" max="100" step="25" id="AssociationValue" onChange={handleVerificationLevelChange} />
                                        {newVerificationLevel === "0" && (<span className="form-control text-center text-muted opacity-25" type="text">I don’t know enough about this user</span>)}
                                        {newVerificationLevel === "25" && (<span className="form-control text-center text-muted opacity-50" type="text">They seem genuine, we engage lots</span>)}
                                        {newVerificationLevel === "50" && (<span className="form-control text-center text-body opacity-75" type="text">I'm confident they're real</span>)}
                                        {newVerificationLevel === "75" && (<span className="form-control border-success text-center text-body" type="text">Seen evidence, shared a call or met them</span>)}
                                        {newVerificationLevel === "100" && (<span className="form-control border-success text-center text-body-emphasis fw-bold" type="text">100% - I'd stake my reputation on it</span>)}
                                    </div>
                                    <p className="small text-muted"><span className="fw-bold">Note: </span>This symbolises an established level-of-trust between you and {target.Username} that will be used to determine verification within your circle, with your own reputation linked to this user.</p>
                                </div>
                            )}
                        </>
                    )}

                    {/*moderationMode !== 'VERIFICATION' && post && target && !(currentUser.PublicKeyBase58Check === target.PublicKeyBase58Check) && (
                        <>
                            <div className="d-flex flex-row flex-nowrap align-items-center justify-content-start mb-3 input-group">
                                <span className="input-group-text">Level</span>
                                <span className={`btn d-flex flex-row align-items-center ${moderationTarget === "user" ? `flex-fill justify-content-center active` : 'text-muted btn-outline-secondary'}`} onClick={() => setModerationTarget('user')}>
                                    <i className={`bi bi-person-fill me-2 ${moderationTarget === "user" ? `text-info`: `text-muted`}`}></i>
                                    <span className={`${moderationTarget === "user" ? `btn-outline-info active fw-bold` : ''}`}>User level</span>
                                </span>
                                <span className={`btn d-flex flex-row align-items-center ${moderationTarget === "content" ? `flex-fill justify-content-center active` : 'text-muted btn-outline-secondary'}`} onClick={() => setModerationTarget('content')}>
                                    <i className={`bi bi-card-text me-2 ${moderationTarget === "content" ? `text-info`: `text-muted`}`}></i>
                                    <span className={`${moderationTarget === "content" ? `btn-outline-info active fw-bold` : ''}`}>Content level</span>
                                </span>
                            </div>
                        </>
                    )*/}

                    {updateFlags ? (
                        <div className="text-center py-4 mb-3">
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            {moderationMode === "VERIFICATION" ? (
                                <>
                                    {!(currentUser.PublicKeyBase58Check === target.PublicKeyBase58Check) && (
                                        <>
                                            {assignedFlags.length > 0 ? (
                                                <>
                                                    <p className="text-center fw-bold">Associations assigned</p>
                                                    <p className="small">
                                                        {assignedFlags.map((flag, index) => (
                                                            <Badge key={index} bg="danger" className="m-1">
                                                                {flag.associationValue}
                                                                <Button
                                                                    variant="outline-light"
                                                                    size="xs"
                                                                    className="ms-2"
                                                                    onClick={() => handleRevokeFlag(flag)}
                                                                >
                                                                    <i className="bi bi-trash3-fill"></i>
                                                                </Button>
                                                            </Badge>
                                                        ))}
                                                    </p>
                                                </>
                                            ) : (
                                                <p className="text-muted mb-3 text-center">You have not currently verified {target.Username}.</p>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    
                                    {moderationMode === "FLAG" && (
                                        <div>
                                            <p className="fw-bold">Select nature of moderation report...</p>
                                            <div class="d-flex flex-column justify-content-between">
                                                {appData?.moderationFlags?.map(flag => (
                                                    <>
                                                    {(!selectedFlag || changeSelection) ? (
                                                        <div className="d-flex flex-row flex-nowrap align-items-center py-2">
                                                            {selectedFlag === flag.AssociationValue && (<i className="bi bi-shield-fill-exclamation me-3 fs-4"></i>)}
                                                            <div className={`${selectedFlag === flag.AssociationValue ? `selected text-body ${!changeSelection ? `fs-5` : ``}` :` text-muted py-1 action`}`} key={flag.AssociationValue} value={flag.AssociationValue} onClick={() => { setSelectedFlag(flag.AssociationValue); setChangeSelection(false); } }>
                                                                <div className={`${selectedFlag === flag.AssociationValue ? `text-body-emphasis fw-bold`:` fw-normal`}`}>
                                                                    {flag.ExtraData?.title || flag.AssociationValue}
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                        {selectedFlag === flag.AssociationValue && (
                                                            <div className="d-flex flex-column flex-nowrap align-items-center">
                                                                {selectedFlag === flag.AssociationValue && (<i className="bi bi-shield-fill-exclamation fs-1"></i>)}
                                                                <div className={`${selectedFlag === flag.AssociationValue ? `selected text-body text-center pb-3 ${!changeSelection ? `fs-5` : ``}` :` text-muted py-1 action`}`} key={flag.AssociationValue} value={flag.AssociationValue} onClick={() => { setSelectedFlag(flag.AssociationValue); setChangeSelection(false); } }>
                                                                    <div className={`${selectedFlag === flag.AssociationValue ? `text-body-emphasis fw-bolder`:` fw-normal`}`}>
                                                                        {flag.ExtraData?.title || flag.AssociationValue}
                                                                    </div>
                                                                    <div className="fw-light small">{flag.ExtraData.description}</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        </>
                                                    )}
                                                    </>
                                                ))}
                                                {selectedFlag && !changeSelection && (
                                                    <>
                                                        <div className="w-100 d-flex flex-row flex-nowrap justify-content-center align-items-center small text-muted text-underline action mb-3" onClick={() => setChangeSelection(true)}>
                                                            <i className="bi bi-pencil-square me-2"></i>change selection
                                                        </div>
                                                        {moderationTarget === 'content' ? (
                                                            <div className="mb-3">
                                                                <p className="small text-muted"><span className="fw-bold">Note: </span>This is used to moderate posts in your own feed and contribute towards moderation applied to other users. You can review your own moderation preferences in the <Avatar publicKey={currentUser.PublicKeyBase58Check} suppliedProfile={currentUser} link={false} type="avatar"/> user panel.</p>
                                                            </div>
                                                        ) : (
                                                            <div className="mb-3">
                                                                <p className="small text-center text-muted">Reports are used to moderation content and protect users from malicious behaviour. Review your moderation preferences in the <Avatar publicKey={currentUser.PublicKeyBase58Check} suppliedProfile={currentUser} link={false} type="avatar"/> user panel.</p>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    {moderationMode === "TOPIC" && (
                                        <div className="mb-3">
                                            {selectedFlag && matchedFlag && !changeSelection ? (
                                                <>
                                                    <div className="fw-bold text-center mb-3">You are assigning topic:</div>
                                                    <div className="d-flex flex-column justify-content-center align-items-center mb-3">
                                                        <i className="bi bi-tags-fill me-3 fs-4"></i>
                                                        <div className="selected text-body fs-5 lh-1 text-center" key={matchedFlag.AssociationValue} value={matchedFlag.AssociationValue}>
                                                            <div className="text-body-emphasis fw-bolder">
                                                                {matchedFlag.ExtraData?.title || matchedFlag.AssociationValue}
                                                            </div>
                                                            <div className="fw-light small">{matchedFlag.ExtraData?.description}</div>
                                                        </div>
                                                    </div>
                                                    <div className="w-100 d-flex flex-row flex-nowrap justify-content-center align-items-center small text-muted text-underline action" onClick={() => setChangeSelection(true)}>
                                                        <i className="bi bi-pencil-square me-2"></i>change selection
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                <p className="text-center"><span className="fw-bold">Assign topic(s)</span> to help categorise this content.</p>
                                                <Form.Group controlId="selectFlag" className="mb-3">
                                                    <Form.Label className="visually-hidden">Flag Type</Form.Label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            {moderationMode === "TOPIC" ? (
                                                                <i className="bi bi-tags-fill"></i>
                                                            ) : (
                                                                <i className="bi bi-flag-fill"></i>
                                                            )}
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={`Search topics...`}
                                                            value={searchTerm}
                                                            onChange={(e) => searchTopics(e.target.value)}
                                                        >
                                                            
                                                        </Form.Control>
                                                    </InputGroup>
                                                    {/* Dropdown positioned correctly */}
                                                    {filteredFlags.length > 0 && (
                                                            <Dropdown.Menu show className="position-absolute ms-5">
                                                                {filteredFlags.map(flag => (
                                                                    <Dropdown.Item 
                                                                        key={flag.AssociationValue} 
                                                                        onClick={() => handleSelectTopic(flag)}
                                                                    >
                                                                        {flag.ExtraData?.title || flag.AssociationValue}
                                                                    </Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        )}
                                                </Form.Group>
                                                <div className="mb-3">
                                                    <p className="small text-muted">By contributing to this model you help content discovery for you and other users. Peer-2-peer categorisation helps with topic-based discovery and helps inform creators of topics they should include in their own content.</p>
                                                </div>
                                                </>
                                            )}
                                        </div>
                                    )}
                                    {selectedFlag && assignedFlags.length > 0 && (
                                        <div className="mb-3">
                                            <p className="text-center small mb-0">You have assigned the following flags to {target.Username}</p>
                                            <p className="small text-center">
                                                {assignedFlags.map((flag, index) => (
                                                    <Badge key={index} bg="danger" className="m-1">
                                                        {flag.associationValue}
                                                        <span
                                                            variant="btn btn-sm btn-outline-light"
                                                            className="ms-2"
                                                            onClick={() => handleRevokeFlag(flag)}
                                                        >
                                                            <i className="bi bi-trash3-fill"></i>
                                                        </span>
                                                    </Badge>
                                                ))}
                                            </p>
                                        </div>
                                    )} 
                                </>
                            )}
                        </>
                    )}

                    {selectedFlag && !changeSelection && (
                        <>
                        {moderationMode === 'FLAG' && selectedFlag && moderationTarget && !(currentUser.PublicKeyBase58Check === target.PublicKeyBase58Check) && (
                            <p className="text-center fw-bold"><span className="me-2">Assign moderation flag to</span><span className="text-nowrap"><Avatar suppliedProfile={target} publicKey={target.PublicKeyBase58Check} type="avatar" link={false} /><Avatar suppliedProfile={target} publicKey={target.PublicKeyBase58Check} type="username" link={false} /></span>?</p>
                        )}
                        {moderationMode === 'TOPIC' && selectedFlag && moderationTarget && !(currentUser.PublicKeyBase58Check === target.PublicKeyBase58Check) && (
                            <p className="text-center fw-bold d-flex flex-row">Assign this topic?</p>
                        )}
                        {((selectedFlag || newVerificationLevel !== "0") && !changeSelection) && (
                            <p className="small text-center text-muted"><span className="fw-bold"><i className="bi bi-info-circle me-2"></i>Note: </span> all actions on a blockchain can be seen. <span className="text-nowrap">Only submit valid reports.</span></p>
                        )}  
                        </>
                    )}
                    {message && <Alert className='text-center' variant={error ? 'danger' : 'success'}>{message}</Alert>}
                </div>
                <div className="d-flex flex-row justify-content-center">
                    {!success ? (
                        <>
                            <Button variant="secondary" onClick={onClose}>
                                Cancel
                            </Button>
                            {selectedFlag && !changeSelection && (
                                <>
                                    {moderationMode === "TOPIC" && selectedFlag && moderationTarget && !(currentUser.PublicKeyBase58Check === target.PublicKeyBase58Check) && (
                                        <Button variant="success" className="ms-2" onClick={handleAssignFlag}>
                                            <i className="bi bi-tags-fill me-2"></i>Yes, assign topic
                                        </Button>
                                    )}
                                    {moderationMode === "FLAG" && selectedFlag && moderationTarget && !(currentUser.PublicKeyBase58Check === target.PublicKeyBase58Check) && (
                                        <Button variant="danger" className="ms-2" onClick={handleAssignFlag}>
                                            <i className="bi bi-flag-fill me-2"></i>Yes, issue report
                                        </Button>
                                    )}
                                    {newVerificationLevel !== "0" && moderationMode === "VERIFICATION" && moderationTarget && !(currentUser.PublicKeyBase58Check === target.PublicKeyBase58Check) && (
                                        <Button variant="info" className="ms-2" onClick={onClose} disabled>
                                            <i className="bi bi-patch-check-fill me-2"></i>Verify {target.Username}
                                        </Button>
                                    )}
                                </>
                            )}  
                        </>
                    ) : (
                        <>
                            <Button variant="success" onClick={onClose}>
                                Assigned successfully, close
                            </Button>
                        </>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};
