import React, { useEffect, useState } from 'react';
import { Loader, friendlyFormatDate } from '../utils/helpers.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ProfileCoin from './ProfileCoin.jsx';
import { deso_graphql } from '../utils/graphql.js';
import { Spinner } from 'react-bootstrap';
import { localCurrency } from '../utils/currency.js';
import ExchangeRatesContext from '../contexts/ExchangeRatesContext.js';
import { useUserPreferences } from "../contexts/UserPreferences";
import UserActivityChart from './stats/userActivity.jsx';
import UserTransactionsChart from './stats/userTransactionsAffecting.jsx';

const ProfileStats = ({ profile, preferences, exchangeRates, additionalData, setAdditionalData }) => {
    const [activeTab, setActiveTab] = useState("dashboard");

    /*useEffect(() => {
        console.log("[ProfileDashboard] AdditionalData Changed:", additionalData);
    }, [additionalData]);*/

    return (
        <>
            <div className='horizontal-nav-wrapper'>
                <ul className="nav nav-underline nav-justified mb-3 nav-fill flex-nowrap justify-content-evenly border-0" id="profileNav" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className={`nav-link${ activeTab === 'dashboard' ? ` active` : ` text-muted`}`} id="profileStatsTabDashboard" onClick={() => setActiveTab('dashboard')} data-bs-toggle="tab" data-bs-target="#profileStatsDashboard" type="button" role="tab" aria-controls="profileStats" aria-selected="true"><i className="bi bi-speedometer"></i> Dashboard</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className={`nav-link${ activeTab === 'activity' ? ` active` : ` text-muted`}`} id="profileStatsTabActivity" onClick={() => setActiveTab('activity')} data-bs-toggle="tab" data-bs-target="#profileStatsActivity" type="button" role="tab" aria-controls="profileStats" aria-selected="true"><i className="bi bi-bar-chart-line-fill"></i> Social Activity</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className={`nav-link${ activeTab === 'creatorcoin' ? ` active` : ` text-muted`}`} id="profileStatsTabCreatorCoin" onClick={() => setActiveTab('creatorcoin')} data-bs-toggle="tab" data-bs-target="#profileStatsCreatorCoin" type="button" role="tab" aria-controls="profileStats" aria-selected="true"><i className="bi bi-coin"></i> Creator Coin</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className={`nav-link${ activeTab === 'tokens' ? ` active` : ` text-muted`}`} id="profileStatsTabTokens" onClick={() => setActiveTab('tokens')} data-bs-toggle="tab" data-bs-target="#profileStatsTokens" type="button" role="tab" aria-controls="profileStats" aria-selected="true"><i className="bi bi-currency-exchange"></i> Tokens</button>
                    </li>
                </ul>
            </div>
            <div className="tab-content" id="profileStats">
                <div className={`tab-pane fade ${activeTab === 'dashboard' ? `show active` : null}`} id="profileStatsDashboard" role="tabpanel" aria-labelledby="profileStatsTabDashboard" tabIndex={0}>
                    { activeTab === 'dashboard' && ( <ProfileDashboard setActiveTab={setActiveTab} profile={profile} preferences={preferences} exchangeRates={exchangeRates} additionalData={additionalData} setAdditionalData={setAdditionalData}/> )}
                </div>
                <div className={`tab-pane fade ${activeTab === 'creatorcoin' ? `show active` : null}`} id="profileStatsCreatorCoin" role="tabpanel" aria-labelledby="profileStatsTabCreatorCoin" tabIndex={0}>
                    { activeTab === 'creatorcoin' && ( <ProfileCoin profile={profile} preferences={preferences} exchangeRates={exchangeRates} additionalData={additionalData} setAdditionalData={setAdditionalData} /> )}
                </div>
                <div className={`tab-pane fade ${activeTab === 'tokens' ? `show active` : null}`} id="profileStatsTokens" role="tabpanel" aria-labelledby="profileStatsTabTokens" tabIndex={0}>
                    { activeTab === 'tokens' && ( <>Placeholder</> )}
                </div>
                <div className={`tab-pane fade ${activeTab === 'activity' ? `show active` : null}`} id="profileStatsActivity" role="tabpanel" aria-labelledby="profileStatsTabActivity" tabIndex={0}>
                    { activeTab === 'activity' && (
                         <ActivityStats profile={profile} preferences={preferences} additionalData={additionalData} setAdditionalData={setAdditionalData} />
                    )}
                </div>
            </div>
        </>
    );
};

export default ProfileStats;

export const ProfileDashboard = ({ setActiveTab, profile, preferences, exchangeRates, additionalData, setAdditionalData }) => {

    useEffect(() => {
        if (additionalData && additionalData.coinHolders && additionalData.ccCoinsInCirculationNanos) {
            const totalCirculation = parseFloat(additionalData.ccCoinsInCirculationNanos);
            const hhiValue = calculateHHI(additionalData.coinHolders, totalCirculation);
            //console.log("HHI VALUE: ", hhiValue);
            
            if(hhiValue) {
                setAdditionalData(prevData => ({
                    ...prevData,
                    ...stats,
                    hhi: hhiValue.toLocaleString()
                }));
            }
        }
    }, [additionalData]);

    /*useEffect(() => {
        console.log("[ProfileDashboard] AdditionalData:", profile, additionalData);

        if (profile?.PublicKeyBase58Check) {
            //fetchProfileStats(profile.PublicKeyBase58Check);
        }
    }, [profile]);*/

    const stats = [
        { icon: 'bi-calendar3', label: 'Join Date', value: profile.joinDate },
        { icon: 'bi-clock', label: 'Last Active', value: profile.lastActive },
        { icon: 'bi-people', label: 'Followers', value: profile.followers },
        { icon: 'bi-person-plus', label: 'Following', value: profile.following },
        { icon: 'bi-chat-dots', label: 'Posts', value: profile.posts },
        { icon: 'bi-reply', label: 'Replies', value: profile.replies },
        { icon: 'bi-coin', label: 'Tips Received', value: profile.tipsReceived },
        { icon: 'bi-coin', label: 'Tips Given', value: profile.tipsGiven },
        { icon: 'bi-currency-exchange', label: 'Coin Value', value: profile.coinValue },
        { icon: 'bi-person-circle', label: 'Coin Holders', value: profile.coinHolders },
    ];

    const calculateDays = (timestamp) => {
        const date = new Date(timestamp);
        const today = new Date();
        const diffTime = Math.abs(today - date);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    return (
        <div className='row d-flex align-items-stretch'>
            <div className='col-12 col-md-6 col-xl-4 mb-3'>
                <div className="card shadow-sm h-100 text-center">
                    <div className='card-header pt-3'>
                        <h5><i className={`bi bi-calendar3 me-3`}></i>Account Age</h5>
                    </div>
                    <div className="card-body">
                        {additionalData && additionalData.firstTransactionTimestamp ? (
                            <div className='row h-100 d-flex align-items-center'>
                                <div className='col-6 align-items-center'>
                                    <span className='fs-3'>
                                    {calculateDays(additionalData.firstTransactionTimestamp).toLocaleString()}
                                    </span><br/>
                                    days ago
                                </div>
                                <div className='col-6 align-items-center'>
                                    <div>
                                        <h6 className="card-title">Joined</h6>
                                        <p className="card-text">
                                            <i className={`bi bi-calendar3 me-2`}></i>
                                            {new Date(additionalData?.firstTransactionTimestamp).toLocaleDateString()}<br/>
                                            <i className="bi bi-link-45deg me-2"></i>Block {additionalData?.blockHeight ? additionalData.blockHeight : <Spinner size='sm' /> }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='d-flex justify-content-center align-items-center h-100'><Spinner animation="border" /></div>)}
                    </div>
                </div>
            </div>

            <div className='col-12 col-md-6 col-xl-4 mb-3'>
                <div className="card shadow-sm h-100 text-center">
                    <div className='card-header pt-3'>
                        <h5><i className={`bi bi-people-fill me-3`}></i>Community</h5>
                    </div>
                    <div className="card-body">
                        {additionalData && additionalData.followers && additionalData.following ? (
                            <div className='row h-100 d-flex align-items-center'>
                                <div className='col-6 align-items-center'>
                                    <span className='fs-3'>
                                        {additionalData.followers.totalCount.toLocaleString()}
                                    </span><br/>
                                    Followers
                                </div>
                                <div className='col-6 align-items-center'>
                                    <span className='fs-3'>
                                        {additionalData.following.totalCount.toLocaleString()}
                                    </span><br/>
                                    Following
                                </div>
                            </div>
                        ) : (<div className='d-flex justify-content-center align-items-center h-100'><Spinner animation="border" /></div>)}
                    </div>
                </div>
            </div>
    
            <div className='col-12 col-md-6 col-xl-4 mb-3'>
                <div className="card shadow-sm h-100 text-center">
                    <div className='card-header pt-3'>
                        <h5><i className={`bi bi-gem me-3`}></i>Diamonds</h5>
                    </div>
                    <div className="card-body">
                        {additionalData && additionalData.diamondsSent && additionalData.diamondsReceived ? (
                            <div className='row h-100 d-flex align-items-center'>
                                <div className='col-6 align-items-center'>
                                    <span className='fs-3'>
                                        {additionalData.diamondsSent.totalCount.toLocaleString()}
                                    </span><br/>Given
                                </div>
                                <div className='col-6 align-items-center'>
                                    <span className='fs-3'>
                                        {additionalData.diamondsReceived.totalCount.toLocaleString()}
                                    </span><br/>Received
                                </div>
                            </div>
                        ) : (<div className='d-flex justify-content-center align-items-center h-100'><Spinner animation="border" /></div>)}
                    </div>
                </div>
            </div>
    
            <div className='col-12 col-md-6 col-xl-4 mb-3'>
                <div className="card shadow-sm h-100 text-center action" onClick={() => setActiveTab('activity')}>
                    <div className='card-header pt-3'>
                        <h5><i className={`bi bi-bar-chart-line-fill me-3`}></i>Social Activity</h5>
                    </div>
                    <div className="card-body">
                        {additionalData && additionalData.posts && additionalData.replies && additionalData.reposts ? (
                            <div className='row h-100 d-flex align-items-center'>
                                <div className='col-6 align-items-center'>
                                    <span className='fs-3'>
                                    {friendlyFormatDate(additionalData.latestTransactionTimestamp)}
                                    </span><br/>
                                    last active
                                </div>
                                <div className='col-6 align-items-center'>
                                    <div>
                                        <h6 className="card-title">Content</h6>
                                        <p className="card-text">
                                            <i className="bi bi-chat-square-text me-2"></i>{additionalData.posts.totalCount.toLocaleString()} posts<br/>
                                            <i className="bi bi-reply me-2"></i>{additionalData.replies.totalCount.toLocaleString()} replies<br/>
                                            <i className="bi bi-repeat me-2"></i>{additionalData.reposts.totalCount.toLocaleString()} reposts<br/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='d-flex justify-content-center align-items-center h-100'><Spinner animation="border" /></div>)}
                    </div>
                </div>
            </div>
    
            <div className='col-12 col-md-6 col-xl-4 mb-3'>
                <div className="card shadow-sm h-100 text-center action" onClick={() => setActiveTab('creatorcoin')}>
                    <div className='card-header pt-3'>
                        <h5><i className={`bi bi-coin me-3`}></i>Creator Coin</h5>
                    </div>
                    <div className="card-body">
                    {additionalData && additionalData?.coinPriceDesoNanos && additionalData.creatorCoinBalances ? (
                            <div className='row h-100 d-flex align-items-center'>
                                <div className='col-6 align-items-center'>
                                    <div>
                                        <span className='fs-3'>{(additionalData.coinPriceDesoNanos / 1e9).toFixed(2)}</span> DESO<br/>
                                        {localCurrency(additionalData.coinPriceDesoNanos / 1e9, preferences, exchangeRates)}
                                    </div>
                                </div>
                                <div className='col-6 align-items-center'>
                                    <div>
                                        <h6 className="card-title">Circulation</h6>
                                        <p className="card-text">
                                            <i className="bi bi-coin me-2"></i>{(additionalData.ccCoinsInCirculationNanos / 1e9).toFixed(2)} coins<br/>
                                            <i className="bi bi-people-fill me-2"></i>{additionalData.creatorCoinBalances.totalCount.toLocaleString()} holders<br/>
                                            <i className="bi bi-pie-chart-fill me-2"></i>{additionalData.hhi !== null ? additionalData.hhi : <Spinner animation="border" />} HHI
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='d-flex justify-content-center align-items-center h-100'><Spinner animation="border" /></div>)}
                    </div>
                </div>
            </div>
    
            <div className='col-12 col-md-6 col-xl-4 mb-3'>
                <div className="card shadow-sm h-100 text-center">
                    <div className='card-header pt-3'>
                        <h5><i className={`bi bi-boxes me-3`}></i>Total Transactions</h5>
                    </div>
                    <div className="card-body">
                        {additionalData && additionalData.firstTransactionTimestamp && additionalData?.count ? (
                            <div className='row h-100 d-flex align-items-center'>
                                <div className='col-6 align-items-center'>
                                    <span className='fs-3'>
                                        {parseInt(additionalData.count, 10).toLocaleString()}
                                    </span><br/>
                                    {calculateDays(additionalData.firstTransactionTimestamp) > 0
                                        ? (parseInt(additionalData.count, 10) / calculateDays(additionalData.firstTransactionTimestamp)).toFixed(0).toLocaleString() + ' per day'
                                        : 'Calculating...'}
                                </div>
                                <div className='col-6 align-items-center'>
                                    <div>
                                        <h6 className="card-title"><i className='bi bi-fire me-2'></i>Gas Fees</h6>
                                        <p className="card-text">
                                            {localCurrency(parseInt(additionalData.totalFees, 10) / 1e9, preferences, exchangeRates)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : (<div className='d-flex justify-content-center align-items-center h-100'><Spinner animation="border" /></div>)}
                    </div>
                </div>
            </div>

        </div>
    );
    
};


const PROFILE_BASIC_QUERY = `
    query ProfileTransactionStatByPublicKey(
        $publicKey: String!
    ) {
        profileTransactionStatByPublicKey(publicKey: $publicKey) {
            totalFees
            latestTransactionTimestamp
            firstTransactionTimestamp
            count
        }
    }
`;
const PROFILE_STATS_QUERY = `
    query ProfileTransactionStatByPublicKey(
        $publicKey: String!,
    ) {
        account(publicKey: $publicKey) {
            coinPriceDesoNanos
            ccCoinsInCirculationNanos
            likes {
                totalCount
            }
            followers {
                totalCount
            }
            following {
                totalCount
            }
            creatorCoinBalances {
                totalCount
            }
            creatorCoinBalancesAsCreator {
                totalCount
            }
        }
    }
`;
/*
const PROFILE_TXNS_QUERY = `
    query ProfileTransactionStatByPublicKey(
        $publicKey: String!
    ) {
        account(publicKey: $publicKey) {
            transactions {
                totalCount
            }
        }
    }
`;
*/
const PROFILE_DIAMONDS_QUERY = `
    query ProfileTransactionStatByPublicKey(
        $publicKey: String!,
    ) {
        account(publicKey: $publicKey) {
            diamondsSent {
                totalCount
            }
            diamondsReceived {
                totalCount
            }
        }
    }
`;
const PROFILE_POSTS_QUERY = `
    query ProfileTransactionStatByPublicKey(
        $publicKey: String!,
        $filter: PostAssociationFilter,
        $postsFilter: PostFilter,
        $repliesFilter: PostFilter,
        $repostsFilter: PostFilter,
    ) {
        account(publicKey: $publicKey) {
            postAssociations(filter: $filter) {
                totalCount
            }
            posts(filter: $postsFilter) {
                totalCount
            }
            replies: posts(filter: $repliesFilter) {
                totalCount
            }
            reposts: posts(filter: $repostsFilter) {
                totalCount
            }
        }
    }
`;
const PROFILE_BLOCK_HEIGHT_QUERY = `
    query ProfileTransactionStatByPublicKey($condition: BlockCondition) {
        blocks(condition: $condition) {
            nodes {
                height
            }
        }
    }
`;

export const fetchProfileStats = async (type, publicKey, additionalData) => {
    let variables = {
        "publicKey": publicKey
    };

    let query;
    switch(type) {
        case "detailed": 
            query = PROFILE_STATS_QUERY;
            Object.assign(variables, {
                "filter": {
                    "associationType": {
                        "equalToInsensitive": "REACTION"
                    }
                },
                "first": 1,
                "orderBy": "TIMESTAMP_ASC"
            });
        break;
        case "diamonds": 
            query = PROFILE_DIAMONDS_QUERY;
            Object.assign(variables, {
                "filter": {
                    "associationType": {
                        "equalToInsensitive": "REACTION"
                    }
                },
                "first": 1,
                "orderBy": "TIMESTAMP_ASC"
            });
        break;
        case "posts": 
            query = PROFILE_POSTS_QUERY; 
            Object.assign(variables, {
                "filter": {
                    "associationType": {
                        "equalToInsensitive": "REACTION"
                    }
                },
                "postsFilter": {
                    "repostedPostHash": {
                        "isNull": true
                    },
                    "parentPostExists": false
                },
                "repliesFilter": {
                    "parentPostExists": true
                },
                "repostsFilter": {
                    "repostedPostHash": {
                        "isNull": false
                    },
                },
                "first": 1,
            });
        break;
        /*
        case "transactions": 
            query = PROFILE_TXNS_QUERY; 
        break;
        */
        case "blockHeight":
            query = PROFILE_BLOCK_HEIGHT_QUERY;
            variables = {
                "condition": {
                    "timestamp": additionalData.firstTransactionTimestamp
                }
            };
        break;
        default: 
            query = PROFILE_BASIC_QUERY; 
        break;
    }

    const request = {
        query: query,
        variables: variables,
        operationName: "ProfileTransactionStatByPublicKey"
    };

    try {
        const response = await deso_graphql(request);

        // Check if the response and data are correctly structured
        if (response && response.data) {
            let profileStats;
            if(response.data?.blocks?.nodes && response.data?.blocks?.nodes[0].height) { 
                profileStats = {
                    blockHeight: response.data?.blocks?.nodes[0].height
                }
            } else if(response.data.account) { 
                profileStats = response.data.account;
            } else if (response.data.profileTransactionStatByPublicKey) {
                profileStats = response.data.profileTransactionStatByPublicKey;
            } else {
                profileStats = response.data;
            }
            //console.log("[ProfileDashboard] stats response for ", type, profileStats);
            return profileStats;
        } else {
            console.error('[ProfileDashboard] Invalid response structure:', response);
            throw new Error('Invalid response structure');
        }
    } catch (error) {
        console.error('[ProfileDashboard] Error fetching profile stats:', error);
        throw error;
    }
};

const calculateHHI = (hodlers = [], totalCirculation) => {
    // Ensure totalCirculation is a number and not zero to avoid division by zero

    //console.log("[ProfileDashboard] Stats, calcHHI:",hodlers,totalCirculation);

    if (!totalCirculation || totalCirculation <= 0) return 0;

    let hhi = 0;

    hodlers.forEach(hodler => {
        const balance = parseFloat(hodler.BalanceNanos);
        const marketShare = balance / totalCirculation;
        hhi += marketShare * marketShare;
    });

    // HHI is usually multiplied by 10,000
    return hhi;
};

export const ActivityStats = ({ profile, preferences, additionalData, setAdditionalData }) => {

    if(!profile || !additionalData || !additionalData.latestTransactionTimestamp || !additionalData.posts ) {
        return (
            <>
                <div className="row">
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="card shadow-sm h-100 text-center">
                            <div className='card-header pt-3'>
                                <h5><i className={`bi bi-clock me-3`}></i>Last Active</h5>
                            </div>
                            <div className="card-body">
                                <Spinner animation="border" />
                            </div>
                        </div>
                    </div>
            
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="card shadow-sm h-100 text-center">
                            <div className='card-header pt-3'>
                                <h5><i className={`bi bi-chat-square-text me-3`}></i>Posts</h5>
                            </div>
                            <div className="card-body">
                                <Spinner animation="border" />
                            </div>
                        </div>
                    </div>
    
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="card shadow-sm h-100 text-center">
                            <div className='card-header pt-3'>
                                <h5><i className={`bi bi-reply me-3`}></i>Replies</h5>
                            </div>
                            <div className="card-body">
                                <Spinner animation="border" />
                            </div>
                        </div>
                    </div>
    
                    <div className="col-lg-3 col-6 mb-3">
                        <div className="card shadow-sm h-100 text-center">
                            <div className='card-header pt-3'>
                                <h5><i className={`bi bi-repeat me-3`}></i>Reposts</h5>
                            </div>
                            <div className="card-body">
                                <Spinner animation="border" />
                            </div>
                        </div>
                    </div>
                </div>
          
                <div className='row'>
                    <div className='col-12 col-md-10 col-lg-6 mb-3'>
                        <UserActivityChart profile={profile} setAdditionalData={setAdditionalData} additionalData={additionalData} />
                    </div>
                    <div className='col-12 col-md-10 col-lg-6 mb-3'>
                        <UserTransactionsChart profile={profile} setAdditionalData={setAdditionalData} additionalData={additionalData} />
                    </div>
                </div>
            </>
        );
    }
    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-6 mb-3">
                    <div className="card shadow-sm h-100 text-center">
                        <div className='card-header pt-3'>
                            <h5><i className={`bi bi-clock me-3`}></i>Last Active</h5>
                        </div>
                        <div className="card-body">
                            <h5 className="fs-5">{friendlyFormatDate(additionalData.latestTransactionTimestamp)}</h5>
                        </div>
                    </div>
                </div>
        
                <div className="col-lg-3 col-6 mb-3">
                    <div className="card shadow-sm h-100 text-center">
                        <div className='card-header pt-3'>
                            <h5><i className={`bi bi-chat-square-text me-3`}></i>Posts</h5>
                        </div>
                        <div className="card-body">
                            <h5 className="fs-5">{additionalData.posts.totalCount.toLocaleString()}</h5>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-6 mb-3">
                    <div className="card shadow-sm h-100 text-center">
                        <div className='card-header pt-3'>
                            <h5><i className={`bi bi-reply me-3`}></i>Replies</h5>
                        </div>
                        <div className="card-body">
                            <h5 className="fs-5">{additionalData.replies.totalCount.toLocaleString()}</h5>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-6 mb-3">
                    <div className="card shadow-sm h-100 text-center">
                        <div className='card-header pt-3'>
                            <h5><i className={`bi bi-repeat me-3`}></i>Reposts</h5>
                        </div>
                        <div className="card-body">
                            <h5 className="fs-5">{additionalData.reposts.totalCount.toLocaleString()}</h5>
                        </div>
                    </div>
                </div>
            </div>
      
            <div className='row'>
                <div className='col-12 col-lg-6 mb-3'>
                    <UserActivityChart profile={profile} setAdditionalData={setAdditionalData} additionalData={additionalData} />
                </div>
                <div className='col-12 col-lg-6 mb-3'>
                    <UserTransactionsChart profile={profile} setAdditionalData={setAdditionalData} additionalData={additionalData} />
                </div>
            </div>
        </>
    );
};