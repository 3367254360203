import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip } from 'chart.js';
import { deso_graphql } from '../../utils/graphql';
import { friendlyFormatDate } from '../../utils/helpers';

const oneDay = 24 * 60 * 60 * 1000; // Define oneDay constant

export const calculatePeriods = (firstTransactionTimestamp) => {
  const now = new Date();
  const periods = [];

  // Convert to Date object if firstTransactionTimestamp is not already a Date
  if (!(firstTransactionTimestamp instanceof Date)) {
    firstTransactionTimestamp = new Date(firstTransactionTimestamp);
  }

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Get the month and year from the date
  const month = monthNames[firstTransactionTimestamp.getMonth()];
  const year = firstTransactionTimestamp.getFullYear().toString().slice(-2); // Get the last two digits of the year

  // Create the formatted date string
  const formattedDate = `${month} \`${year}`;

  // All Time period
  //periods.push({ label: 'Since ' + formattedDate, start: firstTransactionTimestamp, end: now });

  // Calculate yearly periods (excluding the current calendar year)
  /*
  const startYear = firstTransactionTimestamp.getFullYear();
  const endYear = now.getFullYear();
  for (let year = startYear; year < endYear; year++) {
    const startOfYear = new Date(year, 0, 1, 0, 0, 0); // January 1st, 00:00:00
    const endOfYear = new Date(year, 11, 31, 23, 59, 59, 999); // December 31st, 23:59:59.999
    periods.push({ label: `${year}`, start: startOfYear, end: endOfYear });
  }
  */
  // Last 365 days period
  const last365Start = new Date(now.getTime() - oneDay * 365);
  //periods.push({ label: 'Last 365 days', start: last365Start, end: now });

  // Last 6 months period
  const last6MonthsStart = new Date(now);
  last6MonthsStart.setMonth(last6MonthsStart.getMonth() - 6);
  //periods.push({ label: 'Last 6 months', start: last6MonthsStart, end: now });

  // Last month period
  const lastMonthStart = new Date(now);
  lastMonthStart.setMonth(lastMonthStart.getMonth() - 1);
  //periods.push({ label: 'Last Month', start: lastMonthStart, end: now });
  periods.push({ label: 'Last 30 days', start: new Date(now.getTime() - oneDay * 30), end: now });

  // Weeks ago periods
  //periods.push({ label: '4 Weeks Ago', start: new Date(now.getTime() - oneDay * 7 * 4), end: new Date(now.getTime() - oneDay * 7 * 3 - 1) });
  //periods.push({ label: '3 Weeks Ago', start: new Date(now.getTime() - oneDay * 7 * 3), end: new Date(now.getTime() - oneDay * 7 * 2 - 1) });
  periods.push({ label: 'Previous week', start: new Date(now.getTime() - oneDay * 7 * 2), end: new Date(now.getTime() - oneDay * 7 - 1) });
  periods.push({ label: 'Last 7 Days', start: new Date(now.getTime() - oneDay * 7), end: now });

  return periods;
};

// Function to calculate weeks in a period
export const calculateWeeksInPeriod = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffTime = Math.abs(end - start);
  const diffDays = diffTime / (1000 * 60 * 60 * 24);
  return diffDays / 7;
};

// Function to normalize count based on weeks in period
export const normalizeCount = (count, periodStart, periodEnd, joinDate) => {
  const start = new Date(periodStart);
  const end = new Date(periodEnd);
  const currentDate = new Date();

  // Calculate weeks in the period, adjusting for current date if necessary
  let adjustedEnd = end > currentDate ? currentDate : end;
  let weeksInPeriod = calculateWeeksInPeriod(start, adjustedEnd);

  // Adjust weeks in period based on join date
  const joinTime = new Date(joinDate).getTime();
  if (joinTime > start.getTime()) {
    weeksInPeriod = calculateWeeksInPeriod(new Date(joinDate), adjustedEnd);
  }

  const normalizedCount = count / weeksInPeriod;
  return normalizedCount;
};