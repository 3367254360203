import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { getExchangeRates } from '../utils/currency'; // Import getExchangeRates function
import { DeSoIdentityContext } from "react-deso-protocol";
import { getAllAccessGroups } from 'deso-protocol';

export const currencyOptions = {
  "DESO": { icon: null, "name": "Decentralized Social (DESO)" },
  "AUD": { icon:"dollar", "name": "Australian Dollar" },
  "BTC": { icon:"bitcoin", "name": "Bitcoin" },
  "CNY": { icon:"yen", "name": "Chinese Yuan" },
  "EUR": { icon:"euro", "name": "Euro" },
  "INR": { icon:"rupee", "name": "Indian Rupee" },
  "GBP": { icon:"pound", "name": "Pound Sterling" },
  "RUB": { icon:"rupee", "name": "Russian Ruble"},
  "USD": { icon:"dollar", "name": "US Dollar" },
  "JPY": { icon:"yen", "name": "Yen" },
};

export const ExchangeRatesContext = createContext();

export const ExchangeRatesProvider = ({ children }) => {
  const [exchangeRates, setExchangeRates] = useState(null);
  const { currentUser } = useContext(DeSoIdentityContext);
  const [accessGroups, setAccessGroups] = useState([]);

  useEffect(() => {
    const fetchRates = async () => {
      try {
        const rates = await getExchangeRates(); // Call getExchangeRates function
        //console.log("Exchange rates:", rates);
        setExchangeRates(rates);
      } catch (error) {
        //console.error('Error fetching exchange rates:', error.message);
      }
    };

    fetchRates();

    const timer = setInterval(fetchRates, 60000); // Fetch every 60 seconds

    return () => clearInterval(timer);
  }, []);

  // Add access groups here so we can utilise them all over the app
  useEffect(() => {
    // Fetch access groups when currentUser is available or whenever accessGroups state changes
    if (currentUser) {
      fetchAccessGroups(currentUser);
    }
  }, [currentUser]); // Run effect when currentUser changes or accessGroups state changes

  const fetchAccessGroups = async (currentUser) => {
    try {
      const groups = await getAllAccessGroups({ PublicKeyBase58Check: currentUser.PublicKeyBase58Check });
      setAccessGroups(groups);
    } catch (error) {
      console.error('Error fetching access groups:', error);
    }
  };

  const updateAccessGroups = async (currentUser) => {
    try {
      const groups = await getAllAccessGroups({ PublicKeyBase58Check: currentUser.PublicKeyBase58Check });
      setAccessGroups(groups);
    } catch (error) {
      console.error('Error fetching access groups:', error);
    }
  };

  return (
    <ExchangeRatesContext.Provider value={{ exchangeRates, accessGroups, updateAccessGroups }}>
      {children}
    </ExchangeRatesContext.Provider>
  );
};

export default ExchangeRatesContext;

export const useLocalCurrencyValue = (desoValue, currency, exchangeRates) => {
  // Memoize the function to prevent unnecessary recalculations
  return useMemo(() => {
    if (!desoValue) return;

    // Check if exchangeRates is available
    if (!exchangeRates) {
      // Handle the case when exchange rates are not available
      return desoValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 4 }) + ' DESO';
    }

    // Check if the selected currency exists in the exchangeRates
    if (!(currency in exchangeRates)) {
      // Handle the case when the exchange rate for the selected currency is not available
      return desoValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 4 }) + ' DESO';
    }

    // Get the exchange rate for the selected currency
    const exchangeRate = exchangeRates[currency].rate;

    // Convert DESO value to the selected currency
    const localValue = desoValue * exchangeRate;

    // Round the local value to 2 decimal places
    const roundedLocalValue = Math.round(localValue * 100) / 100;

    // Check if the rounded value is less than 0.01
    let returnValue = roundedLocalValue < 0.01 ? '>.01' : roundedLocalValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    // Format the rounded value and currency
    if (exchangeRates[currency].nativeSymbol) {
      return (
        <>
          <i className={`bi bi-currency-${exchangeRates[currency].currency.toLowerCase()}`}></i>{returnValue}
        </>
      );
    } else {
      return returnValue + ' ' + currency;
    }
  }, [desoValue, currency, exchangeRates]); // Memo dependencies
};