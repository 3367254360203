import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip } from 'chart.js';
import { deso_graphql } from '../../utils/graphql';
import { friendlyFormatDate } from '../../utils/helpers';
import { calculatePeriods, calculateWeeksInPeriod, normalizeCount } from './sharedFunctions';
import { Spinner } from 'react-bootstrap';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip);


// GraphQL query template
const GET_USER_ACTIVITY = (periods) => `
  query TotalPostsByPeriod(
    $publicKey: String!,
    ${generateFilterVariables(periods.length)} 
  ) {
    accountByPublicKey(publicKey: $publicKey) {
      ${generatePostFilters(periods.length)}
    }
  }
`;

// Function to generate dynamic GraphQL filter variables
const generateFilterVariables = (numPeriods) => {
  let filterVariables = '';
  for (let i = 0; i < numPeriods; i++) {
    filterVariables += `$filter${i + 1}: PostFilter, `;
    filterVariables += `$repliesFilter${i + 1}: PostFilter, `;
  }
  return filterVariables;
};

// Function to generate dynamic GraphQL post filters
const generatePostFilters = (numPeriods) => {
  let postFilters = '';
  for (let i = 0; i < numPeriods; i++) {
    postFilters += `
      posts${i + 1}: posts(filter: $filter${i + 1}) {
        totalCount
      }
      replies${i + 1}: posts(filter: $repliesFilter${i + 1}) {
        totalCount
      }
    `;
  }
  return postFilters;
};


// Function to calculate GraphQL filters for posts and replies
export const calculateFilters = (periods) => {
    const filters = {};
  
    periods.forEach((period, index) => {
      const start = period.start ? period.start.toISOString() : null;
      const end = period.end.toISOString();
  
      filters[`filter${index + 1}`] = {
        timestamp: {
          greaterThanOrEqualTo: start,
          lessThan: end
        },
        parentPostExists: index >= 5, // Adjust for replies filters starting from index 5
        repostedPostHash: { isNull: true }
      };
  
      filters[`repliesFilter${index + 1}`] = {
        timestamp: {
          greaterThanOrEqualTo: start,
          lessThan: end
        },
        parentPostExists: true,
        repostedPostHash: { isNull: true }
      };
    });
  
    return filters;
  };

const UserActivityChart = ({ profile, setAdditionalData, additionalData }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const username = profile.Username;
  
      const loadUserData = async () => {
        const periods = calculatePeriods(additionalData.firstTransactionTimestamp);
  
        const request = {
          query: GET_USER_ACTIVITY(periods),
          variables: {
            publicKey: profile.PublicKeyBase58Check,
            ...calculateFilters(periods)
          },
          operationName: "TotalPostsByPeriod"
        };
  
        try {
          const response = await deso_graphql(request,'https://graphql.focus.xyz/graphql');
          const data = response.data;
  
          const postsData = periods.map((period, index) => {
            const count = data.accountByUsername[`posts${index + 1}`]?.totalCount || 0;
            return normalizeCount(count, period.start, period.end, additionalData.firstTransactionTimestamp);
          });
  
          const repliesData = periods.map((period, index) => {
            const count = data.accountByUsername[`replies${index + 1}`]?.totalCount || 0;
            return normalizeCount(count, period.start, period.end, additionalData.firstTransactionTimestamp);
          });
  
          const updatedData = {
            ...additionalData,
            contentActivity: {
              postsData,
              repliesData,
              labels: periods.map(period => period.label) // Include labels in contentActivity
            }
          };
  
          setAdditionalData(updatedData); // Save updatedData to additionalData
          setLoading(false);
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      };
  
      if(!additionalData) {
        // wait
      } else if (additionalData && additionalData.firstTransactionTimestamp && !additionalData.contentActivity) {
        loadUserData();
      } else if (additionalData.firstTransactionTimestamp && additionalData.contentActivity) {
        setLoading(false); // Set loading to false if data is already present
      } else {
        setLoading(false);
      }
    }, [profile, additionalData]);
  
    if (loading || !additionalData.contentActivity || !additionalData.contentActivity?.labels) return (
        <div className="card shadow-sm h-100 placeholder-glow">
            <div className='card-header pt-3'>
                <h5><i className={`bi bi-calendar3 me-3`}></i>Content Trend</h5>
                Average weekly posts and replies.
            </div>
            <div className="card-body text-center">
                <Spinner animation="border" />
            </div>
        </div>
      );
    if (error) return (
        <div className="card shadow-sm h-100 placeholder-glow">
            <div className='card-header pt-3'>
                <h5><i className={`bi bi-calendar3 me-3`}></i>Content Trend</h5>
                Average weekly posts and replies.
            </div>
            <div className="card-body text-center">
                <p>Error fetching data: {error.message}</p>
            </div>
        </div>
      );
  
    // Ensure chartData is not null before rendering the Bar component
    //if (!additionalData.contentActivity?.labels) return <p>No labels</p>;
  
    const chartData = {
      labels: additionalData.contentActivity.labels,
      datasets: [
        {
          label: 'Posts',
          data: additionalData.contentActivity.postsData,
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          stack: 'stack1'
        },
        {
          label: 'Replies',
          data: additionalData.contentActivity.repliesData,
          backgroundColor: 'rgba(153, 102, 255, 0.6)',
          stack: 'stack1'
        }
      ]
    };
  
    const options = {
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const datasetLabel = tooltipItem.dataset.label || '';
              const value = parseFloat(tooltipItem.raw).toFixed(1);
              return `${datasetLabel}: ${value}`;
            }
          }
        }
      }
    };
  
    return (
        <div className="card shadow-sm h-100">
            <div className='card-header pt-3'>
                <h5><i className={`bi bi-calendar3 me-3`}></i>Content Trend</h5>
                Average weekly posts and replies.
            </div>
            <div className="card-body text-center">
                <Bar data={chartData} options={options} />
            </div>
        </div>
      );
      
  };
export default UserActivityChart;
