export async function deso_api(api_endpoint, api_request = null, use_node = 'https://node.deso.org', api_version = 0, method = 'POST') {
  let node = use_node;
  if(node === "null" || !node ) { node = 'https://node.deso.org'; }
  //console.log("[deso_api] endpoint, query, node: ", api_endpoint, api_request, use_node);
  let nodeUrl = node + '/api/v' + api_version + '/' + api_endpoint;

  const post = JSON.stringify(api_request);
  const headers = {
    'Content-Type': 'application/json',
    'Content-Length': post.length.toString()
  };

  const options = {
    method: method,
    headers: headers,
    body: JSON.stringify(api_request),
    mode: 'cors'
  };

  try {
    const response = await fetch(nodeUrl, options);
    //console.log("[deso_api] ", response);

    if (response.ok) {
        return response.json().catch(() => (Array.isArray(response) ? [] : {}));
    } else {
      // Handle non-OK responses here
      //console.error('[deso_api] API Error - endpoint, request, response:', api_endpoint, api_request, response);
      return null;
    }
  } catch (error) {
    //console.error('[deso_api] API Error - endpoint, request, error:', api_endpoint, api_request, error);
    return null;
  }
}


export async function deso_graphql(api_request = null, use_node = null) {
    const node = 'https://graphql-prod.deso.com/graphql';
    //const node = 'https://graphql.focus.xyz/graphql';
    const method = "POST";
    let nodeUrl = node;
    if (use_node !== null) {
        nodeUrl = use_node;
    }
    const post = JSON.stringify(api_request);
    const headers = {
        'Content-Type': 'application/json',
        'Content-Length': post.length.toString()
    };
  
    const options = {
        method: method,
        headers: headers,
        body: post
    };
  
    try {
        const response = await fetch(nodeUrl, options);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const result = await response.json();
        return result;
    } catch (error) {
        //console.error('GraphQL Error:', error);
        return null;
    }
  }