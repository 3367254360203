import React, { useState, useEffect, useContext, useRef } from 'react';
import { FormatPost, getPosts, remapCommentTree } from '../utils/posts';
import { formatPost } from '../utils/posts';
import luceneQueryParser from 'lucene-query-parser';
import { Row } from 'react-bootstrap';
import { CarouselFeedOutput, GridOutput, GridView, MediaView, OutputPost, PostPlaceholder } from '../utils/postLayouts';
import { AppDataContext } from '../contexts/appData';

const processSearchTerm = (searchTerm) => {
  try {
    // Parse the search term using Lucene Query Parser
    var results = luceneQueryParser.parse(searchTerm);
    //console.log("[ContentResults.jsx] lucene return:", results);
    
    // Function to recursively build filter conditions
    const buildFilter = (query) => {
      if (!query) return null;
      
      if (query.operator === "AND" || query.operator === "OR" || query.operator === "<implicit>") {
        // Handle AND and OR operators
        const leftFilter = buildFilter(query.left);
        const rightFilter = buildFilter(query.right);
        if (leftFilter && rightFilter) {
          if(query.operator === "<implicit>") { query.operator = "AND"; }
          return { [query.operator.toLowerCase()]: [leftFilter, rightFilter] };
        } else if (leftFilter) {
          return leftFilter;
        } else if (rightFilter) {
          return rightFilter;
        }
      } else if (query.term) {
        // Handle individual terms
        return { body: { includesInsensitive: query.term } };
      } else if (query.left && !query.right) {
        return { body: { includesInsensitive: query.left.term } };
      }
      
      return null;
    };
    
    // Build filter conditions recursively
    const filterConditions = buildFilter(results);
    // console.log("[ContentResults.jsx] Filter conditions:", filterConditions);
    
    return filterConditions;
  } catch (error) {
    // console.error('Error processing search term:', error);
    return null;
  }
};


export const ContentResults = ({ showSearch, search, view, setView, formData, searchTriggered, setSearchTriggered, currentUser, alternateUsers, preferences, accessGroups, feedType, profile, setActiveTab, activeTab }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [startCursor, setStartCursor] = useState(null);  
  const [endCursor, setEndCursor] = useState(null);  
  const [searchRequested, setSearchRequested] = useState(false);
  const [newerPosts, setNewerPosts] = useState([]);
  const [formattedPosts, setFormattedPosts] = useState([]);
  const [mediaURLs, setMediaURLs] = useState([]);
  const [isReformatting, setIsReformatting ] = useState(false);
  const [reformatPosts, setReformatPosts] = useState(false);
  const [prependPosts, setPrependPosts] = useState(false);
  const [seenPostHashHexes, setSeenPostHashHexes] = useState([]);
  const [awaitingResults, setAwaitingResults] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [error, setError] = useState(null);
  const { appData } = useContext(AppDataContext);
  const [limit, setLimit] = useState(20);
  const [nonMediaPosts, setNonMediaPosts] = useState(0);

  const abortControllerRef = useRef(null);
  const debounceTimeoutRef = useRef(null);

  //console.log("[ContentResults.jsx] feedType: ", feedType, posts);

  const resetSearch = () => {
    setPosts([]);
    setFormattedPosts([]);
    setMediaURLs([]);
    setEndCursor(null);
    setStartCursor(null);
    setNewerPosts([]);
    setFormattedPosts([]);
    setSeenPostHashHexes([]);
    setHasNextPage(null);
    setHasPreviousPage(null);
    setAwaitingResults(null);
    setMediaURLs([]);
    setError(null);
  }

  useEffect(() => {
    if (!searchTriggered && !showSearch && !search) {
      setIsLoading(true);
      resetSearch();
      fetchData(formData);
    }
  }, []);
 
  useEffect(() => {
    //console.log("[SearchResults.jsx] SEARCHTRIGGERED Changed:", searchTriggered);
    if (searchTriggered) {
      resetSearch();
      setIsLoading(true);
      //console.log("[SearchResults.jsx] SEARCHTRIGGERED , isLoading:", isLoading);
      fetchData(formData);
    }
  }, [searchTriggered]);

  useEffect(() => {
    resetSearch();
    console.log("FORMDATA: ",formData);
    if(!showSearch && (!profile && (feedType !== 'home' && feedType !== 'stats'))) { 
      //console.log("[SearchResults.jsx] search options NOT visible - so trigger search... (current state) ",searchTriggered);
      setIsLoading(true);
      fetchData(formData)
      setSearchTriggered(true); 
    } else {
      setSearchTriggered(false);
    }
  }, [formData]);

  const fetchData = async (formData, cursor = null, isFetchingNewer = false) => {
    // Clear any existing debounce timeout
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Debounce: Only execute fetch after 3 seconds of no changes
    debounceTimeoutRef.current = setTimeout(async () => {
      // Abort previous request if it exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new controller for the current request
      abortControllerRef.current = new AbortController();
      const { signal } = abortControllerRef.current;

      setFetchingData(true);
      setSearchRequested(false);

      try {
        setError(null);
        setAwaitingResults(true);

        let data = { formData };
        let prevSeenPostHashHexes = [];
        let prevPosts = [];

        // Set up cursor and previous data
        if (cursor) {
          data.after = isFetchingNewer ? null : cursor;
          data.before = isFetchingNewer ? cursor : null;
          data.first = isFetchingNewer ? null : 25;
          prevSeenPostHashHexes = seenPostHashHexes;
          prevPosts = posts;
        } else {
          setPosts([]);
          setSeenPostHashHexes([]);
          data.after = null;
          data.before = null;
        }

        // Collect form data
        if (!formData.orderBy) {
          const contentFormValue = document.getElementById('contentFormValue');
          if (contentFormValue) {
            contentFormValue.value = formData.searchTerm;
          }

          const form = document.getElementById('contentFormFilter');
          if (form) {
            const formValues = new FormData(form);
            formValues.forEach((value, key) => {
              data[key] = value;
            });
          }
        }

        const variables = processFormData(data, cursor, isFetchingNewer);
        const postData = await getPosts(variables, { signal });

        if (postData && postData.nodes) {
          const filteredPostsBySeenHash = postData.nodes.filter(
            post => !prevSeenPostHashHexes.includes(post.PostFound.PostHashHex)
          );

          const filteredPosts = filteredPostsBySeenHash.filter(
            post => !prevPosts.some(existingPost => existingPost.PostFound.PostHashHex === post.PostFound.PostHashHex)
          );

          const newPostHashHexes = filteredPosts.map(post => post.PostFound.PostHashHex);
          const combinedPostHashHexes = [...new Set([...prevSeenPostHashHexes, ...newPostHashHexes])];
          setSeenPostHashHexes(combinedPostHashHexes);

          if (isFetchingNewer) {
            setNewerPosts(filteredPosts);
            if (postData.pageInfo) {
              setHasPreviousPage(postData.pageInfo.hasPreviousPage);
              setStartCursor(postData.pageInfo.startCursor);
            }
          } else {
            setPosts([...prevPosts, ...filteredPosts]);
            if (postData.pageInfo) {
              if (!hasPreviousPage) setHasPreviousPage(postData.pageInfo.hasPreviousPage);
              if (!startCursor) setStartCursor(postData.pageInfo.startCursor);
              setHasNextPage(postData.pageInfo.hasNextPage);
              if (postData.pageInfo.endCursor) {
                setEndCursor(postData.pageInfo.endCursor);
              }
            }
          }
        }
      } catch (error) {
        if (error.name !== 'AbortError') {  // Ignore AbortErrors
          console.error('Error fetching data:', error);
          setError(error);
        }
      } finally {
        setSearchRequested(true);
        setAwaitingResults(false);
        setIsLoading(false);
        setFetchingData(false);
      }
    }, 3000); // 3-second debounce
  };


  const loadMore = () => {
    setIsLoading(true);
    fetchData(formData, endCursor, false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (newerPosts === null || newerPosts.length === 0) { // Only call if newerPosts is null
        checkForNewMessages();
      }
    }, 10000); // Check every 10 seconds
    
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [startCursor, newerPosts]); // Add newerPosts to dependencies
  

  /*useEffect(() => {
    console.log("[ContentResults.jsx] New messages: ",newerPosts);
  }, [newerPosts]);*/
  
  const checkForNewMessages = async () => {
    if (startCursor) {
      const result = await fetchData(formData, startCursor, true); // Fetch newer posts using startCursor
    }
  };

  const showNewPosts = () => {
    //console.log("[ContentResults.jsx] SHOW NEW POSTS adding: ",newerPosts);
    setPrependPosts(true);
    setPosts(prevPosts => [...newerPosts, ...prevPosts]);
    setNewerPosts([]);
  }

  const processFormData = (payload, cursor = null, isFetchingNewer = null) => {
    //console.log("[Search] form data at processFormData:", payload);
    const formData = payload.formData;
    const variables = {
      condition: {},
      filter: { and: [] },
      first: limit,
      orderBy: [formData.orderBy],
    }; 

    if(isFetchingNewer) {
      variables.before = cursor;
    } else {
      variables.after = cursor;
    }

    //console.log("[Search] formData:",formData);
    //console.log("[Search] formData search:",formData["search"]);
    //console.log("[Search] formData blog:",formData["blog"]);
    if(profile) {
      //formData.poster = profile.Username
      formData.poster = null;
      formData.posterPublicKey = [profile.PublicKeyBase58Check];
    }
    Object.keys(formData).forEach(field => {
      //console.log("[Search] formData field, value:",field,formData[field]); //,formData[field]);

      if (field === 'search' && formData[field]) {
        /* original simple
        const searchFilter = {
          body: {
            includesInsensitive: formData[field]
          }
        };
        variables.filter.and.push(searchFilter);*/
        //console.log("[Search] Search term:",formData[field]);
        const searchFilter = processSearchTerm(formData[field]);
        //console.log("[Search] Search filter:",searchFilter);
        if (searchFilter) {
          variables.filter.and.push(searchFilter);
        }
      } else if (field === 'topic' && formData[field]) {
        // Topic-based content search
        const requestedAssociation = formData[field]; // The entire association object
        //console.log("[ContentResults.jsx] TOPIC requested: ", requestedAssociation);
      
        // Check if appData.contentTopics exists and is an array
        if (Array.isArray(appData?.contentTopics)) {
          // Find the topic that matches the supplied association
          const foundTopic = appData.contentTopics.find(topic => 
            topic.AssociationID === requestedAssociation.AssociationID ||
            (topic.AssociationValue === requestedAssociation.AssociationValue &&
             topic.AppPublicKeyBase58Check === requestedAssociation.AppPublicKeyBase58Check)
          );
      
          if (foundTopic) {
            //console.log("[ContentResults.jsx] TOPIC found: ", foundTopic);
            const orConditions = [];

            // Add the topic-based association conditions
            orConditions.push({
              postAssociationsByPostHash: {
                some: {
                  and: [
                    {
                      associationType: {
                        equalTo: foundTopic.AssociationType // Use the actual value from the association
                      }
                    },
                    {
                      associationValue: {
                        equalTo: foundTopic.AssociationValue // Use the actual value from the association
                      }
                    }
                  ]
                }
              }
            });

            // Add body.includesInsensitive condition for hashtags
            if (foundTopic.ExtraData && foundTopic.ExtraData.hashtags) {
              let hashtags = [];
              try {
                hashtags = JSON.parse(foundTopic.ExtraData.hashtags);
              } catch (error) {
                console.error("Error parsing hashtags:", error);
              }

              hashtags.forEach(tag => {
                orConditions.push({
                  body: {
                    includesInsensitive: ' #'+tag // Add each hashtag as a condition
                  }
                });
              });
            }

            // Now, add the or conditions to the filter
            if (orConditions.length > 0) {
              variables.filter.and.push({
                or: orConditions
              });
            }
          } else {
            //console.log("[ContentResults.jsx] TOPIC NOT FOUND: ", requestedAssociation, appData.contentTopics);
          }
        } else {
          //console.log("[ContentResults.jsx] No contentTopics available.");
        }

      } else if (field === 'tabSearch' && formData[field]) {
        /* original simple
        const searchFilter = {
          body: {
            includesInsensitive: formData[field]
          }
        };
        variables.filter.and.push(searchFilter);*/
        //console.log("[Search] Search term:",formData[field]);
        const searchFilter = processSearchTerm(formData[field]);
        //console.log("[Search] Search filter:",searchFilter);
        if (searchFilter) {
          variables.filter.and.push(searchFilter);
        }
      } else if (field === 'blog') {
        //console.log("[Search] Processing blog field:", formData[field]);
        if (formData[field] === true) {
            /*
            // Construct blog filter
            const blogFilter = [
                { "extraData": { "containsKey": "BlogTitleSlug", "notEqualTo": null  } },
                { "extraData": { "containsKey": "BlogDeltaRtfFormat" } }
            ];
            // Clear certain conditions if necessary
            variables.condition.parentPostHash = null;
            variables.condition.repostedPostHash = null;
            // Push blog filter to variables.filter.and
            variables.filter.and.push({ "or": blogFilter });
            */
            variables.filter.and.push({
              "and": [
                {
                  "not": {
                    "or": [
                      {
                        "extraData": {
                          "contains": { "BlogTitleSlug": "" }
                        }
                      },
                      {
                        "extraData": {
                          "contains": { "BlogTitleSlug": null }
                        }
                      }
                    ]
                  }
                },
                {
                  "extraData": {
                    "containsKey": "BlogTitleSlug"
                  }
                }
              ]
            });
        }
      /*
      } else if (field === 'mentions' && formData[field].length > 0) {
        formData[field].forEach((mention) => {
          const mentionValue = Object.keys(mention)[0]; // Get the mention value from the object
          console.log("[Search] mentions:", mentionValue); // Log the mention value
          const searchFilter = {
            'body': {
              'includesInsensitive': mentionValue // Use the mention value in the filter
            }
          };
          variables.filter.and.push(searchFilter);
        });*/
      } else if (field === 'mentions' && Array.isArray(formData[field]) && formData[field].length > 0) {
        const mentionFilters = formData[field].map(mention => {
            const mentionValue = Object.keys(mention)[0]; // Get the mention value from the object
            //console.log("[Search] mentions:", mentionValue); // Log the mention value
            return {
                'body': {
                    'includesInsensitive': mentionValue // Use the mention value in the filter
                }
            };
        });
    
        if (mentionFilters.length > 0) {
            const mentionsFilter = {
                "or": mentionFilters
            };
            variables.filter.and.push(mentionsFilter);
        }    
      } else if (field === 'parentPostHash' && formData[field]) {
        const fieldValue = formData[field];
        if (fieldValue !== null) {
          const parentPostHashFilter = {
            parentPostHash: {
              isNull: !fieldValue,
            }
          };
          variables.filter.and.push(parentPostHashFilter);
        }
      } else if ((field === 'startDate' || field === 'endDate') && formData[field] !== null) {
        const fieldValue = formData[field].trim();
        if (fieldValue) {
          const timestampFilter = {
            timestamp: {
              [field === 'startDate' ? 'greaterThanOrEqualTo' : 'lessThanOrEqualTo']: fieldValue
            }
          };
          variables.filter.and.push(timestampFilter);
        }
      } else if (field === 'parentPoster' && typeof formData[field] === 'string' && formData[field]) {
        const fieldValue = formData[field] ? formData[field].trim() : null;
        if (fieldValue && fieldValue !== '') {
          const parentPosterFilter = {
            'parentPost': {
              'poster': {
                'username': {
                  'equalToInsensitive': fieldValue
                }
              }
            }
          };
          variables.filter.and.push(parentPosterFilter);
        }
      } else if (field === 'poster' && formData[field]) {
        const fieldValue = formData[field].trim();
        if (fieldValue !== '') {
          const userFilter = {};
          userFilter['poster'] = {
            'username': {
              'equalToInsensitive': fieldValue || null
            }
          };
          variables.filter.and.push(userFilter);
        }
      /*
      } else if ((field === 'posterPublicKey' || field === 'publicKey') && formData[field]) {
        const fieldValue = formData[field].trim();
        if (fieldValue !== '') {
          const userFilter = {};
          userFilter['posterPublicKey'] = {
            'equalTo': fieldValue || null
          };
          variables.filter.and.push(userFilter);
        }
      */
      } else if ((field === 'posterPublicKey' || field === 'publicKey') && Array.isArray(formData[field]) && formData[field].length > 0) {
        const fieldValues = formData[field].map(value => value.trim()).filter(value => value !== '');
        if (fieldValues.length > 0) {
            const userFilter = {
                "or": fieldValues.map(value => ({
                    [field]: {
                        'equalTo': value
                    }
                }))
            };
            variables.filter.and.push(userFilter);
        }
      } else if (field === 'filterNumNftCopiesForSale' && formData[field]) {
        const numNftCopiesFilter = {};
        numNftCopiesFilter['numNftCopiesForSale'] = {
          'greaterThanOrEqualTo': 1
        };
        variables.filter.and.push(numNftCopiesFilter);
      } else if (field === 'repliesExist' && formData[field]) {
        const repliesExistFilter = {};
        repliesExistFilter['replies'] = {
          'every': {
            "repliesExist": true
          }
        };
        variables.filter.and.push(repliesExistFilter);
      } else if (field === 'repostedPostExists' && formData[field]) {
        const repostedPostExistsFilter = {
          "repostedPostExists": true
        };
        variables.filter.and.push(repostedPostExistsFilter);
      } else if (field === 'imageUrls' || field === 'videoUrls' || field === 'parentPostHash') {

        //console.log("[SearchResults.jsx] condition: ",field);
        if (formData[field] !== null) {
          if(formData[field] === false) {
            variables.filter.and.push({
                "or": [
                    { [field]: { "isNull": true } },
                    { [field]: { "equalTo": "" } }
                ]
            });
          } else {
            variables.filter.and.push({ [field]: { "isNull": false } });
            variables.filter.and.push({ [field]: { "notEqualTo": "" } });
          }
        }
      } else if (field === 'repostedPostHash' && formData[field] === false && formData["isQuotedRepost"] !== true) {
        variables.filter.and.push({ [field]: { isNull: true } });
        if(formData["isQuotedRepost"]) {
          // Must be Quote Repost
          // Therefore repostedPostHash must also be true
        } else if(formData["isQuotedRepost"]===false) {
          // Must not be a Quote Repost
          // Therefore repostedPostHash can be true or false
        } else { 
          // Can be a quote repost
          // Therefore repostedPostHash must also be true
        }

      } else if (field === "embedUrlFilter" && formData[field] !== null) {
        
      } else if (field === "embedVideoUrl" && formData[field] !== null) {
        if(formData[field]===true) {
          const embedVideoUrlFilter = {
            "and": [
              {
                "extraData": {
                  "containsAnyKeys": "EmbedVideoURL",
                },
                "not": {
                  "extraData": {
                      "contains": { "EmbedVideoURL": "", }
                  },
                }
              }
            ]
          }; 
          variables.filter.and.push(embedVideoUrlFilter);
        } else {
          const embedVideoUrlFilter = {
            "or": [
              {
                "not": {
                  "extraData": {
                    "containsAnyKeys": "EmbedVideoURL",
                  },
                },
                "extraData": {
                      "contains": { "EmbedVideoURL": "", }
                },
              }
            ]
          }; 
          variables.filter.and.push(embedVideoUrlFilter);
        }
      } else if (field === "subscription" && formData[field]) {
        // subscription filter
        const subscriptionFilter = {
              "extraData": {
                  "containsKey": "SubscriptionAccessGroupKeyName",
              },
        }; 
        variables.filter.and.push(subscriptionFilter);
      } else if (field === "node" && formData[field].length > 0) {
        // console.log("[SearchResults] We have a node selection: ", formData[field]);
        const orStatements = formData[field].map(nodeId => ({
            "extraData": {
                "contains": {"Node": `${nodeId}`}
            }
        }));
        variables.filter.and.push({ OR: orStatements });
      } else if (field === "isFollowing" && formData[field] !== null) {
        const isFollowing = {
            "poster": {
              "profile": {
                "account": {
                  "followers": {
                    "some": {
                      "followerPkid": {
                        "equalTo": currentUser?.PublicKeyBase58Check
                      }
                    }
                  }
                }
              }
            }
          }; 
        variables.filter.and.push(isFollowing);
      } else if (field === "isHolder" && formData[field] !== null) {
        const isHolder = {
          "posterPublicKey": {
              "in": appData?.coinHolders?.Hodlers
                  ? appData.coinHolders?.Hodlers.map(holder => holder.HODLerPublicKeyBase58Check)
                  : []
            }
        };
        variables.filter.and.push(isHolder);
      } else if (field === "isHolding" && formData[field] !== null) {
        const isHolding = {
          "posterPublicKey": {
              "in": currentUser?.UsersYouHODL
                  ? currentUser?.UsersYouHODL.map(holder => holder.CreatorPublicKeyBase58Check)
                  : []
          }
        };
        variables.filter.and.push(isHolding);
      } else if ((field.startsWith('is') || field.startsWith('has') || field==='numNftCopies') && formData[field] !== null) { //} && formData[field]) {
        if(field==='isPinned') {
          // add extradata workaround
          const isPinnedExtraData = {
            "extraData": {
                      "contains": { "IsPinned": "true", }
                }
            }; 
          variables.filter.and.push(isPinnedExtraData);
        } else {
          variables.condition[field] = formData[field];
        }
      } else {
        //console.log("[SearchResults.jsx] ** No Processing ** - formData field:",field,formData[field]);
      }
      //console.log("[SearchResults.jsx] finished formData field loop, isLoading:", isLoading);
    });
    //console.log("[SearchResults.jsx] finished formData fields, isLoading:", isLoading);
    //document.getElementById('navbarSearchInput').value = formData.search;
    const ariaOwnsValue = 'navbarSearchInput'; // The value of the aria-owns attribute
    const inputElement = document.querySelector(`[aria-owns="${ariaOwnsValue}"]`);
    if (inputElement) {
      inputElement.value = formData.search;
    }
    document.getElementById('contentFormValue').value = formData.search;
    //console.log("[SearchResults.jsx] end of build formData, isLoading:", isLoading, variables);
    return variables;
  };
  
  if(profile && profile.Username && (activeTab==='home' || activeTab==='stats')) { 
    return; 
  }

  let classCol;
  
  return (
    <React.Fragment key={`feed_content_${feedType}`}>
      <div className={`p-3 col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xxl-4 offset-xxl-4`} style={{ minHeight: "70vh" }}>

        {view === 'list' ? (
          <>
            {newerPosts && newerPosts.length > 0  && (
              <div key='contentBarNewer' className='text-center'>
                <button className="col-6 mx-auto btn btn-primary my-3" onClick={() => showNewPosts()}>
                    {formData.orderBy === 'TIMESTAMP_DESC' ? `Newer` : `Older`} messages
                </button>
              </div>
            )}

            {posts && posts.length > 0 && (
              <Row>
                {posts.map((post, index) =>(
                  <React.Fragment key={`${index}_${post.PostHashHex}`}>
                    <OutputPost key={`${index}_output_${post.PostHashHex}`} postData={post} currentUser={currentUser} level={null} type={null} view={view} accessGroups={accessGroups} preferences={preferences} thread={null} preview={null} alternateUsers={alternateUsers} isExpanded={false} />
                  </React.Fragment>
                ))}
              </Row>
            )}

            {!isLoading && !awaitingResults && (!posts || posts.length === 0) && !isReformatting && searchTriggered && (
              <>
                {search ? (
                  <div className="col-12 text-center py-5">
                    <p className="fw-bold">Your search yielded no results</p>
                    <p>Please try again with a different search term or filters.</p>
                  </div>
                ) : (
                  <p className="col-12 text-center py-5">There are no {feedType} items</p>
                )}
              </>
            )}

            {(isLoading || isReformatting) && (<PostPlaceholder feedType={feedType} view={view} />)}

            {searchTriggered !== null && posts.length > 0 && !isLoading && hasNextPage && endCursor && (
              <div className='text-center'>
                <button className="col-6 mx-auto btn btn-primary my-3" onClick={() => loadMore()}>
                    Load More
                </button>
              </div>
            )}
          </>
        ) : (
          <>
            {view === 'media' && (
              <MediaView posts={posts} currentUser={currentUser} newerPosts={newerPosts} showNewPosts={showNewPosts} hasNextPage={hasNextPage} loadMore={loadMore} view={view} isLoading={isLoading} searchTriggered={searchTriggered} endCursor={endCursor} />              
            )}

            {view === 'grid' && (
              <GridView posts={posts} currentUser={currentUser} newerPosts={newerPosts} showNewPosts={showNewPosts} hasNextPage={hasNextPage} loadMore={loadMore} view={view} isLoading={isLoading} searchTriggered={searchTriggered} endCursor={endCursor} />              
            )}

            { view === 'slideshow' && (
              <CarouselFeedOutput posts={posts} view={view} currentUser={currentUser} accessGroups={accessGroups} preferences={preferences} alternateUsers={alternateUsers} newerPosts={newerPosts} showNewPosts={showNewPosts} hasNextPage={hasNextPage} loadMore={loadMore} />  
            )}

            {!isLoading && !awaitingResults && (!posts || posts.length === 0) && searchTriggered && (
              <>
                {search ? (
                  <div className="col-12 text-center py-5">
                    <p className="fw-bold">Your search yielded no results</p>
                    <p>Please try again with a different search term or filters.</p>
                  </div>
                ) : (
                  <p className="col-12 text-center py-5">There are no {feedType} items</p>
                )}
              </>
            )}   
              </>
            )}
      </div>
    </React.Fragment>
);
};
