import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ProfileCard, UserInput } from "../utils/profiles";
import { Loader } from "../utils/helpers";
import { getProfileFromCache } from "../services/profileCache";
import { DeSoIdentityContext } from "react-deso-protocol";
import { AccessGroupsContext } from "../contexts/AccessGroups";
import ExchangeRatesContext from "../contexts/ExchangeRatesContext";
import { useUserPreferences } from "../contexts/UserPreferences";

export const User = () => {
  const { username, tab, blog, feed } = useParams();
  const { currentUser, alternateUsers } = useContext(DeSoIdentityContext);
  const { accessGroups } = useContext(AccessGroupsContext);
  const { preferences } = useUserPreferences();
  const { exchangeRates } = useContext(ExchangeRatesContext);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();
  //console.log("[user.jsx] username, tab, blog, feed, currentUser, preferences", username, tab, blog, feed, currentUser, preferences,exchangeRates);

  useEffect(() => {
    setLoading(true);
    const fetchProfile = async () => {
      try {
        const profileData = await getProfileFromCache(username);
        setProfile(profileData);
        //console.log("[User.jsx] Profile: ",profileData);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        // Handle error
        setLoading(false); // Set loading to false in case of error
      }
    };
    fetchProfile();
  }, [username]);

  useEffect(() => {
    // Scroll to the top of the screen whenever the profile changes
    window.scrollTo(0, 0);
  }, [profile]);

  if (loading) {
    // Render loader if still loading
    return (
      <div className="container">
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="my-5 py-5">
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  const navigateTo = (selectedUsername) => {
    //console.log("[User.jsx] navigateTo: ",selectedUsername);
    navigate(`/u/${selectedUsername.value}`);
  };

  return (
    <>
      {profile && profile.PublicKeyBase58Check ? (
        <ProfileCard profile={profile} mode={`full`} currentUser={currentUser} alternateUsers={alternateUsers} accessGroups={accessGroups} preferences={preferences} exchangeRates={exchangeRates} tab={tab} feed={feed} />
      ) : (
        <div className="container">
          <div className="d-flex flex-column justify-content-center align-items-center vh-100">
            <div className="my-5 py-5">
              <h1>Sorry, profile does not exist</h1>
              <p>'{username} does not exist.</p>
            </div>
            <div className="">
              <p>Try finding another profile:</p>
              <UserInput onSelect={navigateTo} />
            </div>
          </div>
      </div>
      )}
    </>
  );
};

