import React, { useContext, useEffect, useRef, useState } from "react";
import formatPost, { BodyOutput, PostMedia, remapCommentTree, ShareLink } from "./posts";
import { Carousel, OverlayTrigger, Row, Tooltip, Modal, Container, Spinner } from 'react-bootstrap';
import { Bookmark, DirectTipButton, handlePinPost, HidePost, SendDiamondsButton, SendReactionButton, SendRepostButton, updateBookmark, UserModeration } from "./transactions";
import { Avatar } from "./layouts";
import { friendlyFormatDate } from "./helpers";
import { PostAttachments, TweetEmbed } from "./postAttachments";
import { PostComments } from "./postComments";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Link } from "react-router-dom";
import Masonry from 'react-masonry-css';
import { AppDataContext } from "../contexts/appData";

import Quill from 'quill';
import ReactMarkdown from 'react-markdown';
import { nl2br } from './helpers';
import { createPostAssociation, deletePostAssociation, getPostAssociations, getSinglePost } from "deso-protocol";


import { Chart, PieController, DoughnutController, BarController, CategoryScale, LinearScale, Tooltip as ChartTooltip, Legend, ArcElement } from 'chart.js';

Chart.register(PieController, DoughnutController, BarController, CategoryScale, LinearScale, ChartTooltip, Legend, ArcElement);



export const MediaView = ({
  posts,
  currentUser,
  newerPosts,
  showNewPosts,
  hasNextPage,
  loadMore,
  view,
  isLoading,
  searchTriggered,
  endCursor,
  accessGroups,
  preferences,
  alternateUsers,
}) => {

  const breakpointColumns = {
    default: 2,
    1100: 2,
    700: 1,
    500: 1,
  };

  return (
    <>
      {posts && posts.length > 0 && (
        <>
            <Masonry
            breakpointCols={breakpointColumns}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
            >
                {posts.map((post, index) => (
                <OutputPost
                    key={index}
                    postData={post}
                    currentUser={currentUser}
                    level={null}
                    type={null}
                    view={view}
                    accessGroups={accessGroups}
                    preferences={preferences}
                    thread={null}
                    preview={null}
                    alternateUsers={alternateUsers}
                    isExpanded={false}
                />
                ))}
                {(isLoading) && ( <PostPlaceholder view='media' /> )}
                {hasNextPage && !isLoading && (
                    <div
                        key={`grid_load_more`}
                        className={`collapse show post_id p-2`}
                        onClick={() => loadMore()}
                    >
                        <div className="ratio ratio-4x3 btn btn-outline-primary rounded-0 d-flex flex-row justify-content-center align-items-center m-0 p-0">
                            Load More
                        </div>
                    </div>
                )}
            </Masonry>
        </>
      )}
    </>
  );
};

export const GridView = ({ posts, currentUser, newerPosts, showNewPosts, hasNextPage, loadMore, isLoading }) => {
    const [showModal, setShowModal] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransformEnabled, setIsTransformEnabled] = useState(false);
    const [isLoadingMedia, setIsLoadingMedia] = useState(null);
    const [lastTap, setLastTap] = useState(0); // Track last tap for double-tap detection
    const [tapCount, setTapCount] = useState(0);
    const transformWrapperRef = useRef(null);  // Ref to control TransformWrapper
    const [mediaURLs, setMediaURLs] = useState({});
    const [seenPostHashHexes, setSeenPostHashHexes] = useState([]);

    useEffect(() => {
        const determineMediaURLs = async (appendtype) => {
            if (!isLoading) {
                setIsLoadingMedia(true);
            }
    
            let existingFormattedPosts = [];
            let updatedSeenPostHashHexes = [];
            let existingMediaURLs = [];
            const newMediaURLs = {}; // Changed to an object
    
            for (const post of posts) {
                if (post.PostFound && post.PostFound.PostHashHex) {
                    const postHashHex = post.PostFound.PostHashHex;
    
                    if (!updatedSeenPostHashHexes.includes(postHashHex)) {
                        // Handle ImageURLs
                        if (post?.PostFound?.ImageURLs && post?.PostFound?.ImageURLs.length > 0) {
                            for (const url of post.PostFound.ImageURLs) {
                                newMediaURLs[url] = { post: post.PostFound, type: 'image' }; // Store type as 'image'
                            }
                        }
                        // Handle VideoURLs
                        if (post?.PostFound?.VideoURLs && post?.PostFound?.VideoURLs.length > 0) {
                            for (const url of post.PostFound.VideoURLs) {
                                newMediaURLs[url] = { post: post.PostFound, type: 'video' }; // Store type as 'video'
                            }
                        }
    
                        // Check ParentPosts
                        if (post.PostFound.ParentPosts && post.PostFound.ParentPosts.length > 0) {
                            for (const parentPost of post.PostFound.ParentPosts) {
                                if (!updatedSeenPostHashHexes.includes(parentPost.PostHashHex)) {
                                    // Handle ImageURLs for parent posts
                                    if (parentPost?.PostFound?.ImageURLs && parentPost?.PostFound?.ImageURLs.length > 0) {
                                        for (const url of parentPost.PostFound.ImageURLs) {
                                            newMediaURLs[url] = { post: parentPost.PostFound, type: 'image' }; // Store type as 'image'
                                        }
                                    }
                                    // Handle VideoURLs for parent posts
                                    if (parentPost?.PostFound?.VideoURLs && parentPost?.PostFound?.VideoURLs.length > 0) {
                                        for (const url of parentPost.PostFound.VideoURLs) {
                                            newMediaURLs[url] = { post: parentPost.PostFound, type: 'video' }; // Store type as 'video'
                                        }
                                    }
                                    updatedSeenPostHashHexes.push(parentPost.PostHashHex);
                                }
                            }
                        }
    
                        updatedSeenPostHashHexes.push(post.PostFound.PostHashHex);
                    }
                }
            }
    
            // Merge the new and existing media URLs based on append type
            let mergedMediaURLs = [];
            if (appendtype === 'prepend') {
                mergedMediaURLs = { ...newMediaURLs, ...existingMediaURLs };
            } else {
                mergedMediaURLs = { ...existingMediaURLs, ...newMediaURLs };
            }
    
            setMediaURLs(mergedMediaURLs);
            setSeenPostHashHexes(updatedSeenPostHashHexes);
            setIsLoadingMedia(false);
        };
    
        if (posts && posts.length > 0) {
            determineMediaURLs(null);
        }
    }, [posts]);
    
  
    useEffect(() => {
      if (isTransformEnabled && transformWrapperRef.current) {
        const wrapper = transformWrapperRef.current;
        wrapper.setTransform(1.5, 0, 0); // Zoom in to 1.5x scale when transform mode is enabled
  
        // Optionally, you can set up a timeout to reset zoom if needed
        setTimeout(() => {
          wrapper.setTransform(1, 0, 0); // Reset to original scale after animation (optional)
        }, 1000); // Adjust the timeout based on animation duration
      }
    }, [isTransformEnabled, transformWrapperRef]);

    // Handle opening the modal and setting the current slide index
    const handleImageClick = (index) => {
        setCurrentIndex(index + (newerPosts && newerPosts.length > 0 ? 1 : 0));
        setShowModal(true); // Open the modal
    };
  
    // Handle closing the modal
    const handleCloseModal = () => {
        setShowModal(false); // Close the modal
    };

    const enableTransform = () => {
      setIsTransformEnabled(true);
    };

    const handleInit = (ref) => {
        if(ref.centerView) {
            ref.centerView(1.25,1000); // Zoom to 1.75 scale, centered, with animation duration of 1000ms
        }
        };

    const handleTripleTap = (e) => {
        const currentTime = new Date().getTime();
        const tapGap = currentTime - lastTap;
    
        if (tapGap < 400 && tapGap > 0) { // If the gap between taps is less than 300ms
            setTapCount(tapCount + 1);
        } else {
            // Reset tapCount if time exceeds 300ms
            setTapCount(1); // Reset to 1 for the current tap
        }
    
        // If tap count reaches 3, toggle the transform state
        if (tapCount === 3) {
            const newTransformState = !isTransformEnabled;
            if (newTransformState) {
                enableTransform();
            } else {
                setIsTransformEnabled(false);
                if(transformWrapperRef.centerView) { transformWrapperRef.centerView(1,250); }
            }
            // Reset tap count after triple tap is detected
            setTapCount(0);
        }
    
        setLastTap(currentTime); // Update last tap time
    };
    
    const isVideo = (url) => {
        const imageExtensions = ['.webp','.gif','.jpg','.png'];
        return !imageExtensions.some((ext) => url.endsWith(ext));
    };
  
    return (
        <>
            

          {/* Grid Output */}
          <div className='row gridView'>
          { mediaURLs && Object.keys(mediaURLs).length > 0 && (
                Object.keys(mediaURLs).map((url, index) => {
                    const { post, type } = mediaURLs[url]; // Destructure post and type from mediaURLs

                    return (
                        <div
                            key={post.PostHashHex}
                            className={`collapse show post_id col-6 col-md-4 col-lg-2 col-xl-2 p-2`}
                            data-id={post.PostHashHex}
                            onClick={() => handleImageClick(index)}
                        >
                            {type === 'video' ? ( // Use type to determine whether to render video or image
                                <OutputVideoThumbnail post={post} url={url} />
                            ) : (
                                <img
                                    src={url}
                                    style={{ width: "100%", cursor: 'pointer' }}
                                    className="img-fluid imageFeedAspect object-fit-cover"
                                    alt="Post Image"
                                />
                            )}
                        </div>
                    );
                })
            )}

          {hasNextPage && !isLoading && !isLoadingMedia && (
            <div
                key={`grid_load_more`}
                className={`collapse show post_id col-6 col-md-4 col-lg-2 col-xl-2 p-2`}
                onClick={() => loadMore()}
            >
                <div className="ratio ratio-1x1 btn btn-outline-primary rounded-0 d-flex flex-row justify-content-center align-items-center m-0 p-0">
                    Load More
                </div>
            </div>
          )}
          {(isLoading || isLoadingMedia) && ( <PostPlaceholder view='grid' /> )}
          </div>
           
  
          {/* Modal for Slideshow */}
            <Modal show={showModal} onHide={handleCloseModal} centered className='w-100 h-100 fullscreen-carousel d-flex align-items-center justify-content-center'>
                <Modal.Body className='w-100 h-100'>
                    {/* React-Bootstrap Carousel for Slideshow */}
                    <div className="w-100 position-absolute top-0 end-0 p-4 pt-5 d-flex flex-column justify-content-center align-items-end">
                          <div className="rounded-2 bg-body bg-opacity-50 d-flex align-items-center mt-1" style={{ zIndex: 30050 }}>
                              <button
                                  className="p-2 px-3 pt-1 btn"
                                  onClick={handleCloseModal}
                                  aria-label="Close"
                                  style={{ zIndex: 10 }}
                              >
                                  <i className="bi bi-x-lg"></i>
                              </button>
                          </div>
                          <div className="rounded-2 bg-body bg-opacity-50 d-flex align-items-center mt-4" style={{ zIndex: 30050 }}>
                              {/*<span className='small text-muted' style={{ zIndex: -1 }}><i className='bi bi-info-circle me-1'></i>triple tap to enable pinch and zoom</span>*/}
                              <button
                                  className="p-2 px-3 btn"
                                  onClick={() => setIsTransformEnabled(!isTransformEnabled)}
                                  aria-label="Enable Zoom"
                                  style={{ zIndex: 10 }}
                              >
                                  <i className={`bi ${isTransformEnabled ? `bi-zoom-out text-info` : `bi-zoom-in text-muted` }`}></i>
                              </button>
                          </div>
                      </div>
                    <Carousel activeIndex={currentIndex} onSelect={(selectedIndex) => setCurrentIndex(selectedIndex)} interval={null} indicators={false}>
                        {newerPosts && newerPosts.length > 0 && (
                            <Carousel.Item key='loadNewer'>
                                <div className='h-100 d-flex justify-content-center align-items-center'>
                                    <div className="rounded-2 bg-body bg-opacity-50 d-flex justify-content-center align-items-center p-2">
                                        <span className="text-body text-decoration-none" onClick={showNewPosts}>load newer results</span>
                                    </div>
                                </div>
                            </Carousel.Item>
                        )}
                        {Object.keys(mediaURLs).map((url, index) => {
                            const { post, type } = mediaURLs[url];
                            return (
                                <Carousel.Item key={post.PostHashHex} onClick={handleTripleTap}>
                                    <div className='h-100 d-flex align-items-center'>
                                        <div className="w-100 position-absolute top-0 end-0 p-4 pt-5 d-flex flex-row flex-nowrap justify-content-start align-items-center" style={{ zIndex: 10 }}>
                                            <div className="rounded-2 bg-body bg-opacity-50 d-flex flex-nowrap align-items-center p-2">
                                                <Avatar suppliedProfile={post.ProfileEntryResponse} type="avatar" publicKey={post.PosterPublicKeyBase58Check} />
                                                <Avatar suppliedProfile={post.ProfileEntryResponse} type="username" publicKey={post.PosterPublicKeyBase58Check} />
                                            </div>
                                        </div>
                                        {type === 'video' ? (
                                            <video
                                                className="carousel-transform active d-block object-fit-scale h-100 w-100"
                                                style={{ cursor: 'pointer' }}
                                                controls
                                                alt="Post Video"
                                            >
                                                <source src={url} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        ) : (
                                            <>
                                            {isTransformEnabled ? (
                                                <TransformWrapper
                                                    ref={transformWrapperRef}
                                                    initialScale={1}  // Set the initial zoom scale here (e.g., 1.5 for 150% zoom)
                                                    centerOnInit={true} // Optionally center the image on zoom
                                                    transitionTime={1000}
                                                    onInit={handleInit} 
                                                    // animation={{ animationDuration: 1000 }}
                                                >
                                                    <TransformComponent>
                                                        <img
                                                            src={url}
                                                            style={{ width: "100%", cursor: 'pointer' }}
                                                            className="carousel-transform active d-block w-100 object-fit-scale"
                                                            alt="Post Image"
                                                        />
                                                    </TransformComponent>
                                                </TransformWrapper>
                                            ) : (
                                                <img
                                                    src={url}
                                                    style={{ width: "100%", cursor: 'pointer' }}
                                                    className="carousel-transform active d-block w-100 object-fit-scale"
                                                    alt="Post Image"
                                                />
                                            )}
                                            </>
                                        )}
                                        <div className="position-absolute bottom-0 start-0 p-4 pb-5 w-100 d-flex flex-row flex-nowrap justify-content-between align-items-end" style={{ zIndex: 10 }}>
                                            <div className="rounded-2 bg-body bg-opacity-50 d-flex flex-column align-items-center p-2">
                                                <span className='mb-4'><SendDiamondsButton PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} ReceiverPublicKeyBase58Check={post.PosterPublicKeyBase58Check} DiamondLevel={1} post={post} currentUser={currentUser} /></span>
                                                <span className=''><SendReactionButton PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} ReceiverPublicKeyBase58Check={post.PosterPublicKeyBase58Check} post={post} currentUser={currentUser} /></span>
                                            </div>
                                            <div className="rounded-2 bg-body bg-opacity-50 d-flex flex-column align-items-center p-2">
                                                <Link to={`/posts/${post.PostHashHex}`} className="text-body text-decoration-none">view post</Link>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            );
                        })}
                        {hasNextPage && (
                            <Carousel.Item key='loadmore'>
                                <div className='h-100 d-flex justify-content-center align-items-center'>
                                    <div className="rounded-2 bg-body bg-opacity-50 d-flex justify-content-center align-items-center p-2">
                                        <span className="btn btn-lg btn-primary" onClick={loadMore}>load more results</span>
                                    </div>
                                </div>
                            </Carousel.Item>
                        )}
                    </Carousel>
                </Modal.Body>
            </Modal>
        </>
    );
};

export const CarouselFeedOutput = ({ posts, view, currentUser, accessGroups, preferences, alternateUsers, newerPosts, showNewPosts, hasNextPage, loadMore, isLoadingPosts }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Handle opening the modal and setting the current slide index
  const handleImageClick = (index) => {
    setCurrentIndex(index + (newerPosts && newerPosts.length > 0 ? 1 : 0));
    setShowModal(true); // Open the modal
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

  // Handle slide change
  const handleSlideChange = (direction) => {
    const totalSlides = posts.length;
    setCurrentIndex((prevIndex) => {
      if (direction === "next") {
        return (prevIndex + 1) % totalSlides;
      } else {
        return (prevIndex - 1 + totalSlides) % totalSlides;
      }
    });
  };

  return (
    <Carousel className='feed-carousel' activeIndex={currentIndex} onSelect={(selectedIndex) => setCurrentIndex(selectedIndex)} interval={null} indicators={false}>
        {newerPosts && newerPosts.length > 0 && (
            <Carousel.Item key='loadNewer'>
                <div className='h-100 d-flex justify-content-center align-items-center'>
                    <div className="rounded-2 bg-body bg-opacity-50 d-flex justify-content-center align-items-center p-2">
                        <span className="text-body text-decoration-none" onClick={showNewPosts}>load newer results</span>
                    </div>
                </div>
            </Carousel.Item>
        )}
        {posts.map((post, index) =>(
            <Carousel.Item key={index}>
                <div className='slide-content'>
                    <OutputPost postData={post} currentUser={currentUser} level={null} type={null} view={view} accessGroups={accessGroups} preferences={preferences} thread={null} preview={null} alternateUsers={alternateUsers} isExpanded={false} />
                </div>
            </Carousel.Item>
        ))}
        {hasNextPage && (
            <Carousel.Item key='loadmore'>
                <div className='h-100 d-flex justify-content-center align-items-center'>
                    <div className="rounded-2 bg-body bg-opacity-50 d-flex justify-content-center align-items-center p-2">
                        <span className="btn btn-lg btn-primary" onClick={loadMore}>load more results</span>
                    </div>
                </div>
            </Carousel.Item>
        )}
    </Carousel>
  );
};

const isSyndicated = (post) => {
    const extraDataFields = ['RSS_authors', 'RSS_platform', 'RSS_url'];
    if (post && post.PostFound && post.PostFound.PostExtraData) {
      const isSyndicatedPost = extraDataFields.some(field => 
        Object.prototype.hasOwnProperty.call(post.PostFound.PostExtraData, field)
      );
      return isSyndicatedPost;
    }
    return false;
  };
  

export const OutputPost = ({ postData, currentUser, level, type, view, accessGroups, preferences, thread, preview, alternateUsers, isExpanded }) => {
    const [moderationOutcome, setModerationOutcome] = useState(false);
    const [moderationChecked, setModerationChecked] = useState(false);
    const { fetchModerationFlags, postsToRefresh, setPostRefreshed } = useContext(AppDataContext);

    const [postType, setPostType] = useState(type || 'post');
    const [rawPost, setRawPost] = useState(postData);
    const [finalPost, setFinalPost] = useState(null);
    const [unMasked, setUnMasked] = useState(false);
    const [hidden, setHidden] = useState(null);
    const [threaded, setThreaded] = useState(thread);

    //if(preview) { console.log("[postLayouts.js] <OutputPost (ref compose.js) preview, post:",preview, finalPost); }

    useEffect(() => {
        setRawPost(postData);
    }, [postData]);

    useEffect(() => {
        const refreshPostIfNeeded = async () => {
            if (postsToRefresh && postsToRefresh.includes(postData.PostHashHex)) {
                try {
                    const payload = {
                        PostHashHex: postData.PostHashHex,
                        ReaderPublicKeyBase58Check: currentUser.PublicKeyBase58Check || null, 
                    }
                    const refreshedPost = await getSinglePost(payload);
                    setRawPost(refreshedPost);
                    setPostRefreshed(postData.PostHashHex);
                } catch (error) {
                    console.error('Error refreshing post:', error);
                }
            }
        };

        if(!preview && postsToRefresh && postsToRefresh.includes(postData.PostHashHex)) {
            refreshPostIfNeeded();
        }
    }, [postsToRefresh]);

    useEffect(() => {
        if (rawPost) {
            const finalTmp = rawPost?.PostFound || rawPost;
            if(finalTmp) {
                if(view === 'media') {
                    setPostType('media');
                } else if(finalTmp.PostExtraData && (finalTmp.PostExtraData.BlogTitleSlug || finalTmp.PostExtraData.CoverImage)) {
                    if(isExpanded) {
                        setPostType('blog_full');
                    } else {
                        setPostType('blog');
                    }
                } else if (finalTmp.ParentStakeID && finalTmp.ParentStakeID !== '' && finalTmp.ParentPosts) {
                    setPostType('comment');
                }
            }
            if (finalTmp?.ParentPosts?.length > 0) {
                const tmp = remapCommentTree(finalTmp);
                setThreaded(true);
                setFinalPost(tmp);  // Update the state with remapped comments
                //console.log("[postLayouts.js] postData should be threaded:",postData,tmp)
            } else {
                setFinalPost(finalTmp);
            }
            //if(preview ) { console.log("[postLayouts.js] <OutputPost (ref compose.js) - set final as ",finalTmp); }
        }
    }, [rawPost]);

    // Only fetch moderation flags once finalPost has been set
    useEffect(() => {
        if(preview) { 
            setModerationChecked(true);
            setModerationOutcome(true);
            //console.log("[postLayouts.js] <PreviewPost (ref compose.js) have final post...",finalPost); 
        }
        if (finalPost && !moderationChecked) {
            const postOrPublicKey = finalPost;
            fetchModerationFlags(postOrPublicKey)
            .then((outcome) => {
                setModerationOutcome(outcome);
                setModerationChecked(true); // Mark moderation check as complete
                //console.log("Moderation - post - outcome:",finalPost,outcome);
            });
        }
    }, [finalPost, currentUser, moderationChecked, fetchModerationFlags]);
    

    const handleUnMask = () => {
        setUnMasked(true); // Apply the unMask class
      };

    //if(preview && finalPost) { console.log("[postLayouts.js] <OutputPost (ref compose.js) before moderation...",finalPost); }

    /*
    if(!moderationChecked && !preview && (currentUser && !currentUser.PublicKeysBase58CheckFollowedByUser.includes(finalPost?.PosterPublicKeyBase58Check))) { 
        //console.log("[postLayouts.js] still checking moderation, placeholder for ",postData, postType);
        return (<PostPlaceholder feedType={postType} view={view} single={true} />); 
    }
    
    // If moderation outcome is blocked (false), hide the post

    if (!preview && (moderationOutcome === false && (currentUser && !currentUser.PublicKeysBase58CheckFollowedByUser.includes(finalPost?.PosterPublicKeyBase58Check)))) {
        return <div className="moderationHiddenContent pb-5 text-center text-muted small" id={`post_${finalPost.PostHashHex}`}><i className="bi bi-ban text-danger me-2"></i>a post was blocked due to moderation settings</div>; // You can customize this message
    }
    */

    if(!finalPost) { 
        //console.log("[postLayouts.js] NO OUTPUT POST - ref compose.js"); 
        return; 
    }

    //if(preview && finalPost) { console.log("[postLayouts.js] Output post (ref compose.js) - view,type,isExpanded,final",view,postType,isExpanded,finalPost); }
    //  className={`${moderationOutcome === null ? `moderationMaskContent` : ``} ${unMasked ? 'unMask' : ''}`} >
    return (
        <div>
            {/*moderationOutcome === null && !unMasked && (
                <div className="moderationOptions p-3 position-absolute start-0 pb-5 w-100 h-100 text-center d-flex flex-column align-items-center justify-content-center small">
                    <div className="rounded bg-body text-muted p-2 mb-3">
                        masked due to personal moderation preferences
                    </div>
                    <span className="btn btn-sm bg-body text-muted p-2 mb-3" onClick={!unMasked ? handleUnMask : undefined} >
                        <i className="bi bi-eye-slash-fill me-1"></i>unmask
                    </span>
                </div>
            )*/}
            {view === 'media' ? (
                <Media 
                    post={finalPost} 
                    currentUser={currentUser} 
                    level={level} 
                    type={type} 
                    view={view} 
                    accessGroups={accessGroups} 
                    preferences={preferences} 
                    thread={threaded} 
                    preview={preview}
                    alternateUsers={alternateUsers} 
                    isExpanded={isExpanded} 
                    hidden={hidden} 
                />
            ) : finalPost && finalPost.PostExtraData && isSyndicated(postData) ? (
                <Syndicated 
                    post={finalPost} 
                    currentUser={currentUser} 
                    level={level} 
                    type={type} 
                    view={view} 
                    accessGroups={accessGroups} 
                    preferences={preferences} 
                    thread={threaded} 
                    preview={preview}
                    alternateUsers={alternateUsers} 
                    isExpanded={isExpanded} 
                    hidden={hidden}  
                />
            ) : finalPost && finalPost?.RepostedPostEntryResponse && !finalPost.Body ? (
                <Repost 
                    post={finalPost} 
                    currentUser={currentUser} 
                    level={level} 
                    type={type} 
                    view={view} 
                    accessGroups={accessGroups} 
                    preferences={preferences} 
                    thread={threaded} 
                    preview={preview}
                    alternateUsers={alternateUsers} 
                    isExpanded={isExpanded} 
                    hidden={hidden} 
                />
            ) : finalPost && finalPost.ParentStakeID && finalPost.ParentStakeID !== '' && finalPost.ParentPosts ? (
                postData !== finalPost ? (
                    <Post 
                        post={finalPost} 
                        currentUser={currentUser} 
                        level={level} 
                        type={type} 
                        view={view} 
                        accessGroups={accessGroups} 
                        preferences={preferences} 
                        thread={threaded} 
                        preview={preview}
                        alternateUsers={alternateUsers} 
                        isExpanded={isExpanded} 
                        hidden={hidden}
                    />
                ) : finalPost?.ParentPosts?.length > 0 ? (
                    <Post 
                        post={finalPost} 
                        currentUser={currentUser} 
                        level={level} 
                        type={type} 
                        view={view} 
                        accessGroups={accessGroups} 
                        preferences={preferences} 
                        thread={threaded} 
                        preview={preview}
                        alternateUsers={alternateUsers} 
                        isExpanded={isExpanded} 
                        hidden={hidden} 
                    />
                ) : (
                    <Reply 
                        post={finalPost} 
                        currentUser={currentUser} 
                        level={level} 
                        type={type} 
                        view={view} 
                        accessGroups={accessGroups} 
                        preferences={preferences} 
                        thread={threaded} 
                        preview={preview}
                        alternateUsers={alternateUsers} 
                        isExpanded={isExpanded} 
                        hidden={hidden} 
                    />
                )
            ) : finalPost && ((finalPost.PostExtraData && (finalPost.PostExtraData.BlogTitleSlug || finalPost.PostExtraData.BlogDeltaRtfFormat)) || postType === "blog") ? (
                !isExpanded ? (
                    <BlogPreview 
                        post={finalPost} 
                        currentUser={currentUser} 
                        level={level} 
                        type={type} 
                        view={view} 
                        accessGroups={accessGroups} 
                        preferences={preferences} 
                        thread={threaded} 
                        preview={preview}
                        alternateUsers={alternateUsers} 
                        isExpanded={isExpanded} 
                        hidden={hidden} 
                    />
                ) : (
                    <Blog 
                        post={finalPost} 
                        currentUser={currentUser} 
                        level={level} 
                        type={type} 
                        view={view} 
                        accessGroups={accessGroups} 
                        preferences={preferences} 
                        thread={threaded} 
                        preview={preview}
                        alternateUsers={alternateUsers} 
                        isExpanded={isExpanded} 
                        hidden={hidden} 
                    />
                )
            ) : finalPost?.PostExtraData?.Event ? (
                <Event 
                    post={finalPost} 
                    currentUser={currentUser} 
                    level={level} 
                    type={type} 
                    view={view} 
                    accessGroups={accessGroups} 
                    preferences={preferences} 
                    thread={threaded} 
                    preview={preview}
                    alternateUsers={alternateUsers} 
                    isExpanded={isExpanded} 
                    hidden={hidden} 
                />
            ) : (
                <Post 
                    post={finalPost} 
                    currentUser={currentUser} 
                    level={level} 
                    type={type} 
                    view={view} 
                    accessGroups={accessGroups} 
                    preferences={preferences} 
                    thread={threaded} 
                    preview={preview}
                    alternateUsers={alternateUsers} 
                    isExpanded={isExpanded} 
                    hidden={hidden} 
                />
            )}
        </div>
    );
};

export const BlogPreview = ({ post, currentUser, level, type, view, accessGroups, preferences, thread, preview, alternateUsers, isExpanded, hidden }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [blocked, setBlocked] = useState(false);
    if(blocked) { return; }
    if(isLoading) {
        return <PostPlaceholder type={type} view={view} />
    }

    const formattedTimestamp = new Date(post.TimestampNanos/1000000).toLocaleString();
    const timeSince = friendlyFormatDate(post.TimestampNanos/1000000);

    if (post.PostExtraData.CoverImage) {
        var blogImage = <img src={post.PostExtraData.CoverImage} className="object-fit-cover card-effect" alt={`Cover image for ${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username: `Author`}'s article, '${post.PostExtraData.Title}'`} />;
    } else if (post.ProfileEntryResponse.ExtraData && post.ProfileEntryResponse.ExtraData.FeaturedImageURL) {
        var blogImage = <img src={post.ProfileEntryResponse.ExtraData.FeaturedImageURL} className="object-fit-cover card-effect" alt={`Cover image for ${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username: `Author`}'s article, '${post.PostExtraData.Title}'`} />;
    } else { var blogImage = ''; }
    
    return (
        <div className="pb-5" id={`post_${post.PostHashHex}`}>
            <Link to={`/posts/${post.PostHashHex}`} id={`post_${post.PostHashHex}`} className={`postContentContainer collapse show post_id col-12`} data-id={post.PostHashHex}>
                <div data-posthashhex={post.PostHashHex} className="card m-0 border-1 lh-sm card-effect">
                    <div className="ratio ratio-16x9">{blogImage}</div>
                    <div className="position-absolute bottom-10 bottom-0 p-2 w-100 d-flex flex-row flex-nowrap justify-content-between align-items-center" style={{ zIndex: 10 }}>
                        <div className="rounded-2 bg-body bg-opacity-75 p-2 w-100">
                            <h5 className='fs-5 d-inline'>{post.PostExtraData.Title}</h5>
                            <abbr title={formattedTimestamp} className='ms-2 small'>{timeSince}</abbr>
                            <div className='d-flex flex-row flex-nowrap align-items-center justify-content-between'>
                                <div className="d-flex align-items-center p-2 text-body">
                                    <SendDiamondsButton PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} ReceiverPublicKeyBase58Check={post.PosterPublicKeyBase58Check} DiamondLevel={1} post={post} currentUser={currentUser} />
                                    <SendReactionButton PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} ReceiverPublicKeyBase58Check={post.PosterPublicKeyBase58Check} post={post} currentUser={currentUser} />
                                </div>
                                <div className="d-flex align-items-center p-2">
                                    <Avatar suppliedProfile={post.ProfileEntryResponse} type="avatar" publicKey={post.PosterPublicKeyBase58Check} />
                                    &nbsp;<Avatar suppliedProfile={post.ProfileEntryResponse} type="username" publicKey={post.PosterPublicKeyBase58Check} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </Link>
        </div>
    );
}

export const Blog = ({ post, currentUser, level, type, view, accessGroups, preferences, thread, preview, alternateUsers, isExpanded, hidden }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [expandPost, setExpandPost] = useState(false);
    const [blocked, setBlocked] = useState(false);
    if(blocked) { return; }
    const formattedTimestamp = new Date(post.TimestampNanos/1000000).toLocaleString();
    const timeSince = friendlyFormatDate(post.TimestampNanos/1000000);
    //if(preview && post) { console.log('[postLayouts.js] <Blog> HERE ref compose.js',post); }
    if(isLoading) {
        return <PostPlaceholder type={type} view={view} />
    }    
    return (
        <div className="pb-5 px-0" id={`post_${post.PostHashHex}`}>
            <div className="">
                {preview && (
                    <>
                        {post?.PostExtraData?.CoverImage ? (
                            <div className="ratio ratio-2x1"><img className="object-fit-cover img-fluid ratio-1x2" src={post?.PostExtraData?.CoverImage}/></div>
                        ) : (
                            <div className="ratio ratio-2x1 bg-secondary bg-gradient"></div>
                        )}
                    </>
                    )}
                <h1 className='fs-1'>{post.PostExtraData.Title}</h1>
                <div className="fs-6 d-flex flex-row flex-wrap align-items-center">
                    <span className="fw-light me-2">By</span>
                    <Avatar  suppliedProfile={post.ProfileEntryResponse} type="avatar" publicKey={post.PosterPublicKeyBase58Check}/><Avatar suppliedProfile={post.ProfileEntryResponse} type="username" publicKey={post.PosterPublicKeyBase58Check}/>
                    <span className='small text-muted fw-light'><abbr title={formattedTimestamp}>{timeSince}</abbr></span>
                </div>
            </div>
            <div className="position-relative pt-4">
                {!preview && (<PostSettings post={post} currentUser={currentUser} preferences={preferences} />)}
                <FormattedBlog post={post} />
            </div>
            {!preview && ( <EngagementBar post={post} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} alternateUsers={alternateUsers} isExpanded={isExpanded} /> )}
            {!preview && ( <PostComments post={post} currentUser={currentUser} level={(level+1)} thread={thread}/> )}
        </div>
    );
}

export const Post = ({ post, currentUser, level, type, view, accessGroups, preferences, thread, preview, alternateUsers, isExpanded, hidden }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [expandPost, setExpandPost] = useState(false);
    const [blocked, setBlocked] = useState(false);
    if(blocked) { return; }
    //if(preview && post) { console.log('[postLayouts.js] HERE ref compose.js',post); }


    if(isLoading) {
        return <PostPlaceholder type={type} view={view} />
    }  
    let postType = 'posted';
    let tagged;
    //Check for tags
    if (post?.Body?.includes(`@${currentUser.Username}`)) {
        tagged = 'tagged you in a ';
    }

    if(post?.RepostedPostEntryResponse && post?.Body) {
        postType = tagged ? 'quote' : <>quoted <Avatar publicKey={post?.RepostedPostEntryResponse.PosterPublicKeyBase58Check} suppliedProfile={post?.RepostedPostEntryResponse.ProfileEntryResponse} /></>;
    } else if (post?.ParentStakeID) {
        postType = 'replied';
    } else {
        postType = tagged ? 'post' : 'posted';
    }

    return (
        <div className={`pb-5${post?.IsHidden ? ` opacity-50` : ``}`} id={`post_${post?.PostHashHex}`}>
            <PostHeader postType={postType} post={post} currentUser={currentUser} level={level} type={type} view={view} accessGroups={accessGroups} preferences={preferences} thread={thread} preview={preview} alternateUsers={alternateUsers} isExpanded={isExpanded} expandPost={expandPost} setExpandPost={setExpandPost} blocked={blocked} setBlocked={setBlocked} />
            {expandPost && (
                <div className="postContentWrapper">
                    {post?.RepostedPostEntryResponse && (
                        <div className={`d-flex flex-nowrap align-items-center pb-3`} data-posthashhex={post?.PostHashHex}>
                            <i className="fs-1 mx-2 bi bi-quote"></i>
                            <Quote post={post?.RepostedPostEntryResponse} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} thread={thread} accessGroups={accessGroups} alternateUsers={alternateUsers} isExpanded={isExpanded} hidden={hidden} />
                        </div>
                    )}
                    <div className="postContentContainer position-relative bg-body card card-primary card-effect">
                        <BodyOutput post={post} maxLength={164} comment={null} description={null} padding={3} hidden={hidden} background={null} preview={preview} expanded={isExpanded} />
                        {post?.PostExtraData?.PollOptions && ( <PostPollComponent post={post} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} preview={preview} alternateUsers={alternateUsers} isExpanded={isExpanded} />)}
                        {!post?.PostExtraData?.bodyMarkdown && ( <PostAttachments post={post} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} alternateUsers={alternateUsers} isExpanded={isExpanded} />)}
                    </div>
                    {!preview && ( <EngagementBar post={post} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} alternateUsers={alternateUsers} isExpanded={isExpanded} /> )}
                    {!preview && ( <PostComments post={post} currentUser={currentUser} level={(level+1)} thread={thread}/> )}
                </div>
            )}
        </div>
    );
}

export const Event = ({ post, currentUser, level, type, view, accessGroups, preferences, thread, preview, alternateUsers, isExpanded, hidden }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [expandPost, setExpandPost] = useState(false);
    const [blocked, setBlocked] = useState(false);
    if(blocked) { return; }
    const userLocale = navigator.language || navigator.userLanguage;
    if(isLoading) {
        return <PostPlaceholder type={type} view={view} />
    }  
    let postType = 'posted';
    let tagged;
    //Check for tags
    if (post?.Body?.includes(`@${currentUser.Username}`)) {
        tagged = 'tagged you in a ';
    }

    if(post?.RepostedPostEntryResponse && post?.Body) {
        postType = tagged ? 'quote' : <>quoted <Avatar publicKey={post?.RepostedPostEntryResponse.PosterPublicKeyBase58Check} suppliedProfile={post?.RepostedPostEntryResponse.ProfileEntryResponse} /></>;
    } else if (post?.ParentStakeID) {
        postType = 'replied';
    } else {
        postType = tagged ? 'post' : 'posted';
    }

    //console.log("[postLayouts.jsx] <Event output ref compose.js",post);
    return (
        <div className={`pb-5${post?.IsHidden ? ` opacity-50` : ``}`} id={`post_${post?.PostHashHex}`}>
            <PostHeader postType={postType} post={post} currentUser={currentUser} level={level} type={type} view={view} accessGroups={accessGroups} preferences={preferences} thread={thread} preview={preview} alternateUsers={alternateUsers} isExpanded={isExpanded} expandPost={expandPost} setExpandPost={setExpandPost} blocked={blocked} setBlocked={setBlocked}  />
            {expandPost && (
                <>
                {post?.RepostedPostEntryResponse && (
                    <div className={`d-flex flex-nowrap align-items-center pb-3`} data-posthashhex={post?.PostHashHex}>
                        <i className="fs-1 mx-2 bi bi-quote"></i>
                        <Quote post={post?.RepostedPostEntryResponse} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} thread={thread} accessGroups={accessGroups} alternateUsers={alternateUsers} isExpanded={isExpanded} hidden={hidden} />
                    </div>
                )}
                <div className="postContentContainer position-relative bg-body card-primary card-effect">
                    <div className="position-relative w-100 ratio ratio-1x2">
                        <div className="position-absolute ratio ratio-1x2">
                            {post?.PostExtraData?.CoverImage ? (
                                <img 
                                    src={post.PostExtraData.CoverImage} 
                                    className="w-100 h-100 ratio-1x2 object-fit-cover" 
                                    alt="Event Cover" 
                                />
                            ) : (
                                <div className="object-fit-cover w-100 h-100 bg-secondary bg-gradient">
                                </div>
                            )}
                        </div>
                        <div className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-end">
                            <div className="p-3 bg-body bg-opacity-50 w-100 d-flex flex-column">
                                {post?.PostExtraData?.PollOptions && ( <RSVPComponent post={post} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} preview={preview} alternateUsers={alternateUsers} isExpanded={isExpanded} />)}
                                <div className="d-flex flex-row align-items-end">
                                    <div className="flex-fill">
                                    {post?.PostExtraData?.EventStart && (
                                        <>
                                            <div className="flex-fill">
                                                <i className="bi bi-calendar3 text-muted me-2"></i>{new Date(post.PostExtraData.EventStart).toLocaleString(userLocale, { 
                                                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone 
                                                })}
                                            </div>
                                        </>
                                    )}
                                    {post?.PostExtraData?.EventEnd && (
                                        <>
                                            <div className="flex-fill">
                                                <i className="bi bi-calendar3 text-muted me-2"></i>{new Date(post.PostExtraData.EventEnd).toLocaleString(userLocale, { 
                                                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone 
                                                })}
                                            </div>
                                        </>
                                    )}
                                    {post?.PostExtraData?.EventLocation && (
                                        <>
                                            <div className="flex-fill">
                                            <i className="bi bi-geo-fill text-muted me-2"></i>{post.PostExtraData.EventLocation}
                                            </div>
                                        </>
                                    )}
                                    </div>
                                    <AddToCalendarButton post={post} />
                                </div>
                            </div>
                        </div>
                    </div>
            
                <BodyOutput post={post} maxLength={164} comment={null} description={null} padding={3} hidden={hidden} background={null} preview={preview} expanded={isExpanded} />
                </div>
                {!post?.PostExtraData?.bodyMarkdown && ( <PostAttachments post={post} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} alternateUsers={alternateUsers} isExpanded={isExpanded} />)}
                {!preview && ( <PostComments post={post} currentUser={currentUser} level={(level+1)} thread={thread}/> )}
                </>
            )}
        </div>
    );
}

export const Quote = ({ post, currentUser, level, type, view, accessGroups, preferences, thread, preview, alternateUsers, isExpanded, hidden }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [expandPost, setExpandPost] = useState(false);
    const [blocked, setBlocked] = useState(false);
    if(blocked) { return; }

    if(isLoading) {
        return <PostPlaceholder type={type} view={view} />
    }
    let postType;
    let tagged;
    if (post.Body.includes(`@${currentUser.Username}`)) {
        tagged = 'tagged you in a ';
    }
    if(post?.RepostedPostEntryResponse && post?.Body) {
        postType = tagged ? 'quote' : <>quoted <Avatar publicKey={post?.RepostedPostEntryResponse.PosterPublicKeyBase58Check} suppliedProfile={post?.RepostedPostEntryResponse.ProfileEntryResponse} /></>;
    } else if (post?.ParentStakeID) {
        postType = 'replied';
    } else {
        postType = tagged ? 'post' : 'posted';
    }

    return (
        <div className="p-0 m-0 w-100" id={`post_${post.PostHashHex}`}>
            <PostHeader postType={postType} post={post} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} alternateUsers={alternateUsers} isExpanded={isExpanded} expandPost={expandPost} setExpandPost={setExpandPost} blocked={blocked} setBlocked={setBlocked}  />
            {expandPost && (
                <>
                    <div className="postContentContainer card-secondary card-effect">
                        <BodyOutput post={post} maxLength={164} comment={null} description={null} padding={3} hidden={hidden} background={null} preview={preview} expanded={isExpanded} />
                        <PostAttachments post={post} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} alternateUsers={alternateUsers} isExpanded={isExpanded} />
                    </div>
                    <EngagementBar post={post} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} alternateUsers={alternateUsers} isExpanded={isExpanded} />
                    {!preview && ( <PostComments post={post} currentUser={currentUser} level={(level+1)} thread={thread}/> )}
                </>
            )}
        </div>
    );
}

export const Repost = ({ post, currentUser, level, type, view, accessGroups, preferences, thread, preview, alternateUsers, isExpanded, hidden }) => {
    const [isLoading, setIsLoading] = useState(false);

    if(isLoading) {
        return <PostPlaceholder type={type} view={view} />
    }
    return (
        <>
            <div className="d-flex flex-row align-items-center" id={`post_${post.PostHashHex}`}><i className="bi bi-repeat me-1"></i> <Avatar suppliedProfile={post.ProfileEntryResponse} type="username" publicKey={post.PosterPublicKeyBase58Check}/> <span className='ms-1 fw-light'>reposted</span></div>
            <OutputPost postData={post.RepostedPostEntryResponse} currentUser={currentUser} level={0} type={type} view={view} accessGroups={accessGroups} preferences={preferences} thread={thread} preview={preview} alternateUsers={alternateUsers} isExpanded={isExpanded} />
        </>
    );
}

export const Reply = ({ post, currentUser, level, type, view, accessGroups, preferences, thread, preview, alternateUsers, isExpanded, hidden }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [expandPost, setExpandPost] = useState(false);
    const [blocked, setBlocked] = useState(false);
    if(blocked) { return; }
    //console.log("<Reply>",post);

    if(!post) { return; }

    if(isLoading) {
        return <PostPlaceholder type={type} view={view} />
    }

    let postType;
    let tagged;

    if (post.Body && currentUser.ProfileEntryResponse.Username && post.Body.toLowerCase().includes(`@${currentUser.ProfileEntryResponse.Username.toLowerCase()}`)) {
        //console.log("TAGGED post",post);
        tagged = <span className="fw-bold text-body">tagged you </span>;
    }
    
    if(post?.RepostedPostEntryResponse && post?.Body) {
        postType = tagged ? <>{tagged} in a quote</> : <>quoted <Avatar publicKey={post?.RepostedPostEntryResponse.PosterPublicKeyBase58Check} suppliedProfile={post?.RepostedPostEntryResponse.ProfileEntryResponse} /></>;
    } else if (post?.ParentStakeID) {
        postType = tagged ? <>{tagged} in a reply</> : 'replied';
    } else {
        postType = tagged ? <>{tagged} in a post</> : 'posted';
    }
    
    let parentPost = null;
    if(post?.ParentPosts?.length > 0) {
        parentPost = post.ParentPosts[(post.ParentPosts.length-1)];
    }
    const formattedTimestamp = new Date(post.TimestampNanos/1000000).toLocaleString();
    const timeSince = friendlyFormatDate(post.TimestampNanos/1000000);
    return (
        <>
            <div className={`p-0 m-0 repliesContainer${(post.CommentCount > 0) ? ` hasComments` : ``}`} id={`post_${post.PostHashHex}`} >
                <div className='repliesWrapper w-100 d-flex justify-content-between align-items-top flex-nowrap p-0 m-0'>
                    <div className="commentGutter">
                        <Avatar type="avatar" size={1} publicKey={post.PosterPublicKeyBase58Check}/>
                    </div>
                    <div className={`flex-fill ms-1`}>
                        <div>
                            <div className='replyContainer bg-body-tertiary Cd-block card-tertiary rounded-3'>
                                <div className="d-flex flex-row flex-nowrap">
                                <Avatar suppliedProfile={post.ProfileEntryResponse} type="username" publicKey={post.PosterPublicKeyBase58Check}/>
                                <span className='small'>
                                    <span className='text-muted'>{postType}</span>&nbsp;
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip><span className='text-end px-2'>{formattedTimestamp}</span></Tooltip>}
                                        >
                                        <span>{timeSince}</span>
                                    </OverlayTrigger>
                                </span>
                                </div>
                                <BodyOutput post={post} maxLength={164} comment={true} description={null} padding={1} hidden={hidden} background={null} preview={preview} expanded={isExpanded} />
                                <PostAttachments post={post} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} alternateUsers={alternateUsers} isExpanded={isExpanded} />
                            </div>
                            <EngagementBar post={post} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} alternateUsers={alternateUsers} isExpanded={isExpanded} />
                        </div>
                        {!preview && ( <PostComments post={post} currentUser={currentUser} level={(level+1)} thread={thread} /> )}
                    </div>
                </div>
            </div>
        </>
    );
}

export const Media = ({ post, currentUser, level, type, view, accessGroups, preferences, thread, preview, alternateUsers, isExpanded, hidden }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [expandPost, setExpandPost] = useState(false);
    const [blocked, setBlocked] = useState(false);
    if(blocked) { return; }
    if(isLoading) {
        return <PostPlaceholder type={type} view={view} />
    }
    if(!post.ImageURLs && !post.VideoURLs) {
        return; 
    }
    return (
        <div className="w-100 col-12 col-md-6 mediaView">
            <PostHeader postType='post' post={post} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} alternateUsers={alternateUsers} isExpanded={isExpanded} expandPost={expandPost} setExpandPost={setExpandPost} blocked={blocked} setBlocked={setBlocked}  />
            {expandPost && (
                <>
                <div className="postContentContainer card-primary card-effect">
                    <PostAttachments post={post} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} alternateUsers={alternateUsers} isExpanded={isExpanded} />
                </div>
                <EngagementBar post={post} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} alternateUsers={alternateUsers} isExpanded={isExpanded} />
                {!preview && ( <PostComments post={post} currentUser={currentUser} level={(level+1)} thread={thread}/> )}
                </>
            )}
        </div>
    );
}

export const Syndicated = ({ post, currentUser, level, type, view, accessGroups, preferences, thread, preview, alternateUsers, isExpanded, hidden }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [expandPost, setExpandPost] = useState(false);
    const [blocked, setBlocked] = useState(false);
    if(blocked) { return; }
    if(isLoading) {
        return <PostPlaceholder type={type} view={view} />
    }  
    return (
        <div className="pb-5">
            <PostHeader postType='post' post={post} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} alternateUsers={alternateUsers} isExpanded={isExpanded} expandPost={expandPost} expandPost={expandPost} setExpandPost={setExpandPost} blocked={blocked} setBlocked={setBlocked} />
            {expandPost && (
                <>
                    <div className="postContentContainer bg-transparent card-primary card-effect">
                        <TweetEmbed url={post.PostExtraData.EmbedVideoURL} preferences={preferences} />
                    </div>
                    <EngagementBar post={post} level={level} type={type} view={view} currentUser={currentUser} preferences={preferences} accessGroups={accessGroups} alternateUsers={alternateUsers} isExpanded={isExpanded} />
                    {!preview && ( <PostComments post={post} currentUser={currentUser} level={(level+1)} thread={thread}/> )}
                </>
            )}
        </div>
    );
}
/*************************************************************************************************************
 * Common Post Components
 */
export const PostHeader = ({ postType = null, post, currentUser, level, type, view, accessGroups, preferences, thread, preview, alternateUsers, isExpanded, expandPost, setExpandPost, blocked, setBlocked }) => {
    const { postModerationFlags, userModerationFlags } = useContext(AppDataContext);
    const [showFlags, setShowFlags] = useState(false);
    let postIcon;

    //console.log("postModerationFlags, userModerationFlags, preferences", postModerationFlags, userModerationFlags, preferences);

    useEffect(() => {
        if (preferences?.moderationSettings && postModerationFlags && userModerationFlags) {
            let shouldExpand = false;
            let shouldHide = false;
            let hasMask = false;
    
            // Check user moderation flags
            const userFlags = userModerationFlags[post.PosterPublicKeyBase58Check] || [];
            userFlags.forEach(flag => {
                const preferenceValue = preferences.moderationSettings[flag];
    
                if (preferenceValue === false) {
                    // Block output completely
                    shouldHide = true;
                    //console.log(`Content for user ${post.PosterPublicKeyBase58Check} is blocked due to false preference for flag ${flag}`);
                } else if (preferenceValue === null && !shouldHide) {
                    // Mask output unless a block is already set
                    hasMask = true;
                    //console.log(`Content for user ${post.PosterPublicKeyBase58Check} is masked due to null preference for flag ${flag}`);
                } else if (preferenceValue === true && !shouldHide && !hasMask) {
                    // Allow content if no block or mask exists
                    shouldExpand = true;
                    //console.log(`Content for user ${post.PosterPublicKeyBase58Check} is allowed due to true preference for flag ${flag}`);
                }
            });
    
            // Check post moderation flags
            const postFlags = postModerationFlags[post.PostHashHex] || [];
            postFlags.forEach(flag => {
                const preferenceValue = preferences.moderationSettings[flag];
    
                if (preferenceValue === false) {
                    // Block output completely
                    shouldHide = true;
                    //console.log(`Content for post ${post.PostHashHex} is blocked due to false preference for flag ${flag}`);
                } else if (preferenceValue === null && !shouldHide) {
                    // Mask output unless a block is already set
                    hasMask = true;
                    //console.log(`Content for post ${post.PostHashHex} is masked due to null preference for flag ${flag}`);
                } else if (preferenceValue === true && !shouldHide && !hasMask) {
                    // Allow content if no block or mask exists
                    shouldExpand = true;
                    //console.log(`Content for post ${post.PostHashHex} is allowed due to true preference for flag ${flag}`);
                }
            });
    
            // Finalize state updates based on checks
            if (shouldHide) {
                setBlocked(true);
                setExpandPost(false);
            } else if (hasMask) {
                setBlocked(false);
                setExpandPost(false);
            } else if (shouldExpand) {
                setBlocked(false);
                setExpandPost(true);
            } else {
                setBlocked(false);
                setExpandPost(true);
            }
        } else {
            setBlocked(false);
            setExpandPost(true);
        }

        if(currentUser?.PublicKeysBase58CheckFollowedByUser?.includes(post?.PosterPublicKeyBase58Check)) {
            setBlocked(false);
            setExpandPost(true);
        }
    }, [post, userModerationFlags, postModerationFlags]);

    


    const formattedTimestamp = new Date(post.TimestampNanos/1000000).toLocaleString();
    const timeSince = friendlyFormatDate(post.TimestampNanos/1000000);

    let postTypeOutput = postType;
    let tagged;

    if (post.Body && currentUser.ProfileEntryResponse.Username && post.Body.toLowerCase().includes(`@${currentUser.ProfileEntryResponse.Username.toLowerCase()}`)) {
        //console.log("TAGGED post",post);
        tagged = <span className="fw-bold text-body">tagged you </span>;
    }
    
    if(post?.RepostedPostEntryResponse && post?.Body) {
        postTypeOutput = tagged ? <>{tagged} in a quote</> : <>quoted <Avatar publicKey={post?.RepostedPostEntryResponse.PosterPublicKeyBase58Check} suppliedProfile={post?.RepostedPostEntryResponse.ProfileEntryResponse} /></>;
    } else if (post?.RepostedPostEntryResponse && !post.Body) {
        postTypeOutput = tagged ? <>{tagged} in a repost</> : 'reposted';
    } else if (post?.ParentStakeID) {
        postTypeOutput = tagged ? <>{tagged} in a reply</> : 'replied';
    } else {
        postTypeOutput = tagged ? <>{tagged} in a post</> : 'posted';
    }

    //if(!post.ProfileEntryResponse) { console.log("postHeader - no user?"); }

    //console.log(`PostHeader - ${post.ProfileEntryResponse.Username}`, userModerationFlags[post.PosterPublicKeyBase58Check],postModerationFlags[post.PostHashHex]);

    return (
        <div className={`postHeader flex-fill d-flex flex-col align-items-center ${expandPost ? `` : `opacity-50`}`}>
            <div className="fs-4"><Avatar suppliedProfile={post.ProfileEntryResponse} type="avatar" publicKey={post.PosterPublicKeyBase58Check}/></div>
            <div className="ps-2 flex-grow-1 justify-content-start align-items-center flex-wrap text-truncate">
                <Avatar suppliedProfile={post.ProfileEntryResponse} type="fullname" publicKey={post.PosterPublicKeyBase58Check}/>
                <div className="useraction">
                    {postTypeOutput}&nbsp;
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip><span className='text-end px-2'>{formattedTimestamp}</span></Tooltip>}
                        >
                        <span>{timeSince}</span>
                    </OverlayTrigger>
                </div>
            </div>
            {((userModerationFlags && userModerationFlags[post.PosterPublicKeyBase58Check]?.length > 0) || 
              (postModerationFlags && postModerationFlags[post.PostHashHex]?.length > 0)) && (
                <div className="d-inline dropdown userModerationSettings">
                <button className='btn btn-sm border-0 action' style={{ cursor: 'pointer' }} onClick={() => setShowFlags(!showFlags)} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bi bi-flag-fill text-danger"></i>
                </button>
                
                    <ul className="openModeration dropdown-menu small text-center">
                        <li>
                            <span className="dropdown-item small"><i className="bi bi-flag-fill me-2"></i>Open<span className="fw-light">Moderation</span><br/>
                            {(userModerationFlags && userModerationFlags[post.PosterPublicKeyBase58Check] || []).map((flag, index) => (
                                <span key={`userMod_${index}`} className="badge bg-warning text-dark">{flag}</span>
                            ))}
                            {(postModerationFlags && postModerationFlags[post.PostHashHex] || []).map((flag, index) => (
                                    <span key={`postMod_${index}`} className="badge bg-warning text-dark">{flag}</span>
                            ))}
                            </span>
                        </li>
                    </ul>
                
                </div>
            )}
            {!expandPost && (
                <span className="btn btn-sm border-0 action d-flex flex-row flex-nowrap" onClick={() => setExpandPost(true)}><i className="bi bi-eye px-2"></i><span className="d-none d-md-inline">unhide</span></span>
            )}
            {postIcon && postIcon !== '' && <div className="fs-5 px-2">{postIcon}</div>}
            {!preview && ( <Bookmark post={post} currentUser={currentUser} />)}
            {post?.IsHidden && ( 
                <OverlayTrigger
                    key={`${post.PostHashHex} hidden`}
                    placement="top"
                    overlay={<Tooltip><span className='px-2'>{post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? post.ProfileEntryResponse.Username: `Author`} has hidden this content</span></Tooltip>}
                >
                    <i className="fs-5 bi bi-incognito"></i>
                </OverlayTrigger> )}
            {!preview && (<UserModeration post={post} currentUser={currentUser} preferences={preferences} target={post.ProfileEntryResponse} label={true} className='mx-1 px-2' size='sm' />)}
        </div>
    )
}

export const FormattedBlog = ({ post }) => {
    const [quillInitialized, setQuillInitialized] = React.useState(false);
    const divRef = React.useRef(null);
    let delta;
    let deltaObject = null;

    if (post.PostExtraData && post.PostExtraData['DNI-ZirkelsBlogContent']) {
        delta = post.PostExtraData['DNI-ZirkelsBlogContent'];
    } else if (post.PostExtraData && post.PostExtraData.BlogDeltaRtfFormat) {
        delta = post.PostExtraData.BlogDeltaRtfFormat;
    }

    React.useEffect(() => {
        if (!delta || !divRef.current) return;

        try {
            deltaObject = JSON.parse(delta);
            //console.log("BLOG.", deltaObject);

            // Initialize Quill in read-only mode
            const quill = new Quill(divRef.current, { readOnly: true });
            quill.setContents(deltaObject.ops);
            setQuillInitialized(true);

            const removeEmptyElements = () => {
                const elements = divRef.current.querySelectorAll('p, h3, h2, h4, h5, h6, pre'); // Add any other tags you want to check
                elements.forEach(el => {
                    if (el.innerHTML.trim() === '<br>') {
                        el.style.display = 'none';
                    }
                });
            };

            removeEmptyElements();

        } catch (error) {
            // If parsing as JSON fails, assume it's a Markdown string
            setQuillInitialized(false);
        }
    }, [post]);

    if (!delta) {
        // If delta parsing fails or delta is not present, return markdown/plain body
        return post.PostExtraData.bodyMarkdown ? (
            <ReactMarkdown>{post.PostExtraData.bodyMarkdown}</ReactMarkdown>
        ) : (
            <div>{nl2br(post.Body)}</div>
        );
    }

    return <div className="m-0 p-0" ref={divRef} />;
};

export const EngagementBar = ({ post, currentUser, level, type, view, accessGroups, preferences, thread, preview, alternateUsers, isExpanded }) => {
    const handleShare = async ({ title, taster, url }) => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: title,
                    text: taster,
                    url: url
                });
            } else {
                alert('Web Share API not supported in your browser.');
            }
        } catch (error) {
            // console.error('Error sharing:', error.message);
        }
    };

    const url = '/posts/' + post.PostHashHex;
    let firstLine = null;
    let taster = null;
    if(post.Body) {
        firstLine = post.Body.split('\n')[0]; // Get the first line of the body
        taster = firstLine.length > 100 ? firstLine.substring(0, 100) + '...' : firstLine;
    }
    let title;
    if(post && post.PostExtraData && post.PostExtraData.Title) {
        title = `${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? '@'+post.ProfileEntryResponse.Username+': ' : null } ${post.PostExtraData.Title}`;
    } else {
        title = `${post.ProfileEntryResponse && post.ProfileEntryResponse.Username ? '@'+post.ProfileEntryResponse.Username+': ' : null } ${taster}`;
    }

    let featureImage;
    if(post.PostExtraData && post.PostExtraData.CoverImage) {
        featureImage = post.PostExtraData.CoverImage;
    } else if (post.ImageURLs && post.ImageURLs.length > 0) {
        featureImage = post.ImageURLs[0];
    } else if (post.poster && post.poster.extraData && post.poster.extraData.FeaturedImageURL) {
        featureImage = post.poster.extraData.FeaturedImageURL;
    } else {
        featureImage = null;
    }

    const ShareLinkComponent = (
        <ShareLink
            title={title}
            taster={taster}
            url={url}
            image={featureImage}
            handleShare={handleShare}
        />
    );

    return (
        <div className="d-flex justify-content-around text-center pt-1 engagementPanel">
            <SendReactionButton key={`reaction_${post.PostHashHex}`} PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} ReceiverPublicKeyBase58Check={post.PosterPublicKeyBase58Check} post={post} />
            <SendDiamondsButton key={`diamond_${post.PostHashHex}`} PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} ReceiverPublicKeyBase58Check={post.PosterPublicKeyBase58Check} DiamondLevel={1} post={post} />
            <SendRepostButton currentUser={currentUser} alternateUsers={alternateUsers} preferences={preferences} key={`repost_${post.PostHashHex}`} PostHashHex={post.PostHashHex} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} post={post} />
            <DirectTipButton key={`directip_${post.PostHashHex}`} SenderPublicKeyBase58Check={currentUser?.PublicKeyBase58Check} Receiver={post.ProfileEntryResponse} PostHashHex={post.PostHashHex} source='post' />
            <div className="align-self-center share-link action">
                {ShareLinkComponent}
            </div>
        </div>
    );
}

/***************************************************************************
 * Media Components
 */
export const OutputVideoThumbnail = ({ post, url }) => {
    const [thumbnail, setThumbnail] = useState(null);
    const [fallbackThumbnail, setFallbackThumbnail] = useState(null);
    const [showIframe, setShowIframe] = useState(false);
    const [playbackId, setPlaybackId] = useState(null);

    useEffect(() => {
        if (url.includes("https://lvpr.tv") && playbackId) {
            const options = {
                method: 'GET',
                headers: { Authorization: '949cwn7ln5sghzkr' } // Replace with your actual token.
            };
    
            //console.log("[postLayouts.ks] VIDEO: Fetching video details from Livepeer API with playback ID:", url, playbackId);
            //console.log("[postLayouts.ks] VIDEO: Request options:", options);
    
            // Fetch video details from Livepeer API
            fetch(`https://livepeer.studio/api/playback/${playbackId}`, options)
                .then(response => {
                    //console.log("[postLayouts.ks] VIDEO: API response:", response); // Log the raw response
                    return response.json();
                })
                .then(data => {
                    //console.log("[postLayouts.ks] VIDEO: Parsed response data:", data); // Log the parsed response data
                    const isHLS = data.meta?.source?.some(source => source.hrn === "HLS (TS)");
                    
                    // First, try to get the thumbnail from 'text/vtt'
                    const thumbnailSource = data.meta?.source?.find(source => source.type === "text/vtt");
                    if (thumbnailSource && thumbnailSource.url) {
                        // Set the thumbnail URL from the vtt file
                        //console.log("[postLayouts.ks] VIDEO: found thumbnail:", thumbnailSource.url.replace('/thumbnails.vtt', '/keyframes_0.jpg'));
                        const jpgThumbnailUrl = thumbnailSource.url.replace('/thumbnails.vtt', `/keyframes_0.jpg`);
                        const pngThumbnailUrl = thumbnailSource.url.replace('/thumbnails.vtt', `/keyframes_0.png`);
                        
                        // Set the primary thumbnail URL and fallback to png if loading fails
                        setThumbnail(jpgThumbnailUrl); // Primary .jpg URL
                        setFallbackThumbnail(pngThumbnailUrl);
                    } else {
                        // For any source type, replace the last part of the URL with 'keyframe_0.jpg' and 'keyframe_0.png'
                        const videoSource = data.meta?.source?.find(source => source.url);
                        if (videoSource && videoSource.url) {
                            const videoUrl = videoSource.url;
        
                            // Replace the last segment of the URL (any file path segment) with 'keyframe_0.jpg' and 'keyframe_0.png'
                            const jpgThumbnailUrl = videoUrl.replace(/\/[^\/]+$/, '/keyframe_0.jpg');
                            const pngThumbnailUrl = videoUrl.replace(/\/[^\/]+$/, '/keyframe_0.png');
                    
                            //console.log("[postLayouts.ks] Fallback thumbnail URL:", jpgThumbnailUrl, pngThumbnailUrl);
                    
                            // Set the thumbnail URLs
                            setThumbnail(jpgThumbnailUrl); // Set the .jpg thumbnail URL
                            setFallbackThumbnail(pngThumbnailUrl); // Set the .png fallback thumbnail URL
                        }
                    }
                })
                .catch(err => {
                    console.error("[postLayouts.ks] VIDEO: Error fetching video details:", err); // Log errors if any
                });
        }
    
        if (!playbackId && url.includes("https://lvpr.tv")) {
            const extractedId = extractPlaybackId(url);
            //console.log("[postLayouts.ks] VIDEO: Extracted playback ID:", extractedId); // Log the extracted playback ID
            setPlaybackId(extractedId);
        }
    }, [url, playbackId]);
    

    const handleThumbnailClick = () => {
        setShowIframe(true);
    };

    // Extract the playback ID from the URL
    const extractPlaybackId = (url) => {
        const urlObj = new URL(url); // Parse the URL
        const playbackId = urlObj.searchParams.get('v'); // Extract the 'v' query parameter
        return playbackId || null; // Return the ID or null if not found
    };
    

    if (!url) {
        return null;
    }

    if (url.includes("https://lvpr.tv")) {
        if ((thumbnail && thumbnail !== 'null' && thumbnail !== '') || (fallbackThumbnail && fallbackThumbnail !== '' && fallbackThumbnail !== '')) {
            return (
                <div className="position-relative w-100 m-0">
                    <img
                        src={thumbnail && thumbnail !== 'null' && thumbnail !== '' ? thumbnail : fallbackThumbnail} 
                        onError={(e) => e.target.src = fallbackThumbnail} 
                        alt={`Video thumbnail for ${post.ProfileEntryResponse?.Username || 'author'}'s post`}
                        className="w-100 m-0 postAttachment"
                        style={{ width: "100%", height: "auto" }}
                    />
                    {/* Play icon overlay */}
                    <i
                        className="bi bi-play-circle-fill position-absolute top-50 start-50 translate-middle fs-1 hover"
                        style={{ zIndex: 2 }}
                    ></i>
                </div>
            );
        } else {
            <div className="position-relative w-100 m-0 postAttachment">
                <i
                    className="bi bi-play-circle-fill position-absolute top-50 start-50 translate-middle fs-1 hover"
                    style={{ zIndex: 2 }}
                ></i>
            </div>
        }
    } else {
        // Fallback to regular video embedding for other video URLs
        return (
            <div className="position-relative w-100 m-0 postAttachment">
                <i
                    className="bi bi-play-circle-fill position-absolute top-50 start-50 translate-middle fs-1 hover"
                    style={{ zIndex: 2 }}
                ></i>
            </div>
        );
    }
};

/***************************************************************************
 * Placeholders
 */

export const PostPlaceholder = ({feedType, view, single = null}) => {
    //console.log("[posts.js] PostPlaceholder contentType, view:",feedType,view);
    let type = 'post';

    switch(view) {
        case "grid": type = "grid"; break;
        case "media": type = "media"; break;
        case "threaded":
        default: type = 'list'; break;
    }

    switch(feedType) {
        case "video": type="media"; break;
        case "images": type="grid"; break;
        case "comments": type="comment"; break;
        case "blog": type="blog"; break;
        case "blog_full": type="blog_full"; break;
    }

    if (type === "grid") {
        return (
            <>
                <div className="col-6 col-md-4 col-lg-2 col-xl-2 p-2 placeholder-glow">
                    <div className="position-relative">
                        <div className="img-fluid w-100 imageFeedAspect object-fit-cover placeholder"></div>
                        <div className="position-absolute top-50 start-50 translate-middle fs-1">
                        <i className="bi bi-image-fill placeholdericon"></i>
                        </div>
                    </div>
                </div>
                <div className={`col-6 col-md-4 col-lg-2 col-xl-2 p-2 placeholder-glow${single ? ` d-none` : ``}`}>
                    <div className="position-relative">
                        <div className="img-fluid w-100 imageFeedAspect object-fit-cover placeholder"></div>
                        <div className="position-absolute top-50 start-50 translate-middle fs-1">
                        <i className="bi bi-image-fill placeholdericon"></i>
                        </div>
                    </div>
                </div>
                <div className={`d-none d-md-block col-6 col-md-4 col-lg-2 col-xl-2 p-2 placeholder-glow${single ? ` d-none` : ``}`}>
                    <div className="position-relative">
                        <div className="img-fluid w-100 imageFeedAspect object-fit-cover placeholder"></div>
                        <div className="position-absolute top-50 start-50 translate-middle fs-1">
                        <i className="bi bi-image-fill placeholdericon"></i>
                        </div>
                    </div>
                </div>
                <div className={`d-none d-lg-block col-6 col-md-4 col-lg-2 col-xl-2 p-2 placeholder-glow${single ? ` d-none` : ``}`}>
                    <div className="position-relative">
                        <div className="img-fluid w-100 imageFeedAspect object-fit-cover placeholder"></div>
                        <div className="position-absolute top-50 start-50 translate-middle fs-1">
                        <i className="bi bi-image-fill placeholdericon"></i>
                        </div>
                    </div>
                </div>
                <div className={`d-none d-lg-block col-6 col-md-4 col-lg-2 col-xl-2 p-2 placeholder-glow${single ? ` d-none` : ``}`}>
                    <div className="position-relative">
                        <div className="img-fluid w-100 imageFeedAspect object-fit-cover placeholder"></div>
                        <div className="position-absolute top-50 start-50 translate-middle fs-1">
                        <i className="bi bi-image-fill placeholdericon"></i>
                        </div>
                    </div>
                </div>
                <div className={`d-none d-lg-block col-6 col-md-4 col-lg-2 col-xl-2 p-2 placeholder-glow${single ? ` d-none` : ``}`}>
                    <div className="position-relative">
                        <div className="img-fluid w-100 imageFeedAspect object-fit-cover placeholder"></div>
                        <div className="position-absolute top-50 start-50 translate-middle fs-1">
                        <i className="bi bi-image-fill placeholdericon"></i>
                        </div>
                    </div>
                </div>
            </>
          );
    } else if (type === "media") {
        return (
            <>
                <div className="w-100 p-2 pb-5 mb-4 placeholder-glow">
                    <div className="border-0 rounded-0 p-0 m-0 text-nowrap message-header placeholder-wave">
                        <div className="row d-flex flex-row justify-content-between align-items-center flex-nowrap">
                        <div className="col flex-fill d-flex flex-col align-items-center">
                            <div className="ps-2 fs-4" style={{ opacity: 1 }}>
                                <div className="placeholder avatar-placeholder deso_avatar"></div>
                            </div>
                            <div className="ps-2 flex-grow-1 align-items-center flex-wrap text-truncate" style={{ opacity: 1 }}>
                            <span className="placeholder col-4"></span>
                            <div className="useraction">
                                <span className="placeholder col-3 placeholder-xs"></span>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="position-relative">
                        <div className="w-100 videoFeedAspect object-fit-cover placeholder"></div>
                        <div className="position-absolute top-50 start-50 translate-middle fs-1">
                        <i className="bi bi-play-btn-fill placeholdericon"></i>
                        </div>
                    </div>
                </div>
                <div className={`w-100 p-2 pb-5 mb-4 placeholder-glow${single ? ` d-none` : ``}`}>
                    <div className="border-0 rounded-0 p-0 m-0 text-nowrap message-header placeholder-wave">
                        <div className="row d-flex flex-row justify-content-between align-items-center flex-nowrap">
                        <div className="col flex-fill d-flex flex-col align-items-center">
                            <div className="ps-2 fs-4" style={{ opacity: 1 }}>
                                <div className="placeholder avatar-placeholder deso_avatar"></div>
                            </div>
                            <div className="ps-2 flex-grow-1 align-items-center flex-wrap text-truncate" style={{ opacity: 1 }}>
                            <span className="placeholder col-4"></span>
                            <div className="useraction">
                                <span className="placeholder col-3 placeholder-xs"></span>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="position-relative">
                        <div className="w-100 videoFeedAspect object-fit-cover placeholder"></div>
                        <div className="position-absolute top-50 start-50 translate-middle fs-1">
                        <i className="bi bi-play-btn-fill placeholdericon"></i>
                        </div>
                    </div>
                </div>
                <div className={`d-none d-lg-block w-100 p-2 pb-5 mb-4 placeholder-glow${single ? ` d-none` : ``}`}>
                    <div className="border-0 rounded-0 p-0 m-0 text-nowrap message-header placeholder-wave">
                        <div className="row d-flex flex-row justify-content-between align-items-center flex-nowrap">
                        <div className="col flex-fill d-flex flex-col align-items-center">
                            <div className="ps-2 fs-4" style={{ opacity: 1 }}>
                                <div className="placeholder avatar-placeholder deso_avatar"></div>
                            </div>
                            <div className="ps-2 flex-grow-1 align-items-center flex-wrap text-truncate" style={{ opacity: 1 }}>
                            <span className="placeholder col-4"></span>
                            <div className="useraction">
                                <span className="placeholder col-3 placeholder-xs"></span>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="position-relative">
                        <div className="w-100 videoFeedAspect object-fit-cover placeholder"></div>
                        <div className="position-absolute top-50 start-50 translate-middle fs-1">
                        <i className="bi bi-play-btn-fill placeholdericon"></i>
                        </div>
                    </div>
                </div>
            </>
          );
    } else if (type === "blog") {
        return (
            <>
                <div className="col-12 mb-4">
                    <div className="card m-0 border-0 lh-sm position-relative">
                        <div className="ratio ratio-16x9 placeholder-glow">
                            <div className='placeholder bg-info bg-opacity-50'></div>
                        </div>
                        <div className="w-100 p-2 position-absolute" style={{ bottom: '0px' }}>
                            <div className='p-2 bg-body placeholder-glow placeholder-wave bg-opacity-25 rounded'>
                                <h5 className="placeholder placeholder-lg col-12"></h5>
                                <h5 className="placeholder placeholder-lg col-5"></h5>
                                <span className='placeholder placeholder-sm col-2 small ms-1'></span>
                                <p className="m-0 row">
                                    <span className="col-6 d-flex flex-row flex-nowrap p-0">
                                        <span className="placeholder ratio ratio-1x1 avatar-placeholder me-2"></span>
                                        <span className="fw-bold placeholder w-75"></span>
                                    </span>
                                    <span className='col'></span>
                                    <span className="placeholder col-1 rounded-pill"></span>
                                    <span className="placeholder col-1 rounded-pill ms-1"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`col-12 mb-4${single ? ` d-none` : ``}`}>
                    <div className="card m-0 border-0 lh-sm position-relative">
                        <div className="ratio ratio-16x9 placeholder-glow">
                            <div className='placeholder bg-warning bg-opacity-50'></div>
                        </div>
                        <div className="w-100 p-2 position-absolute" style={{ bottom: '0px' }}>
                            <div className='p-2 bg-body placeholder-glow placeholder-wave bg-opacity-25 rounded'>
                                <h5 className="placeholder placeholder-lg col-7"></h5>
                                <span className='placeholder placeholder-sm col-2 small ms-1'></span>
                                <p className="m-0 row">
                                    <span className="col-6 d-flex flex-row flex-nowrap p-0">
                                        <span className="placeholder ratio ratio-1x1 avatar-placeholder me-2"></span>
                                        <span className="fw-bold placeholder w-50"></span>
                                    </span>
                                    <span className='col'></span>
                                    <span className="placeholder col-1 rounded-pill"></span>
                                    <span className="placeholder col-1 rounded-pill ms-1"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
          );
    } else if (type === "blog_full") {
        return (
            <>
                <div className="pb-5 placeholder-glow" id="post_976a95bf5b37127a45981f726483621c814dcf7920547bac92b82285faae4c2a">
                    <div>
                        <span className="fs-1 placeholder placeholder-lg col-12"></span><br/>
                        <span className="fs-1 placeholder placeholder-lg col-10"></span>
                    </div>
                    <div className="mb-3">
                        <span className="fs-5 placeholder ratio ratio-1x1 avatar-placeholder me-2"></span>
                        <span className="fs-4 placeholder placeholder col-5"></span>
                        <span className='fs-5 placeholder placeholder col-2 small ms-1'></span>
                    </div>
                    <p className="mb-3">
                        <span className="placeholder placeholder-lg col-12"></span><br/>
                        <span className="placeholder placeholder-lg col-12"></span><br/>
                        <span className="placeholder placeholder-lg col-12"></span><br/>
                        <span className="placeholder placeholder-lg col-4"></span>
                    </p>
                    <p className="mb-3">
                        <span className="placeholder placeholder-lg col-12"></span><br/>
                        <span className="placeholder placeholder-lg col-12"></span><br/>
                        <span className="placeholder placeholder-lg col-9"></span>
                    </p>
                    <div className="ratio ratio-16x9 placeholder-glow mb-3">
                        <div className='placeholder bg-opacity-50'></div>
                    </div>
                    <p className="mb-3">
                        <span className="placeholder placeholder-lg col-12"></span><br/>
                        <span className="placeholder placeholder-lg col-8"></span>
                    </p>
                </div>
            </>
            );
    } else if(type==="comment") {
        return (
            <div className={`col-12 p-0 m-0 repliesContainer placeholder-glow`}>
                <div className='repliesWrapper d-flex justify-content-between align-items-top flex-nowrap p-0 m-0 mb-4'>
                    <div style={{ width: "2.1rem"  }}>
                        <div className="placeholder avatar-placeholder deso_avatar"></div>
                    </div>
                    <div className={`flex-fill flex-grow-1`}>
                        <div>
                            <div className='replyContainer d-block placeholder bg-secondary p-2 rounded-3'>
                                <p>
                                    <span className='placeholder col-4'></span>
                                    <span className='placeholder col-3 ms-2 placeholder-sm'></span>
                                </p>
                                <p>
                                    <span className='placeholder col-8'></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`repliesWrapper d-flex justify-content-between align-items-top flex-nowrap p-0 m-0 mb-4${single ? ` d-none` : ``}`}>
                    <div style={{ width: "2.1rem"  }}>
                        <div className="placeholder avatar-placeholder deso_avatar"></div>
                    </div>
                    <div className={`flex-fill flex-grow-1`}>
                        <div>
                            <div className='replyContainer d-block placeholder bg-secondary p-2 rounded-3'>
                                <p>
                                    <span className='placeholder col-5'></span>
                                    <span className='placeholder col-2 ms-2 placeholder-sm'></span>
                                </p>
                                <p>
                                    <span className='placeholder col-12'></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <>
                <div className="col-12 placeholder-glow mb-5">
                    <div className="border-0 rounded-0 p-0 m-0 pt-2 text-nowrap message-header mb-1">
                        <div className="row d-flex flex-row justify-content-between align-items-center flex-nowrap">
                        <div className="col flex-fill d-flex flex-col align-items-center placeholder-wave">
                            <div className="ps-2 fs-4" style={{ opacity: 1 }}>
                                <div className="placeholder avatar-placeholder deso_avatar"></div>
                            </div>
                            <div className="ps-2 flex-grow-1 align-items-center flex-wrap text-truncate" style={{ opacity: 1 }}>
                            <span className="placeholder col-3"></span>
                            <div className="useraction">
                                <span className="placeholder col-4 placeholder-xs"></span>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-12 position-relative placeholder bg-secondary rounded-0 border-0 p-3 m-0 card-effect">
                        <div className='placeholder-wave'>
                            <p>
                                <span className='placeholder col-12'></span><br/>
                                <span className='placeholder col-6'></span>
                            </p>
                            <p>
                                <span className='placeholder col-12'></span><br/>
                                <span className='placeholder col-8'></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className={`col-12 placeholder-glow mb-5${single ? ` d-none` : ``}`}>
                    <div className="border-0 rounded-0 p-0 m-0 pt-2 text-nowrap message-header mb-1">
                        <div className="row d-flex flex-row justify-content-between align-items-center flex-nowrap">
                        <div className="col flex-fill d-flex flex-col align-items-center placeholder-wave">
                            <div className="ps-2 fs-4" style={{ opacity: 1 }}>
                            <div className="placeholder avatar-placeholder deso_avatar"></div>
                            </div>
                            <div className="ps-2 flex-grow-1 align-items-center flex-wrap text-truncate" style={{ opacity: 1 }}>
                            <span className="placeholder col-4"></span>
                            <div className="useraction">
                                <span className="placeholder col-3 placeholder-xs"></span>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-12 position-relative placeholder bg-secondary rounded-0 border-0 p-3 m-0 card-effect">
                        <div className='placeholder-wave'>
                            <p>
                                <span className='placeholder col-12'></span>
                            </p>
                            <p className="placeholder col-8 mb-0"></p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export const PostSettings = ({ post, currentUser, preferences }) => {
    const [showOptions, setShowOptions] = useState(false);
    const { existingPost, setExistingPost } = useContext(AppDataContext);
    const [showHideModal, setShowHideModal] = useState(false);

    const toggleShowOptions = (event) => {
        if (event) { event.stopPropagation(); }
        setShowOptions(prevState => !prevState);
    }

    if(!post || !currentUser) { return; } 
    
    return (
        <>
            {currentUser.PublicKeyBase58Check === post.PosterPublicKeyBase58Check && (
                <>
                <li><span className="dropdown-item" onClick={() => handlePinPost(post, currentUser, preferences)}><i className="bi bi-pin-angle me-2"></i>Pin to Profile (Home)</span></li>
                <li><span className="dropdown-item" onClick={() => setExistingPost(post)}><i className="bi bi-pencil me-2"></i>Edit Post</span></li>
                <li><span className="dropdown-item" onClick={() => setShowHideModal(true)}><i className={`bi bi-${post.IsHidden ? `eye-fill` : `eye-slash`} me-2`}></i>{post.IsHidden ? `Unhide` : `Hide`} Post</span></li>    
                </>
            )}
            {showHideModal && (
                <HidePost  showHideModal={showHideModal} setShowHideModal={setShowHideModal} currentUser={currentUser} post={post} />
            )}
        </>
    );
}

export const PostPollComponent = ({ post, currentUser, level, type, view, accessGroups, preferences, thread, preview, alternateUsers, isExpanded }) => {
    const [pollResponses, setPollResponses] = useState({});
    const [pollOptions, setPollOptions] = useState(post?.PostExtraData?.PollOptions ? JSON.parse(post.PostExtraData.PollOptions) : null);
    const [pollExpiration, setPollExpiration] = useState(post?.PostExtraData?.PollExpirationBlockHeight ? post.PostExtraData.PollExpirationBlockHeight : null);
    const [showResults, setShowResults] = useState(false);
    const [refreshResults, setRefreshResults] = useState(true);
    const [userHasVoted, setUserHasVoted] = useState(false);
    const [usersVote, setUsersVote] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(null);

    useEffect(() => {
        const  fetchpollResponses = async () => {
            const payload = {
                PostHashHex: post.PostHashHex,
                AssociationType: "POLL_RESPONSE",

            }
            try {
                const data = await getPostAssociations(payload);
                setPollResponses(data.Associations);
                //console.log("PostAssociations",data);
            } catch (error) {
            } finally {
                setRefreshResults(false);
            }
        }
        if((!pollResponses || refreshResults) && pollOptions) { 
            fetchpollResponses();
        }
    }, [pollOptions, refreshResults]);
    
    useEffect(() => {
        setPollOptions(post?.PostExtraData?.PollOptions ? JSON.parse(post.PostExtraData.PollOptions) : null);
        setPollExpiration(post?.PostExtraData?.PollExpirationBlockHeight ? post.PostExtraData.PollExpirationBlockHeight : null);
        setPollResponses({});
        setRefreshResults(true);
    }, [post]);

    useEffect(() => {
        if (pollResponses && pollResponses.length > 0) {
            const userResponse = pollResponses.find(
                (response) => response.TransactorPublicKeyBase58Check === currentUser?.PublicKeyBase58Check
            );
    
            if (userResponse || !currentUser) {
                setUserHasVoted(true);
                if (userResponse) {
                    setUsersVote(userResponse.AssociationValue); // Set the user's vote
                }
            } else {
                setUserHasVoted(false);
                setUsersVote(null); // Reset the user's vote if they haven't voted
            }
        }
    }, [pollResponses, currentUser]);
    

    const submitVote = async (option) => {
        if (!currentUser || !post) {
            console.error("User or post data is missing.");
            return;
        }

        setIsSubmitting(true);
        const payload = {
            AssociationType: "POLL_RESPONSE",
            AssociationValue: option,
            PostHashHex: post.PostHashHex,
            TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
            AppPublicKeyBase58Check: process.env.REACT_APP_SYSTEM_KEY,  
        };

        try {
            const response = await createPostAssociation(payload); // Assuming this function is available to send the request
            //console.log("Vote submitted successfully:", response);
            setRefreshResults(true); // Refresh results after submitting the vote
        } catch (error) {
            console.error("Error submitting vote:", error);
        } finally {
            setIsSubmitting(false);
        }
    };
    
    if(!post?.PostExtraData?.PollOptions) {
        return;
    }
    return (
        <>
            <React.Fragment>
                {refreshResults || isSubmitting? (
                    <div className="w-100 d-flex flex-column align-items-center justify-content-center" style={{ height: "15em" }}>
                        <Spinner />
                    </div>
                ) : (
                    <div className={`w-100 p-3 pollComponent ${showResults ? `expanded` : ``} ${preview ? 'disabled' : ''}`}>
                        {showResults ? (
                            <>
                                <PostPollResults post={post} pollOptions={pollOptions} pollResponses={pollResponses} usersVote={usersVote} />
                            </>
                        ) : (
                            <>
                                <h5 className="text-center">{post?.PostExtraData?.PollTitle ? post.PostExtraData.PollTitle: `Vote in this poll` } </h5>
                                {userHasVoted ? (
                                    <div className="text-center py-5">
                                        <p><i className="bi bi-check-circle-fill me-2"></i>You have already voted in this poll.</p>
                                        <span 
                                            className={`btn btn-primary ${preview ? 'disabled' : ''}`} 
                                            onClick={!preview ? () => setShowResults(!showResults) : undefined}>
                                            View the results
                                        </span>
                                    </div>
                                ) : (
                                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 d-flex flex-row justify-content-center align-items-center">
                                    {pollOptions.map((option, index) => (
                                        <div key={index} className="col mb-3 d-flex align-self-stretch text-center">
                                            <div 
                                                className={`btn w-100 h-100 bg-success bg-gradient bg-opacity-50 shadow-sm d-flex align-items-center justify-content-center ${preview ? 'disabled' : 'active'}`} 
                                                style={{ height: '100px' }} 
                                                onClick={!preview ? () => submitVote(option) : undefined}>
                                                {option && option.length > 30 ? <small>{option}</small> : option }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                )}
                            </>
                        )}
                        <div className="d-flex flex-row justify-content-end align-items-center">
                            <span 
                                className={`btn btn-small action ${preview ? 'disabled' : ''}`} 
                                onClick={!preview ? () => setShowResults(!showResults) : undefined}>
                                <i className={`me-2 bi bi-pie-chart${!showResults ? `-fill` : ``}`}></i>
                                {!showResults ? `show` : `hide`} results 
                                {pollResponses.length && <span className="text-muted small ms-1">({pollResponses.length} responses)</span>}
                            </span>
                            <span 
                                className={`btn btn-small action ${preview ? 'disabled' : ''}`} 
                                onClick={!preview ? () => setRefreshResults(true) : undefined}>
                                <i className="bi bi-arrow-clockwise me-2"></i>refresh
                            </span>
                        </div>
                    </div>

                )}
            </React.Fragment>
        </>
    )
}

const PostPollResults = ({ post, pollOptions, pollResponses, usersVote, refreshPost }) => {
    const [chartType, setChartType] = useState('pie'); // Default to pie chart
    const [chartData, setChartData] = useState(null);
    const [visibleOptions, setVisibleOptions] = useState(pollOptions.map(() => true)); // Track visibility of each option
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null); // Store the chart instance

    useEffect(() => {
        // Filter poll responses to include only those that match valid pollOptions
        const filteredResponses = pollResponses.filter(response => 
            pollOptions.includes(response.AssociationValue)
        );

        // Count responses for each poll option
        const responseCounts = pollOptions.map(option => {
            return filteredResponses.filter(response => response.AssociationValue === option).length;
        });

        // Set chart data based on options and counts
        setChartData({
            labels: pollOptions,
            datasets: [{
                label: 'Poll Responses',
                data: responseCounts,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)',
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
                    'rgba(255, 159, 64, 0.5)',
                ],
            }],
        });
    }, [pollOptions, pollResponses]);

    useEffect(() => {
        if (!chartData || !chartRef) { return; }
        // Destroy the previous chart instance if it exists
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        // Create a new chart instance
        const ctx = chartRef.current.getContext('2d');
        chartInstanceRef.current = new Chart(ctx, {
            type: chartType === 'horizontalBar' ? 'bar' : chartType,
            data: {
                labels: chartData.labels,
                datasets: [{
                    ...chartData.datasets[0],
                    data: chartData.datasets[0].data.map((value, index) => 
                        visibleOptions[index] ? value : 0 // Set to 0 if not visible
                    ),
                }],
            },
            options: {
                indexAxis: chartType === 'horizontalBar' ? 'y' : 'x',
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const label = context.label || '';
                                const value = context.raw || 0;
                                return `${label}: ${value}`;
                            }
                        }
                    }
                },
            },
        });

        // Clean up the chart on component unmount
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
                chartInstanceRef.current = null; // Reset the reference
            }
        };
    }, [chartData, chartType, visibleOptions]);

    const toggleOptionVisibility = (index) => {
        setVisibleOptions((prev) => {
            const newVisibility = [...prev];
            newVisibility[index] = !newVisibility[index];
            return newVisibility;
        });
    };

    if (!chartData || !chartType || !pollOptions || !pollResponses) { return null; }
    
    return (
        <div className="w-100">
            <div className="d-flex justify-content-end mb-3">
                <div>
                    <div className="input-group input-group-sm d-flex align-items-stretch">
                        <button onClick={() => setChartType('pie')} className={`btn ${chartType === 'pie' ? 'btn-outline-secondary text-body' : 'btn-outline-secondary'}`}><i className="bi bi-pie-chart-fill me-2"></i>Pie</button>
                        <button onClick={() => setChartType('bar')} className={`btn ${chartType === 'bar' ? 'btn-outline-secondary text-body' : 'btn-outline-secondary'}`}><i className="bi bi-bar-chart-fill me-2"></i>Bar</button>
                        <button onClick={() => setChartType('horizontalBar')} className={`btn ${chartType === 'horizontalBar' ? 'btn-outline-secondary text-body' : 'btn-outline-secondary'}`}><i className="bi bi-bar-chart-steps me-2"></i>Horizontal</button>
                    </div>
                </div>
            </div>
            <div className="row align-items-center justify-content-center">
                {post.PostExtraData.PollTitle && (
                    <div className="col-12 text-center fs-5 fw-bold mb-3">{post.PostExtraData.PollTitle}</div>
                )}
                <div className="col-12 col-md-6 offset-lg-none col-xl-4 p-5 py-2 d-flex justify-content-center">
                    <canvas ref={chartRef} />
                </div>
                <div className="col-12 col-lg-6 col-xl-4 align-items-center">
                    <div className="fw-bold mb-3">Responses</div>
                    {chartData && pollOptions.map((option, index) => (
                        <div key={index} className={`list-group-item input-group d-flex w-100 flex-row flex-nowrap align-items-center mb-2 action ${visibleOptions[index] ? `` : `opacity-50` }`} onClick={() => toggleOptionVisibility(index)}>
                            {visibleOptions[index] ? (
                                <span 
                                    className="pollLegendKey input-group-text align-self-stretch justify-content-center" 
                                    style={{ backgroundColor: chartData.datasets[0].backgroundColor[index] }}
                                >{option === usersVote && (<i className="bi bi-check-circle-fill"></i>)}</span>
                            ) : (
                                <span 
                                    className="pollLegendKey input-group-text align-self-stretch justify-content-center" 
                                >{option === usersVote && (<i className="bi bi-check-circle-fill"></i>)}</span>
                            )}
                            <span className="flex-fill input-group-text align-self-stretch text-wrap text-start">{option}</span>
                            <span className="input-group-text align-self-stretch fw-light text-muted">
                                <span className="text-body fw-bold me-2">{chartData.datasets[0].data[index]}</span>
                                {chartData.datasets[0].data[index] === 1 ? `vote` : `votes`}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};


export const RSVPComponent = ({ post, currentUser, level, type, view, accessGroups, preferences, thread, preview, alternateUsers, isExpanded }) => {
    const [pollResponses, setPollResponses] = useState({});
    const [respondees, setRespondees] = useState([]);
    const [attendees, setAttendees] = useState([]);
    const [interested, setInterested] = useState([]);
    const [pollOptions, setPollOptions] = useState(post?.PostExtraData?.PollOptions ? JSON.parse(post.PostExtraData.PollOptions) : null);
    const [pollExpiration, setPollExpiration] = useState(post?.PostExtraData?.PollExpirationBlockHeight ? post.PostExtraData.PollExpirationBlockHeight : null);
    const [showResults, setShowResults] = useState(false);
    const [refreshResults, setRefreshResults] = useState(true);
    const [userHasVoted, setUserHasVoted] = useState(false);
    const [usersVote, setUsersVote] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(null);
    const [changeResponse, setChangeResponse] = useState(false);

    useEffect(() => {
        const  fetchpollResponses = async () => {
            const payload = {
                PostHashHex: post.PostHashHex,
                AssociationType: "POLL_RESPONSE",
                IncludeTransactorProfile: true,
            }
            try {
                const data = await getPostAssociations(payload);
                setPollResponses(data.Associations);
                const sortedRespondees = Object.entries(data.PublicKeyToProfileEntryResponse)
                    .map(([key, value]) => ({
                        key,
                        ...value
                    }))
                    .sort((a, b) => b.CoinPriceDeSoNanos - a.CoinPriceDeSoNanos);
            
                setRespondees(sortedRespondees);
            
                // Get attendees and interested parties based on matching TransactorPublicKeyBase58Check
                const tmpAttendees = data.Associations.filter(association => 
                    association.AssociationValue === 'Yes'
                ).map(association => 
                    data.PublicKeyToProfileEntryResponse[association.TransactorPublicKeyBase58Check]
                ).filter(Boolean); // filter out any undefined values
            
                const tmpInterested = data.Associations.filter(association => 
                    association.AssociationValue === 'Tentative'
                ).map(association => 
                    data.PublicKeyToProfileEntryResponse[association.TransactorPublicKeyBase58Check]
                ).filter(Boolean); // filter out any undefined values
            
                setAttendees(tmpAttendees);
                setInterested(tmpInterested);
                //console.log("PostAssociations", data);
            } catch (error) {
            } finally {
                setRefreshResults(false);
            }
        }
        if((!pollResponses || refreshResults) && pollOptions) { 
            fetchpollResponses();
        }
    }, [pollOptions, refreshResults]);

    useEffect(() => {
        // take 
        //console.log("PostAssociations Respondees:",respondees);
    }, [respondees]);
    
    useEffect(() => {
        setPollOptions(post?.PostExtraData?.PollOptions ? JSON.parse(post.PostExtraData.PollOptions) : null);
        setPollExpiration(post?.PostExtraData?.PollExpirationBlockHeight ? post.PostExtraData.PollExpirationBlockHeight : null);
        setPollResponses({});
        setRefreshResults(true);
    }, [post]);

    useEffect(() => {
        if (pollResponses && pollResponses.length > 0) {
            const userResponse = pollResponses.find(
                (response) => response.TransactorPublicKeyBase58Check === currentUser?.PublicKeyBase58Check
            );
    
            if (userResponse || !currentUser) {
                setUserHasVoted(true);
                if (userResponse) {
                    //console.log(userResponse);
                    setUsersVote(userResponse); // Set the user's vote
                }
            } else {
                setUserHasVoted(false);
                setUsersVote(null); // Reset the user's vote if they haven't voted
            }
        }
    }, [pollResponses, currentUser]);
    

    const submitVote = async (option) => {
        if (preview) { return; }
        if (!currentUser || !post) {
            console.error("User or post data is missing.");
            return;
        }
    
        setIsSubmitting(true);
    
        try {
            // Step 1: Find existing responses by the current user
            const existingResponses = pollResponses.filter(
                (response) => response.TransactorPublicKeyBase58Check === currentUser.PublicKeyBase58Check
            );
    
            // Step 2: Delete existing responses if any
            if (existingResponses.length > 0) {
                await Promise.all(
                    existingResponses.map(async (response) => {
                        const deletePayload = {
                            AssociationID: response.AssociationID,
                            TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                        };
                        await deletePostAssociation(deletePayload);
                    })
                );
            }
    
            // Step 3: Submit the new vote
            const payload = {
                AssociationType: "POLL_RESPONSE",
                AssociationValue: option,
                PostHashHex: post.PostHashHex,
                TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
                AppPublicKeyBase58Check: process.env.REACT_APP_SYSTEM_KEY,
            };
    
            const response = await createPostAssociation(payload);
            //console.log("Vote submitted successfully:", response);
            setRefreshResults(true);
        } catch (error) {
            console.error("Error submitting vote:", error);
        } finally {
            setIsSubmitting(false);
            setChangeResponse(false);
        }
    };
    
    
    if(!post?.PostExtraData?.PollOptions) {
        return;
    }
    return (
        <>
            <React.Fragment>
                {refreshResults || isSubmitting? (
                    <div className="w-100 d-flex flex-column align-items-center justify-content-start">
                        <Spinner />
                    </div>
                ) : (
                    <div className={`w-100 rsvpComponent d-flex flex-row flex-nowrap justify-content-between align-items-start mb-1`}>
                        <div className="d-flex flex-row flex-nowrap align-items-center">
                            {attendees && attendees.map((user, index) => (
                                <div
                                    key={index}
                                    style={{
                                    marginLeft: index >= 1
                                        ? `${((respondees.length > 9 ? -1.2 / 9 : -1.0 / 9) * (respondees.length > 9 ? 9 : respondees.length))}em`
                                        : '0em'
                                    }}
                                >
                                    <Avatar
                                    size={1.3}
                                    type="avatar"
                                    publicKey={user.PublicKeyBase58Check}
                                    link={false}
                                    />
                                </div>
                            ))}
                            <span className="small ms-2">{attendees.length} attending</span>
                            {interested.length && interested.length > 0 ? <span className="ms-2 small text-muted">{interested.length} interested</span> : `` }
                        </div>
                        <div className="d-flex flex-column">
                            {userHasVoted && !changeResponse ? (
                                <>
                                    {usersVote.AssociationValue === "Yes" ? (
                                        <span className="text-center fw-bold"><i className="bi bi-check-circle-fill me-2"></i>I am attending</span>
                                    ) : (
                                        <>
                                            {usersVote.AssociationValue === "Tentative" ? (
                                                <span className="text-center"><i className="bi bi-question-circle me-2"></i>Tentative</span>
                                            ) : (
                                                <span className="text-center fw-light text-muted"><i className="bi bi-x-circle-fill me-2"></i>Not attending</span>
                                            )}
                                        </>
                                    )}
                                    <span className="text-center text-muted small action" onClick={() => setChangeResponse(true)}>Edit response</span>
                                </>
                            ) : (
                                <>
                                    <div className="input-group input-group-sm">
                                        <span className="input-group-text bg-body bg-opacity-50 text-muted">RSVP</span>
                                        {pollOptions.map((option, index) => (
                                            <>
                                            {option === "Yes" || option === "Tentative" || option === "No" ? (
                                                <>
                                                    {option === 'Yes' && (<span key={index} onClick={() => submitVote(option)} className="btn btn-outline-secondary bg-body bg-opacity-50 text-body action"><i className="bi bi-check-circle-fill"></i></span>)}
                                                    {option === "Tentative" && (<span key={index} onClick={() => submitVote(option)} className="btn btn-outline-secondary bg-body bg-opacity-50 text-body action"><i className="bi bi-question-circle"></i></span>)}
                                                    {option === "No" && (<span key={index} onClick={() => submitVote(option)} className="btn btn-outline-secondary bg-body bg-opacity-50 text-body action"><i className="bi bi-x-circle-fill"></i></span>)}
                                                </>
                                            ) : (
                                                <>
                                                    {option && option.length > 30 ? <small onClick={() => submitVote(option)}  key={index} >{option}</small> : option }
                                                </>
                                            )}
                                            </>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                )}
            </React.Fragment>
        </>
    )
}

export function AddToCalendarButton({ post }) {
    const { EventStart, EventEnd, EventLocation, EventURL, Title } = post.PostExtraData;
  
    if(!EventStart) { return; }
    // Parse start and end times for iCal format (YYYYMMDDTHHmmSSZ)
    const formatToIcalDate = (isoDate) => {
      const date = new Date(isoDate);
      return date.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
    };
  
    const startDate = formatToIcalDate(EventStart);
    let endDate;
    if(!EventEnd) { endDate = startDate; }
    else {  endDate = formatToIcalDate(EventEnd); }
    const uid = `${post.PostHashHex}@${process.env.REACT_APP_URL}`;
  
    // Helper to wrap text at 75 characters with folding
    const foldLine = (text) => {
      return text.replace(/(.{75})/g, "$1\n ").trim();
    };
  
    const formattedBody = post?.Body?.replace(/\n/g, '\\n').replace(/\s+/g, ' ');
  
    const descriptionContent = `Check the latest details on ${process.env.REACT_APP_APPNAME}\\n` +
        `${process.env.REACT_APP_URL}/u/${post.ProfileEntryResponse.Username}/events/${post.PostExtraData.EventSlug}\\n\\n` +
        `${formattedBody}`;

    // ICS Calendar Content
    const icsContent = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "PRODID:-//Your App//EN",
    "BEGIN:VEVENT",
    `UID:${uid}`,
    `DTSTAMP:${formatToIcalDate(new Date().toISOString())}`,
    `SUMMARY:${Title || 'Event'}`,
    `DTSTART:${startDate}`,
    `DTEND:${endDate}`,
    `LOCATION:${EventLocation || ''}`,
    `DESCRIPTION:${descriptionContent}`,
    "END:VEVENT",
    "END:VCALENDAR"
    ].join("\n");
  
    // Function to download the .ics file
    const downloadIcsFile = () => {
        const blob = new Blob([icsContent], { type: 'text/calendar' });
        const url = window.URL.createObjectURL(blob);
        
        // Create a hidden anchor element and set attributes for download
        const a = document.createElement('a');
        a.href = url;
        a.download = `${Title || 'event'}.ics`;
        a.style.display = 'none'; // Make the anchor element invisible
        a.target = '_self'; // Ensures it won't trigger a new tab or reload
    
        // Append to the document, trigger click, and remove
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    
        // Revoke the URL after a brief delay to ensure download completes
        setTimeout(() => window.URL.revokeObjectURL(url), 100);
    };
  
    // Google Calendar link with full description
    const googleCalendarUrl = `https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(
      Title || 'Event'
    )}&dates=${startDate}/${endDate}&location=${encodeURIComponent(
      EventLocation || ''
    )}&details=${encodeURIComponent(`Check the latest details on ${process.env.REACT_APP_APPNAME}\n${process.env.REACT_APP_URL}/u/${post.ProfileEntryResponse.Username}/events/${post.PostExtraData.EventSlug}\n\n` + post.Body)}`;
  
    return (
      <div className="flex-shrink-1">
        <div className="input-group input-group-sm align-items-center justify-content-end">
            <span onClick={downloadIcsFile} className="btn btn-primary"><i className="bi bi-calendar-plus-fill me-2"></i>Calendar</span>
            <Link
                to={googleCalendarUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-secondary"
                >
                <i className="bi bi-google"></i>
            </Link>
        </div>
      </div>
    );
  }
  
  