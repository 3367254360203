// Handle subscribing and unsubscribing
import React, { useState, useEffect } from 'react';
import { createUserAssociation, getUserAssociations, publicKeyToBase58Check } from "deso-protocol"
import { Button, Modal } from 'react-bootstrap'; 
// We know about followers and coin-holders

// We NEED to know re: paying subscribers


export const subscribeTo = async ( publicKeyBase58Check, type ) => {

    // Create user association
    /* fields 
    'TargetUserPublicKeyBase58Check'
    'TransactorPublicKeyBase58Check' 
    'AssociationType'
    'AssociationValue'
    */

    // createUserAssociation();
    // Has it been created
    /*
    try {
        // Check whether the user has any subscriptions to the creator
        const payload = {
            TransactorPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
            TargetUserPublicKeyBase58Check: publicKeyBase58Check,
            AssociationType: "SUBSCRIPTION"
        };
        console.log("[SUBSCRIPTIONS] get payload:",payload);
        const subscriptions = await getUserAssociations(payload);
        console.log("[SUBSCRIPTIONS] returned subscriptions:",subscriptions);
        return subscriptions;
    } catch (error) {
        console.error('Error checking subscriptions:', error);
        return null;
    }
    */
}

export const SubscriptionButton = ({ post }) => {
    const [showModal, setShowModal] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                console.log("[subscriptions.jsx] sending post to subscriptionOptions: ",post);
                const response = await subscriptionOptions(post);
                if (response && response.Associations) {
                    setOptions(response.Associations);
                } else {
                    console.error('[subscriptions.jsx] No subscription options available.');
                }
            } catch (error) {
                console.error('[subscriptions.jsx] Error fetching subscription options:', error);
            }
        };

        if (showModal) {
            fetchOptions();
        }
    }, [showModal, post]);

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setOptions([]); // Clear options when closing modal
    };

    return (
        <>
            <button className="btn btn-outline-info" onClick={handleButtonClick} style={{ zIndex: "200" }}>See available subscriptions</button>
            <Modal size="lg" centered={true} show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Subscriptions Available</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0'>
                    <div className="row m-0 p-0">
                    {options.map(option => (
                        <div className='p-0'>
                            <div className="ratio ratio-16x9">
                                {option.ExtraData.CoverImageURL ? <img src={option.ExtraData.CoverImageURL} className="profile-header-image feature position-absolute top-0 start-0 w-100 h-100" /> : null }
                                <div className="bg-body bg-opacity-50 position-absolute top-50 start-0 w-100 h-50">
                                <div className="row mt-3 px-2">
                                    <div className='col-12'><h4>{option.ExtraData.Name}</h4></div>
                                    <div className='col-8'>
                                        <p>{option.ExtraData.Description}</p>
                                        <span className='text-muted small'>{option.ExtraData.PaymentCadenceDays} days rolling</span>
                                    </div>
                                    <div className='col-4 text-end'>
                                        <button className='btn btn-info'>Subscribe (${(option.ExtraData.CostUsdCents/100).toFixed(2)})</button>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                    ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export const subscriptionOptions = async (post) => {
    try {
        // Check whether the user has any subscriptions to the creator
        console.log("[subscriptions.jsx]  fetching associations for post: ", post);
        const payload = {
            //BC1YLfzTKbH6hWGX4WXiy21AKRTQtdy5J1a2zpXqMuY3VpESYYA65NU = PhilipKDick
            //BC1YLi66ZrrTXe5oXSYqir1jjwkez8trwA4GFBEnzP1cDQz9AzcSNij = nastyhotdog
            //BC1YLirnYXwbhHZzi4q6ry9HccM16JzUsXnYb9YyFrKm5zDfvFsvEGB = Hocus_DESO
            //AppPublicKeyBase58Check: "BC1YLirnYXwbhHZzi4q6ry9HccM16JzUsXnYb9YyFrKm5zDfvFsvEGB",
            //TransactorPublicKeyBase58Check: post.PosterPublicKeyBase58Check,
            TargetUserPublicKeyBase58Check: post.PosterPublicKeyBase58Check,
            AssociationValue: post.PostExtraData.SubscriptionAccessGroupKeyName,
            AssociationType: "SUBSCRIPTION_TIER",
        };
        console.log("[subscriptions.jsx]  get subscription options payload:",payload);
        const subscriptions = await getUserAssociations(payload);
        console.log("[subscriptions.jsx]  returned available subscriptions:",subscriptions);
        return subscriptions;
    } catch (error) {
        console.error('[subscriptions.jsx]  Error checking subscriptions:', error);
        return null;
    }
}

export const checkSubscriptions = async (currentUser, publicKeyBase58Check) => {
    try {
        // Check whether the user has any subscriptions to the creator
        const payload = {
            //BC1YLfzTKbH6hWGX4WXiy21AKRTQtdy5J1a2zpXqMuY3VpESYYA65NU = PhilipKDick
            //BC1YLi66ZrrTXe5oXSYqir1jjwkez8trwA4GFBEnzP1cDQz9AzcSNij = nastyhotdog
            //BC1YLirnYXwbhHZzi4q6ry9HccM16JzUsXnYb9YyFrKm5zDfvFsvEGB = Hocus_DESO
            TransactorPublicKeyBase58Check: "BC1YLirnYXwbhHZzi4q6ry9HccM16JzUsXnYb9YyFrKm5zDfvFsvEGB", // currentUser.PublicKeyBase58Check,
            TargetUserPublicKeyBase58Check: publicKeyBase58Check,
            AssociationType: "SUBSCRIPTION"
        };
        console.log("[subscriptions.jsx]  get payload:",payload);
        const subscriptions = await getUserAssociations(payload);
        console.log("[subscriptions.jsx]  returned subscriptions:",subscriptions);
        return subscriptions;
    } catch (error) {
        console.error('[subscriptions.jsx] Error checking subscriptions:', error);
        return null;
    }
};

